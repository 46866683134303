import { HgbCellTooltip } from '@common/contents';
import { HgbCellTooltipElement } from '@common/contents/HgbCellTooltipElement';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { JSON_TO_KEY } from '@utils/text';
import { ColumnsType } from 'antd/es/table';
import BigNumber from 'bignumber.js';
import { isEmpty } from 'lodash';
import React from 'react';
import {
  EmissionOutput,
  EmissionVolume,
} from 'types/api/emission-output/emission-output.type';
import {
  EmissionDataSearchForm,
  EmissionOutputDataType,
  OffsetDataType,
} from '../C01';
import { HgbCellTooltipEmissionVolume } from './HgbCellTooltipEmissionVolume';

export const useC01Columns = (searchValues: EmissionDataSearchForm) => {
  const {
    text: { C01, common },
  } = React.useContext(LanguageContext)!;
  const { user } = React.useContext(AuthContext)!;

  const isHighRole = user.isPresidingBank || user.isManagementBank;
  const hiddenInputName = (arr1: any, arr2: any) =>
    arr1.length > 1 || arr2.length > 1;

  const floorByMethod = (method: string): number => {
    const electronicUse = ['Electricity usage', '電気使用量'];
    if (
      electronicUse.includes(method.trim()) ||
      searchValues.scopeCode === '2'
    ) {
      return 6;
    }
    return 2;
  };
  let emissionColumns: ColumnsType<EmissionOutputDataType> = [
    {
      title: C01.label.targetYearMonth,
      dataIndex: 'targetYearMonth',
      width: 100,
    },
    {
      title: C01.label.enterpriseName,
      dataIndex: 'enterpriseName',
      width: 170,
      render: (text) => (
        <HgbCellTooltip count={50} row={2}>
          {text}
        </HgbCellTooltip>
      ),
    },
    {
      title: common.label.organizationBaseName,
      dataIndex: 'organizationBaseName',
      width: 170,
      render: (text) => (
        <HgbCellTooltip count={50} row={2}>
          {text}
        </HgbCellTooltip>
      ),
    },

    {
      title:
        searchValues?.typeOfGas === 'GHG01' //None CO2
          ? C01.label.activityType
          : C01.label.greenHouseGasCode,
      dataIndex: 'types',
      width: 170,
      render: (types: string[], record) =>
        types?.map((item) => <p key={`${record.key}-${item}`}>{item}</p>),
    },

    {
      title: C01.label.calculationTargetName,
      dataIndex: 'calculationTargetName',
      width: 170,
      render: (text) => (
        <HgbCellTooltip count={50} row={2}>
          {text}
        </HgbCellTooltip>
      ),
    },
    {
      title: C01.label.types,
      dataIndex: 'types',
      width: 150,
      render: (types: string[], record) =>
        types?.map((item) => <p key={`${item}-${record.key}`}>{item}</p>),
    },
    {
      title:
        searchValues.typeOfGas !== 'CO2'
          ? common.label.emissionVolume
          : C01.label.amount,
      dataIndex: 'amount',
      align: 'center',
      width: 170,
      render: (input: EmissionVolume[]) => {
        let inputs: EmissionVolume[][] = [];
        if (!isEmpty(input)) {
          const maxIndex = input[input.length - 1].rowIndex ?? 0;
          for (let i = 0; i <= maxIndex; i++) {
            inputs = [...inputs, input.filter((k) => k.rowIndex === i)];
          }
        }
        return inputs.map((item) => {
          if (item.length > 2) {
            return (
              <HgbCellTooltipElement item={item} key={item.join('-')}>
                <div className="tw-grid tw-max-w-440 tw-grid-cols-[repeat(auto-fit,minmax(120px,auto))] tw-gap-24">
                  {item.map((s) => (
                    <div
                      key={s.name + JSON_TO_KEY(item)}
                      className="tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center"
                    >
                      {hiddenInputName(inputs, item) && (
                        <p className="tw-min-h-[21px]">{s.name}</p>
                      )}
                      {typeof s.value === 'string' ? (
                        <p className="tw-min-h-[21px]">
                          {s.value} {s.unit}
                        </p>
                      ) : (
                        <p className="tw-min-h-[21px]">
                          {new BigNumber(s.value ?? '').toFixed()} {s.unit}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </HgbCellTooltipElement>
            );
          }
          return (
            <div
              className="tw-grid tw-grid-cols-[repeat(auto-fit,minmax(120px,auto))] tw-gap-24"
              key={JSON_TO_KEY(item) + 'inputsmap'}
            >
              {item.map((s) => (
                <div
                  className="tw-mx-auto tw-flex tw-flex-col tw-items-center tw-justify-center"
                  key={s.name + JSON_TO_KEY(item) + 'inputsmap'}
                >
                  {hiddenInputName(inputs, item) && (
                    <HgbCellTooltip>
                      <p className="tw-min-h-[21px]">{s.name}</p>
                    </HgbCellTooltip>
                  )}
                  {typeof s.value === 'string' ? (
                    <p className="tw-min-h-[21px]">
                      {s.value} {s.unit}
                    </p>
                  ) : (
                    <p className="tw-min-h-[21px]">
                      {new BigNumber(s.value ?? '').toFixed()} {s.unit}
                    </p>
                  )}
                </div>
              ))}
            </div>
          );
        });
      },
    },
    {
      title: C01.label.intensity,
      dataIndex: 'intensity',
      align: 'center',
      width: 150,
      render: (output: EmissionOutput) => {
        const {
          intensities,
          inputs,
          selects,
          scopeCode,
          calculationTargetName,
        } = output;

        let intensity: EmissionVolume[][] = [];
        if (!isEmpty(inputs) && !isEmpty(intensities)) {
          const inputMaxLength = inputs[inputs.length - 1].rowIndex;
          const intensityMaxLength =
            intensities[intensities.length - 1].rowIndex;
          const maxIndex =
            inputMaxLength > intensityMaxLength
              ? inputMaxLength
              : intensityMaxLength;
          for (let i = 0; i <= maxIndex; i++) {
            const tmp = intensities.filter((k) => k.rowIndex === i);
            intensity.push(
              tmp.length
                ? tmp
                : [{ name: '', value: undefined, unit: '', rowIndex: i }],
            );
          }
        }
        return intensity.map((item, index) => {
          if (
            scopeCode === '2' &&
            !['Direct input', '直接手入力'].includes(calculationTargetName)
          ) {
            item = item.filter((item) => {
              let itemName = item?.name || '';
              return itemName.split(' ').length > 1
                ? selects.includes(itemName.split(' ')[0])
                : selects.includes(itemName.replace('基準', ''));
            });
          }
          return (
            <div
              className="tw-flex tw-h-auto tw-justify-between"
              key={item.join('-')}
            >
              {item.map((s) => (
                <React.Fragment
                  key={JSON_TO_KEY(s) + JSON_TO_KEY(item) + 'intensitymap'}
                >
                  <div className="tw-flex tw-flex-auto tw-flex-col tw-items-center tw-justify-start">
                    {hiddenInputName(intensity, item) && (
                      <HgbCellTooltip>
                        <p className="tw-min-h-[21px]">
                          {s.rowIndex !== index ? '' : s.name}
                        </p>
                      </HgbCellTooltip>
                    )}
                    <div className="tw-min-h-[21px]">
                      <HgbCellTooltip>
                        {s.rowIndex !== index
                          ? ''
                          : s.value !== null
                            ? (s.value ?? 0).toFixed(
                              floorByMethod(output.calculationMethodName),
                            )
                            : ''}{' '}
                        {s.unit ?? ''}
                      </HgbCellTooltip>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          );
        });
      },
    },
    {
      title:
        searchValues.typeOfGas !== 'CO2'
          ? C01.label.C02Equivalent
          : common.label.emissions,
      dataIndex: 'emissionVolume',
      align: 'center',
      width: 170,
      render: (output: EmissionOutput) => {
        const { emissionVolume, selects, scopeCode, calculationTargetName } =
          output;
        let emissionVolumes: EmissionVolume[][] = [];

        if (!isEmpty(emissionVolume)) {
          const maxIndex =
            emissionVolume[emissionVolume.length - 1].rowIndex ?? 0;
          for (let i = 0; i <= maxIndex; i++) {
            emissionVolumes.push(
              emissionVolume.filter((k) => k.rowIndex === i),
            );
          }
        }

        return emissionVolumes.map((item) => {
          if (item.length > 2) {
            return (
              <HgbCellTooltipElement
                item={item}
                key={JSON_TO_KEY(item) + 'emissionVolumes'}
              >
                <div className="tw-grid tw-max-w-440 tw-grid-cols-[repeat(auto-fit,minmax(120px,auto))] tw-gap-24">
                  {item.map((s) => (
                    <div
                      key={JSON_TO_KEY(s) + 'emissionVolumes__children'}
                      className="tw-flex tw-flex-col tw-items-center tw-justify-center"
                    >
                      {hiddenInputName(emissionVolumes, item) && (
                        <HgbCellTooltip>
                          <p className="tw-min-h-[21px]">{s.name}</p>
                        </HgbCellTooltip>
                      )}
                      <HgbCellTooltipEmissionVolume
                        value={
                          s.value?.toString
                            ? new BigNumber(s.value).toFixed()
                            : ''
                        }
                      >
                        <p className="tw-min-h-[21px]">
                          {s.value?.toString
                            ? new BigNumber(s.value).toFixed()
                            : ''}{' '}
                          {s.unit}
                        </p>
                      </HgbCellTooltipEmissionVolume>
                    </div>
                  ))}
                </div>
              </HgbCellTooltipElement>
            );
          }
          if (
            scopeCode === '2' &&
            !['Direct input', '直接手入力'].includes(calculationTargetName)
          ) {
            item = item.filter((item) => {
              return (item.name?.split(' ') as string[]).length > 1
                ? selects.includes(item.name?.split(' ')[0] as string)
                : selects.includes(item.name?.replace('基準', '') as string);
            });
          }
          return (
            <div
              className="tw-grid tw-grid-cols-[repeat(auto-fit,minmax(120px,auto))] tw-gap-24"
              key={JSON_TO_KEY(item) + 'emissionVolumes'}
            >
              {item.map((s) => (
                <div
                  className="tw-flex tw-flex-col tw-items-center tw-justify-center"
                  key={JSON_TO_KEY(s) + 'emissionVolumes__children'}
                >
                  {hiddenInputName(emissionVolumes, item) && (
                    <HgbCellTooltip>
                      <p className="tw-min-h-[21px]">{s.name}</p>
                    </HgbCellTooltip>
                  )}{' '}
                  <HgbCellTooltipEmissionVolume
                    value={
                      s.value?.toString ? new BigNumber(s.value).toFixed() : ''
                    }
                  >
                    <p className="tw-min-h-[21px]">
                      {s.value?.toString
                        ? new BigNumber(s.value).toFixed()
                        : ''}{' '}
                      {s.unit}
                    </p>
                  </HgbCellTooltipEmissionVolume>
                </div>
              ))}
            </div>
          );
        });
      },
    },
    {
      title: C01.label.note,
      dataIndex: 'activityName',
      width: 150,
      render: (text) => <HgbCellTooltip count={20}>{text}</HgbCellTooltip>,
    },
    {
      title: common.label.action,
      align: 'center',
      dataIndex: 'action',
      width: 300,
    },
  ];

  let offsetColumns: ColumnsType<OffsetDataType> = [
    {
      title: C01.label.targetYearMonth,
      dataIndex: 'targetYearMonth',
      width: 100,
    },
    {
      title: C01.label.enterpriseName,
      dataIndex: 'enterpriseName',
      width: 150,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: common.label.organizationBaseName,
      dataIndex: 'organizationBaseName',
      width: 150,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: C01.label.offsetAmountWithUnit,
      dataIndex: 'offsetAmount',
      width: 160,
      render: (text) => {
        return <HgbCellTooltip>{text}</HgbCellTooltip>;
      },
    },
    {
      title: C01.label.offsetCertificateDate,
      dataIndex: 'offsetCertificateDate',
      width: 140,
    },
    {
      title: C01.label.offsetTarget,
      dataIndex: 'offsetTarget',
      width: 150,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: C01.label.projectName,
      dataIndex: 'projectName',
      width: 150,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: C01.label.creditType,
      dataIndex: 'creditType',
      width: 150,
      render: (text) => {
        return (
          <HgbCellTooltip count={50} row={2}>
            {text}
          </HgbCellTooltip>
        );
      },
    },
    {
      title: common.label.action,
      align: 'center',
      dataIndex: 'action',
      width: 300,
    },
  ];

  const getTitlesToRemove = (typeOfGas: string) => {
    const titles = [
      C01.label.types,
      C01.label.calculationTargetName,
      C01.label.intensity,
    ];

    if (typeOfGas === 'GHG01') {
      return [...titles, C01.label.greenHouseGasCode]; //Non-energy-origin CO2
    }
    return typeOfGas === 'CO2'
      ? [C01.label.greenHouseGasCode, C01.label.note] //・When selecting CO2
      : titles; //・When selecting  “methane (CH₄)” to “nitrogen trifluoride (NF₃)”
  };

  const removeColumn = (columns: any[], title: string) =>
    columns.filter((column) => column.title !== title);
  const titlesToRemove = getTitlesToRemove(searchValues.typeOfGas);

  const hiddenTitleWithRole = isHighRole
    ? common.label.action
    : C01.label.enterpriseName;

  offsetColumns = removeColumn(offsetColumns, hiddenTitleWithRole);
  emissionColumns = removeColumn(emissionColumns, hiddenTitleWithRole).filter(
    (column) => !titlesToRemove.includes(column?.title),
  );

  return { emissionColumns, offsetColumns };
};
