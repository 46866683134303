import { PlusOutlined } from '@ant-design/icons';
import { HgbButton } from '@common/atoms/HgbButton';
import { HgbEmpty } from '@common/antd/HgbEmpty';
import { HgbSpin } from '@common/antd/HgbSpin';
import { HgbCellTooltip } from '@common/contents';
import { HgbCellLink } from '@common/contents/HgbCellLink';
import { HgbAntdInput } from '@common/forms';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { useBooleanState } from '@hooks/useBooleanState';
import { useDebounce } from '@hooks/useDebound';
import { PaginationProps, usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { trimWithEn } from '@utils/text';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbQueryWithPaging } from 'services/common/query';
import { WithKey } from 'types/common';
import CopyElement from '../D22/CopyComponent';
import AddSupplyOwnerModal from './AddSupplyOwnerModal';
import ViewSupplyOwnerModal from './ViewSupplyOwnerModal';

export type SupplyOwner = {
  id: number;
  inviteCode: string;
  buyerName: string;
  approvedAmount: number;
  createDate: string;
  supplyChainDescription: string;
  buyerId: number;
  supplyChainName: string;
};

export type GetSupplyOwnersRequest = PaginationProps & {
  sortBy?: string;
  nameSearch?: string;
};

const D15 = () => {
  const {
    text: { common, D15 },
  } = useContext(LanguageContext)!;

  const supplyOwnersSearchMethods = useForm<{ nameSearch: string }>({
    values: { nameSearch: '' },
  });

  const { paginationProps, resetPaginations, PaginationComponent } = usePagin();

  useEffect(() => {
    resetPaginations();
  }, [supplyOwnersSearchMethods.watch('nameSearch')]);

  const nameSearchDebounce = useDebounce(
    supplyOwnersSearchMethods.watch('nameSearch'),
    300,
  );

  const [viewData, setViewData] = useState<SupplyOwner>();
  const viewModalState = useBooleanState(false);
  const addModalState = useBooleanState(false);

  const showViewModal = useCallback((record: SupplyOwner) => {
    setViewData({
      ...record,
      supplyChainDescription: trimWithEn(record.supplyChainDescription),
      supplyChainName: trimWithEn(record.supplyChainName),
    });
    viewModalState.turnOn();
  }, []);

  const supplyOwnersRequest: GetSupplyOwnersRequest = {
    ...paginationProps,
    nameSearch: nameSearchDebounce,
  };

  const supplyOwnersQuery = useHgbQueryWithPaging<
    SupplyOwner[],
    GetSupplyOwnersRequest
  >(API_URLS.BUYER_CHAIN, supplyOwnersRequest, {
    queryKey: [QUERY_KEYS.BUYER_CHAIN_LIST, supplyOwnersRequest],
  });

  const columns: ColumnsType<SupplyOwner> = [
    {
      title: D15.columns.inviteCode,
      dataIndex: 'inviteCode',
      render: (value) => <CopyElement value={value} />,
      width: 136,
    },
    {
      title: common.columns.supplyChainGroupName,
      dataIndex: 'supplyChainName',
      render: (value, record) => (
        <HgbCellLink
          className="tw-inline-block tw-break-all tw-text-left"
          onClick={() => showViewModal(record)}
        >
          <HgbCellTooltip>{value}</HgbCellTooltip>
        </HgbCellLink>
      ),
      width: 260,
    },
    {
      title: D15.columns.numPracticipants,
      dataIndex: 'approvedAmount',
      align: 'center',
      width: 160,
    },
    {
      title: D15.columns.createDate,
      dataIndex: 'createDate',
      width: 120,
    },
    {
      title: D15.columns.note,
      dataIndex: 'supplyChainDescription',
      render: (value) => (
        <div
          className="tw-break-all"
          dangerouslySetInnerHTML={{ __html: value.replaceAll('\n', '<br />') }}
        />
      ),
      width: 280,
    },
  ];

  const rows: WithKey<SupplyOwner>[] = useMemo(() => {
    return (supplyOwnersQuery?.data?.result?.content ?? []).map((item) => ({
      ...item,
      key: item.id,
    }));
  }, [supplyOwnersQuery?.data?.result?.content]);

  return (
    <ManagementTemplate>
      <DataTableTemplate
        title={D15.title}
        buttonComponent={
          <HgbButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={addModalState.turnOn}
          >
            {common.button.register}
          </HgbButton>
        }
        paginationComponents={
          <PaginationComponent
            current={supplyOwnersQuery.data?.result?.currentPage}
            total={supplyOwnersQuery.data?.result?.totalRecord}
            pageSize={supplyOwnersQuery.data?.result?.pageSize}
          />
        }
        inputsComponent={
          <div className="tw-rounded-8 tw-bg-primary-1/20 tw-p-16">
            <FormProvider {...supplyOwnersSearchMethods}>
              <HgbAntdInput
                {...supplyOwnersSearchMethods.register('nameSearch')}
                type="K"
                maxLength={100}
                search
                className="pc:tw-w-240"
                placeholder={common.placeholder.filter}
              />
            </FormProvider>
          </div>
        }
      >
        {rows.length > 0 ? (
          <Table
            columns={columns}
            dataSource={rows}
            pagination={false}
            className="tw-overflow-hidden"
            scroll={{
              x: columns.reduce(
                (prev, current) => prev + Number(current.width ?? 0),
                0,
              ),
            }}
          />
        ) : null}

        {supplyOwnersQuery.isFetched && rows.length === 0 ? (
          <HgbEmpty description={common.message.noResult} />
        ) : null}

        {supplyOwnersQuery.isLoading ? (
          <HgbSpin placement="middle" />
        ) : null}
      </DataTableTemplate>
      <AddSupplyOwnerModal modalState={addModalState} />
      <ViewSupplyOwnerModal modalState={viewModalState} data={viewData} />
    </ManagementTemplate>
  );
};

export default D15;
