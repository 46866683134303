import { cn } from '@utils/cn';
import * as React from 'react';

const HgbModalForm: React.FC<
  React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >
> = ({ className, onSubmit, ...props }) => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setTimeout(() => {
      onSubmit?.(e);
    }, 50);
  };

  const handleDraft = () => {
    formRef.current?.setAttribute('status', 'DRAFT');
  };
  const handleActive = () => {
    formRef.current?.setAttribute('status', 'ACTIVE');
  };

  React.useEffect(() => {
    const draftButton = formRef.current?.querySelector('button[name="draft"]');
    const submitButton = formRef.current?.querySelector(
      'button[name="active"]',
    );
    
    draftButton?.addEventListener('click', handleDraft);
    submitButton?.addEventListener('click', handleActive);
    return () => {
      draftButton?.removeEventListener('click', handleDraft);
      submitButton?.removeEventListener('click', handleActive);
    };
  }, []);

  return (
    <form
      ref={formRef}
      className={cn(
        'tw-flex tw-flex-auto tw-flex-col tw-overflow-y-hidden tw-px-4',
        className,
      )}
      {...props}
      onSubmit={handleSubmit}
    />
  );
};

export default HgbModalForm;
