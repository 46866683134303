import { HgbButton } from '@common/atoms/HgbButton';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { AuthenTemplate } from '@layouts/templates';
import React, { useContext } from 'react';

export const Logout: React.FC = () => {
  const {
    text: { A05, common },
  } = useContext(LanguageContext)!;
  const { backToLogin } = useContext(AuthContext)!;

  return (
    <AuthenTemplate title={A05.pageTitleLogout}>
      <h4>{A05.message.logoutSuccessfully}</h4>
      <HgbButton type="link" onClick={backToLogin} className="tw-mx-auto">
        {common.button.backToLogin}
      </HgbButton>
    </AuthenTemplate>
  );
};
