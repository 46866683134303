import { cn } from '@utils/cn';
import * as React from 'react';

interface IPieBoundaryProps {
  children: React.ReactNode;
  title: string;
  pieName: string;
  description: string;
  value: string;
  subValue: string;
  className?: string;
}

export const PieBoundary: React.FC<IPieBoundaryProps> =
  React.memo((props) => {
    return (
      <div className={cn('tw-flex tw-flex-wrap tw-gap-16', props.className)}>
        <div className="tw-relative tw-flex tw-w-fit tw-flex-col tw-gap-8 tw-pl-24 tw-text-center">
          <h3 className="tw-absolute tw-left-0 tw-top-0 tw-font-bold">
            {props.title}
          </h3>
          {props.children}
          <p className="tw-font-bold">{props.pieName}</p>
        </div>
        <div className="tw-max-w-420 tw-space-y-4 tw-py-16">
          <h3>{props.description}</h3>
          <div className="tw-flex tw-flex-col tw-items-end">
            <p className="tw-font-bold">{props.value}</p>
            <small>{props.subValue}</small>
          </div>
        </div>
      </div>
    );
  });
