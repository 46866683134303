// import { Role } from './role';

import { Role } from '@constants/consts';

export type LoginRequest = {
  bankCode?: string;
  username: string;
  password: string;
};

export const loginRequestDefault: LoginRequest = {
  bankCode: undefined,
  username: '',
  password: '',
};

export type LoginResponse = {
  isFirstGeneratePassword?: boolean;
  profileToken?: string;
  refreshToken?: string;
  accountId?: string;
  email?: string;
  username?: string;
  nameKanji?: string;
  nameKana?: string;
  authorities?: {
    roles?: Role[];
    privileges?: string[];
    isPresidingBank?: boolean;
    bankCode?: string;
  };
  departmentHierarchy?: {
    departmentName: string;
    isPresidingBank: boolean;
    bankId: number;
    enterpriseId: number;
    baseId: null | number;
    bankName?: string;
    subscriptionType: string;
    billingStartDefault: string | null;
    isBuyer: boolean;
  };
  scopeCode?: string | null;
  isLocked?: boolean;
};

export type Authorize = {
  authorities?: {
    roles?: string[];
    privileges?: string[];
    isPresidingBank?: boolean;
    bankCode?: string;
  };
};

export type ChangePasswordRequest = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export type ReissueRequest = {
  bankCode: string;
  username: string;
  email: string;
};

export const reissueRequestDefault: ReissueRequest = {
  bankCode: '',
  username: '',
  email: '',
};

export type SignInFormData = {
  username: string;
  password: string;
};

export type ReissueTokenRequest = {
  accountId: string;
  refreshToken: string;
};

export type ReissueTokenResponse = {
  profileToken: string;
  refreshToken: string;
};
