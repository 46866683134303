import { HgbModal, HgbModalProps } from '@common/antd/HgbModal';
import { HgbFieldError } from '@common/contents/HgbFieldError';
import {
  HgbAntdInput,
  HgbSelect,
  HgbSelectOption,
  parseToHgbSelectOptions,
} from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { Radio } from 'antd';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  CreateBaseRequest,
  GetBaseListBoxRequest,
  GetBaseListBoxResponse,
  GetOrganizationListBoxRequest,
  GetOrganizationListBoxResponse,
} from 'services/types/base';
import { v4 } from 'uuid';
import * as yup from 'yup';

type AddBaseFormData = {
  baseName: string;
  classification: string;
  parentOrganizationId: string;
  parentBaseId: string;
  displayOrder: string;
};

const createBaseRequestDefault: AddBaseFormData = {
  baseName: '',
  classification: '',
  parentOrganizationId: '',
  parentBaseId: '',
  displayOrder: '',
};

const convertToCreateBaseRequest = (
  v: AddBaseFormData,
): CreateBaseRequest | undefined => {
  if (!v) {
    return undefined;
  }
  if (v.classification === '1') {
    return {
      baseName: v.baseName,
      classification: '1',
      displayOrder: v.displayOrder,
    };
  }
  return {
    ...v,
    parentBaseId: Number(v.parentBaseId),
    parentOrganizationId: Number(v?.parentOrganizationId),
  };
};

export interface OrganizationAddModalProps extends HgbModalProps {
  onSubmitOk?: () => void;
  classificationOptions: HgbSelectOption[];
}
export const OrganizationAddModal: React.FC<
  OrganizationAddModalProps
> = ({ onSubmitOk, classificationOptions, ...props }) => {
  const {
    text: {
      D02,
      common,
      E0000,
    },
  } = React.useContext(LanguageContext)!;

  const addSchema = yup.object().shape({
    baseName: yup.string().required(E0000(D02.label.baseName)),
    classification: yup.string().required(E0000(D02.label.classification)),
    parentBaseId: yup
      .string()
      .when('classification', (classification, schemaBuilder) => {
        return classification.toString() === '2' ||
          classification.toString().length === 0
          ? schemaBuilder.required(E0000(D02.label.parentOrganizationId))
          : schemaBuilder;
      }),
  });

  const methods = useForm<AddBaseFormData>({
    values: createBaseRequestDefault,
    resolver: yupResolver(addSchema),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;

  const formValues = watch();
  const { classification, parentBaseId } = watch();

  const { data: baseLBQuery } = useHgbQuery<
    GetBaseListBoxResponse,
    GetBaseListBoxRequest
  >(API_URLS.BASE_LISTBOX_API_URL);

  const { data: orgLBQuery } = useHgbQuery<
    GetOrganizationListBoxResponse,
    GetOrganizationListBoxRequest
  >(
    API_URLS.ORGANIZATION_LISTBOX_API_URL,
    {
      parentBaseId: parentBaseId,
    },
    {
      queryKey: [
        API_URLS.ORGANIZATION_LISTBOX_API_URL,
        formValues.parentBaseId,
      ],
      enabled: !isEmpty(parentBaseId),
    },
  );

  const parentBaseIdOptions = useMemo(
    () => parseToHgbSelectOptions(baseLBQuery?.result),
    [baseLBQuery?.result],
  );

  const parentOrgIdOptions = useMemo(
    () => parseToHgbSelectOptions(orgLBQuery?.result),
    [orgLBQuery?.result],
  );

  const { mutate: createBaseMutate } = useHgbMutationPost(
    API_URLS.BASE_ORGANIZATION_API_URL,
    {
      onSuccess: (data) => {
        !!data && onSubmitOk?.();
      },
    },
  );

  const submitHandler: SubmitHandler<AddBaseFormData> = (data) => {
    let request: CreateBaseRequest = convertToCreateBaseRequest(data)!;
    if (classification === '1') {
      request = {
        baseName: data.baseName,
        displayOrder: data.displayOrder,
        classification,
      };
    }
    createBaseMutate(request);
  };

  return (
    <FormProvider {...methods}>
      <HgbModal
        {...props}
        formProps={{
          onSubmit: handleSubmit(submitHandler),
        }}
        title={D02.modal.organizationAddTitle}
        cancelText={common.button.cancel}
        okText={common.button.add}
      >
        <div className="tw-grid tw-gap-24">
          <HgbAntdInput
            {...register('baseName')}
            required
            label={D02.label.baseName}
            placeholder={D02.placeholder.baseName}
            maxLength={50}
          />
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div>
              <Radio.Group
                onChange={(e) => {
                  setValue('classification', e.target.value);
                  clearErrors('classification');
                  setValue('displayOrder', '0');
                }}
                value={classification}
              >
                {classificationOptions.map((item) => (
                  <Radio value={item.value.toString()} key={v4()}>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
            {errors.classification?.message && (
              <HgbFieldError>{errors.classification?.message}</HgbFieldError>
            )}
          </div>

          {classification !== '1' && (
            <>
              <HgbSelect
                {...register('parentBaseId')}
                options={parentBaseIdOptions}
                label={D02.label.parentBaseId}
                placeholder={D02.placeholder.parentBaseId}
                required
                autoInitValue
                showSearch
              />
              <HgbSelect
                {...register('parentOrganizationId')}
                disabled={parentOrgIdOptions.length === 0}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={parentOrgIdOptions}
                label={D02.label.parentOrganizationId}
                placeholder={D02.placeholder.parentOrganizationId}
                autoInitValue
              />
            </>
          )}

          <HgbAntdInput
            {...register('displayOrder')}
            type={'positiveInteger'}
            maxLength={3}
            label={D02.label.displayOrder}
            placeholder={D02.placeholder.displayOrder}
          />
        </div>
      </HgbModal>
    </FormProvider>
  );
};
