import { HgbButton } from '@common/atoms/HgbButton';
import HgbModalForm from '@common/antd/HgbModalForm';
import { HgbModalFormBody } from '@common/antd/HgbModalFormBody';
import { HgbModalFormFooter } from '@common/antd/HgbModalFormFooter';
import { HgbModalTitle } from '@common/antd/HgbModalTitle';
import { HgbAntdInput } from '@common/forms';
import { LanguageContext } from '@contexts/LanguageContext';
import { BooleanState } from '@hooks/useBooleanState';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SupplierProduct } from './D19';
import { bigNumberToString } from '@utils/text';

interface IViewSupplierProductModalProps {
  modalState: BooleanState;
  data: SupplierProduct;
}

const ViewSupplierProductModal: React.FC<
  IViewSupplierProductModalProps
> = ({ modalState, data }) => {
  const {
    text: { common, D19 },
  } = React.useContext(LanguageContext)!;

  const addSupplierProductMethods = useForm<SupplierProduct>({
    values: {
      ...data,
      emissionIntensity: bigNumberToString(data.emissionIntensity),
    },
  });

  return (
    <>
      <FormProvider {...addSupplierProductMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={
            <HgbModalTitle>
              {D19.modal.supplierProductsInformation}
            </HgbModalTitle>
          }
          open={modalState.value}
          onCancel={modalState.turnOff}
          footer={null}
          afterClose={modalState.turnOff}
          closable={true}
          maskClosable={false}
          destroyOnClose
        >
          <HgbModalForm>
            <HgbModalFormBody>
              <HgbAntdInput
                {...addSupplierProductMethods.register('serialNumber')}
                maxLength={50}
                type={'K'}
                label={D19.serialNumber}
                required
                readOnly
              />
              <HgbAntdInput
                {...addSupplierProductMethods.register('productName')}
                maxLength={100}
                type={'K'}
                label={D19.productName}
                required
                readOnly
              />
              <HgbAntdInput
                {...addSupplierProductMethods.register('unit')}
                maxLength={15}
                type={'K'}
                label={D19.modal.unit}
                required
                readOnly
              />
              <HgbAntdInput
                {...addSupplierProductMethods.register('emissionIntensity')}
                label={D19.emissionsPerUnit}
                maxLength={10}
                type="integer"
                required
                suffix="tCO2"
                readOnly
              />
              <HgbAntdInput
                {...addSupplierProductMethods.register('gtinCode')}
                label={D19.gtinCode}
                maxLength={14}
                type="integer"
                readOnly
              />
              <HgbAntdInput
                {...addSupplierProductMethods.register('upcCode')}
                label={D19.upcCode}
                maxLength={12}
                type="integer"
                readOnly
              />
            </HgbModalFormBody>
            <HgbModalFormFooter>
              <HgbButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
              >
                {common.button.cancel}
              </HgbButton>
            </HgbModalFormFooter>
          </HgbModalForm>
        </Modal>
      </FormProvider>
    </>
  );
};

export default ViewSupplierProductModal;
