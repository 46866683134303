import { HgbInlineLogo } from '@common/logos';
import { COLORS } from '@constants/colors';
import { AuthenStatus } from '@contexts/AuthContext';
import { cn } from '@utils/cn';
import * as React from 'react';

interface AuthenTemplateProps {
  title?: React.ReactNode;
  children: React.ReactNode;
  logo?: boolean;
  loginStatus?: AuthenStatus;
  bankName?: string;
  className?: string;
}
export const AuthenTemplate: React.FC<AuthenTemplateProps> = ({
  children,
  title = '',
  bankName = '',
  logo = true,
  className = '',
}) => {
  return (
    <div
      className={cn(
        'tw-grid tw-min-h-screen tw-place-items-center tw-bg-cover tw-bg-no-repeat',
        className,
      )}
      style={{ backgroundImage: "url('/background/authen-background.svg')", backgroundColor: COLORS.mainBackground }}
    >
      <div className="tw-w-360 pc:tw-w-436">
        <div className={`tw-grid tw-rounded-16 tw-bg-white tw-p-24 contain`}>
          {logo && <HgbInlineLogo className="tw-mx-auto tw-mb-12 tw-h-64" />}
          {bankName && (
            <h1 className="tw-text-center tw-font-bold">{bankName}</h1>
          )}
          <h1 className="tw-mb-24 tw-text-center tw-font-bold tw-text-[27px]">{title}</h1>
          {children}
        </div>
      </div>
    </div>
  );
};
