import { HgbFieldError } from '@common/contents/HgbFieldError';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import { cn } from '@utils/cn';
import { Radio, RadioChangeEvent, RadioGroupProps } from 'antd';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';

export type HgbAntdRadioOption<T = string> = {
  label: React.ReactNode;
  value: T;
};

interface HgbRadioGroupProps extends RadioGroupProps {
  options: HgbAntdRadioOption[];
  name: string;
  onChangeValue?: (e: RadioChangeEvent) => void;
  label?: string;
  tabIndex?: number;
  required?: boolean;
  direction?: 'vertical' | 'horizontal';
  info?: string;
  modalStatus?: string;
  readOnly?: boolean;
}

export const HgbRadioGroup = React.forwardRef<any, HgbRadioGroupProps>(
  (
    {
      className = '',
      direction = 'horizontal',
      tabIndex = 0,
      options,
      onChange,
      label,
      required,
      onChangeValue,
      info,
      modalStatus,
      disabled,
      readOnly,
      ...props
    },
    ref,
  ) => {
    const {
      watch,
      setValue,
      formState: { errors },
    } = useFormContext();
    const error = errors[props.name];
    const handleChange = (e: RadioChangeEvent) => {
      setValue(props.name, e.target.value);
      onChangeValue?.(e);
    };

    const id = v4();
    return (
      <div
        className={cn(
          'tw-grid tw-gap-8',
          { 'tw-pointer-events-none': readOnly },
          className,
        )}
      >
        {label && (
          <label
            htmlFor={id}
            className="tw-flex tw-gap-4 tw-text-paragraph tw-font-bold"
          >
            {label}
            {required && (
              <span className="tw-text-paragraph tw-font-bold tw-text-error-7">
                *
              </span>
            )}
            {modalStatus !== 'VIEW' && !!info ? (
              <HgbInfoLink to={info} target="_blank" />
            ) : null}
          </label>
        )}
        <Radio.Group
          id={id}
          ref={ref}
          className={cn('tw-flex tw-h-auto tw-items-center tw-gap-16', {
            'tw-flex-col tw-items-start tw-pl-16': direction === 'vertical',
            'tw-cursor-not-allowed': disabled,
          })}
          value={watch(props.name)}
          onChange={(e) => {
            if (disabled) return;
            e.preventDefault();
            handleChange(e);
          }}
          {...props}
        >
          {options.map((option, index) => (
            <Radio
              key={index + props.name + v4()}
              tabIndex={tabIndex}
              value={option.value}
              onClick={(e) => {
                e.preventDefault();
                if (disabled) return;
              }}
              className={cn(
                'tw-mr-0 tw-flex tw-flex-1 tw-gap-4 tw-break-keep tw-whitespace-nowrap [&:after]:tw-hidden [&_span]:!tw-px-0',
                { 'tw-pointer-events-none': disabled },
              )}
            >
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
        {error && <HgbFieldError>{error.message?.toString()}</HgbFieldError>}
      </div>
    );
  },
);
