import { HgbModal, HgbModalProps } from '@common/antd/HgbModal';
import { HgbFieldError } from '@common/contents/HgbFieldError';
import {
  HgbAntdInput,
  HgbSelect,
  HgbSelectOption,
  parseToHgbSelectOptions,
} from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { STATUS_CODE } from '@constants/consts';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { Radio } from 'antd';
import React, { useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHgbMutationPut } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  GetOrganizationListBoxRequest,
  GetOrganizationListBoxResponse,
  UpdateBaseRequest,
  UpdateBaseResponse,
} from 'services/types/base';
import { v4 } from 'uuid';
import * as yup from 'yup';

type UpdateBaseFormData = {
  baseName?: string;
  classification?: string;
  displayOrder?: string;
  hierarchicalPath?: string;
  id: string;
  parentBaseId?: string;
  parentOrganizationId?: string;
};

const convertToUpdateBaseFormData = (
  v?: UpdateBaseRequest,
): UpdateBaseFormData | undefined => {
  if (!v) {
    return undefined;
  }
  return {
    ...v,
    id: v.id.toString(),
    parentBaseId: v.parentBaseId?.toString() ?? '-1',
    parentOrganizationId: v.parentOrganizationId?.toString() ?? '-1',
  };
};

const convertToUpdateBaseRequest = (
  v?: UpdateBaseFormData,
): UpdateBaseRequest | undefined => {
  if (!v) {
    return undefined;
  }
  if (v.classification === '1') {
    return {
      id: Number(v.id),
      baseName: v.baseName,
      displayOrder: v.displayOrder,
      classification: v.classification,
    };
  }
  return {
    // ...v,
    id: Number(v?.id),
    parentBaseId: Number(v?.parentBaseId),
    parentOrganizationId: Number(v?.parentOrganizationId ?? -1),
    displayOrder: v.displayOrder,
    classification: v.classification,
    baseName: v.baseName,
  };
};
export interface OrganizationUpdateModalProps extends HgbModalProps {
  onSubmitOk?: () => void;
  item?: UpdateBaseRequest;
  readOnly?: boolean;
  classificationOptions: HgbSelectOption[];
  parentBaseIdOptions: HgbSelectOption[];
}
export const OrganizationUpdateModal: React.FC<
  OrganizationUpdateModalProps
> = ({
  onSubmitOk,
  item,
  readOnly,
  classificationOptions,
  parentBaseIdOptions,
  ...props
}) => {
  const formData = convertToUpdateBaseFormData(item);
  const {
    text: { D02, common, E0000 },
  } = React.useContext(LanguageContext)!;

  const updateSchema = yup.object().shape({
    baseName: yup.string().required(E0000(D02.label.baseName)),
  });

  const methods = useForm<UpdateBaseFormData>({
    values: formData,
    resolver: yupResolver(updateSchema),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;

  const { classification, parentBaseId } = watch();

  const { data: orgLBQuery } = useHgbQuery<
    GetOrganizationListBoxResponse,
    GetOrganizationListBoxRequest
  >(
    API_URLS.ORGANIZATION_LISTBOX_API_URL,
    {
      parentBaseId: parentBaseId === '' ? undefined : parentBaseId,
    },
    {
      queryKey: [API_URLS.ORGANIZATION_LISTBOX_API_URL, parentBaseId],
      enabled: true,
    },
  );

  const [isParentBaseEdited, setIsParentBaseEdited] = useState(false);
  const parentOrgIdOptions = useMemo(() => {
    const orgLBs = (orgLBQuery?.result ?? []).filter(
      (item) =>
        !item.hierarchicalPath
          ?.split('-')
          .includes(formData?.id?.toString() ?? ''),
    );
    const rs = parseToHgbSelectOptions(orgLBs);
    if (isParentBaseEdited) {
      setValue('parentOrganizationId', rs?.[0]?.value);
    } else {
      setValue('parentOrganizationId', formData?.parentOrganizationId);
    }
    return rs;
  }, [
    formData?.id,
    formData?.parentOrganizationId,
    orgLBQuery?.result,
    isParentBaseEdited,
  ]);

  const { mutate: updateBaseMutate } = useHgbMutationPut<
    UpdateBaseResponse,
    UpdateBaseRequest
  >(API_URLS.BASE_ORGANIZATION_API_URL, {
    onSuccess: (data) => {
      if (data.statusCode === STATUS_CODE.updateSuccess) {
        onSubmitOk?.();
      }
    },
  });

  const submitHandler: SubmitHandler<UpdateBaseFormData> = (data) => {
    if (data.classification === undefined) return;
    updateBaseMutate(convertToUpdateBaseRequest(data)!);
  };

  return (
    <FormProvider {...methods}>
      <HgbModal
        {...props}
        formProps={{
          onSubmit: handleSubmit(submitHandler),
        }}
        title={
          readOnly
            ? D02.modal.organizationViewTitle
            : D02.modal.organizationUpdateTitle
        }
        cancelText={common.button.cancel}
        okText={readOnly ? '' : common.button.change}
      >
        <div className="tw-grid tw-gap-24">
          <HgbAntdInput
            {...register('id')}
            label={D02.label.baseId}
            placeholder={D02.placeholder.baseName}
            maxLength={50}
            disabled
          />
          <HgbAntdInput
            {...register('baseName')}
            required
            label={D02.label.baseName}
            placeholder={D02.placeholder.baseName}
            maxLength={50}
            disabled={readOnly}
          />
          <div className="tw-flex tw-flex-col tw-gap-4">
            <div>
              <Radio.Group
                onChange={(e) => {
                  setValue('classification', e.target.value);
                  clearErrors('classification');
                  setValue('displayOrder', '0');
                }}
                value={classification}
              >
                {classificationOptions.map((item) => (
                  <Radio value={item.value.toString()} key={v4()} disabled>
                    {item.label}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
            {errors.classification?.message && (
              <HgbFieldError>{errors.classification?.message}</HgbFieldError>
            )}
          </div>

          {classification !== '1' && (
            <>
              <HgbSelect
                onChangeValue={() => {
                  setIsParentBaseEdited(true);
                }}
                {...register('parentBaseId')}
                options={parentBaseIdOptions}
                label={D02.label.parentBaseId}
                placeholder={D02.placeholder.parentBaseId}
                required
                disabled={readOnly}
                showSearch
              />
              <HgbSelect
                {...register('parentOrganizationId')}
                showSearch
                options={parentOrgIdOptions}
                label={D02.label.parentOrganizationId}
                placeholder={D02.placeholder.parentOrganizationId}
                disabled={readOnly || parentOrgIdOptions.length === 0}
              />
            </>
          )}

          <HgbAntdInput
            {...register('displayOrder')}
            type={'positiveInteger'}
            maxLength={3}
            label={D02.label.displayOrder}
            placeholder={D02.placeholder.displayOrder}
            disabled={readOnly}
          />
        </div>
      </HgbModal>
    </FormProvider>
  );
};
