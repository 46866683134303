import { DeleteFilled, LinkOutlined } from '@ant-design/icons';
import { HgbFileUploadOutlinedIcon } from '@common/icons/outlined';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { cn } from '@utils/cn';
import { Tooltip, Upload, UploadFile, UploadProps } from 'antd';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 } from 'uuid';

const { Dragger } = Upload;

interface HgbUploadProps extends UploadProps {
  name: string;
  className?: string;
  label?: string;
  size?: number;
  numberOfFiles?: number;
  isReset?: boolean;
  setIsReset?: Dispatch<SetStateAction<boolean>>;
}

const HgbUpload: React.FC<HgbUploadProps> = (
  {
    name,
    className,
    disabled = false,
    fileList: qq,
    accept = '.png,.pdf,.jpg,.jpeg,.bmp',
    size = 10,
    multiple = false,
    numberOfFiles = 23,
    isReset = false,
    setIsReset,
  },
  ...props
) => {
  const {
    text: {
      common: {
        label: { fileUpload, fileName },
        message,
      },
    },
  } = useContext(LanguageContext)!;

  const { addMessage } = useContext(PortalContext)!;
  const { setValue } = useFormContext();
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [previousFiles, setPreviousFiles] = useState<File[]>([]);

  const beforeUpload = (file: UploadFile<any>) => {
    if (validateFile(file)) {
      setFileList([file]);
      setValue(name, file);
    } else {
      onRemove();
    }
    return false;
  };

  const onRemove = () => {
    setFileList([]);
    setValue(name, undefined);
  };

  useEffect(() => {
    if (isReset) {
      setIsReset?.(false);
      setFileList([]);
    }
  }, [isReset]);

  const beforeUploadMultiple = (file: any) => {
    if (validateFileMultiple(file)) {
      setFileList((prev) => [...prev, file]);
    }
    return false;
  };

  useEffect(() => {
    if (fileList.length > numberOfFiles) {
      const files = fileList.filter(
        (f) => !previousFiles.find((p) => p.name === f.name),
      );
      setFileList(files);
      setValue(name, files);
      addMessage('error', message.fileExceedLimit);
      setPreviousFiles([]);
      return;
    }
    setValue(name, multiple ? fileList : fileList[0]);
  }, [fileList]);

  const onRemoveMultiple = (file: UploadFile<any>) => {
    const removeList = fileList.filter((s) => s.name !== file.name);
    setFileList(removeList);
    setValue(name, removeList);
  };

  const extentions = accept.replaceAll('.', '').split(/,/);
  const validateFile = (file: UploadFile<any>) => {
    if (!file) {
      return false;
    }
    if (Number(file.size) > size * 1000000) {
      addMessage('error', message.fileExceedMb.replace('$', size.toString()));
      return false;
    }
    if (!extentions.includes((file.type ?? '').split('/')[1])) {
      addMessage('error', message.fileFormat);
      return false;
    }
    return true;
  };

  const validateFileMultiple = (file: any) => {
    if (fileList.length > 0 && fileList.find((f) => f.name === file.name)) {
      addMessage('error', message.fileExisted);
      return false;
    }

    if (!validateFile(file)) return false;
    setPreviousFiles((prev) => [...prev, file]);

    return true;
  };

  return (
    <div>
      <div className={cn(`tw-h-176 tw-bg-primary-1/20 ${className}`)}>
        <Dragger
          name={name}
          beforeUpload={!multiple ? beforeUpload : beforeUploadMultiple}
          onRemove={!multiple ? onRemove : onRemoveMultiple}
          fileList={[]}
          accept={accept}
          disabled={disabled}
          multiple={multiple}
          {...props}
        >
          <p className="ant-upload-drag-icon">
            <HgbFileUploadOutlinedIcon
              size="24"
              className="tw-text-primary-6"
            />
          </p>
          <p className="ant-upload-text">
            {fileUpload.text1}
            <br />
            <span className="tw-text-primary-6">{fileUpload.text2}</span>
            {fileUpload.text3}
          </p>
          <p className="ant-upload-hint">
            {extentions.join(', ').toUpperCase()} upto {size}MB
          </p>
        </Dragger>
      </div>
      {fileList?.length > 0 && (
        <div>
          <h3>{fileName}: </h3>
          <div className="tw-flex tw-flex-col tw-gap-4">
            {fileList?.map((file) => {
              return (
                <div
                  key={v4()}
                  className="tw-group tw-flex tw-w-full tw-items-center tw-gap-16 tw-duration-300"
                >
                  <LinkOutlined />
                  <p className=" tw-line-clamp-1 tw-max-w-340 tw-flex-auto tw-break-all">
                    <Tooltip title={file.name.length > 50 ? file.name : ''}>
                      {file.name}
                    </Tooltip>
                  </p>

                  <DeleteFilled
                    className="tw-ml-auto tw-cursor-pointer tw-opacity-0 group-hover:tw-opacity-50"
                    onClick={() => onRemoveMultiple(file)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default HgbUpload;
