import { LanguageContext } from '@contexts/LanguageContext';
import { AuthenTemplate } from '@layouts/templates';
import * as React from 'react';

// not found
export const BankCodeNotFound: React.FC = () => {
  const {
    text: { common },
  } = React.useContext(LanguageContext)!;

  return (
    <AuthenTemplate title={common.message.tit_not_found_bank_code} logo={false}>
      <p className="tw-text-center">
        {common.message.mess_not_found_bank_code}
      </p>
    </AuthenTemplate>
  );
};
