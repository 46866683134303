import { ButtonProps } from '@common/types';
import { cn } from '@utils/cn';
import * as React from 'react';

interface HgbCellLinkProps extends ButtonProps {}

export const HgbCellLink: React.FC<HgbCellLinkProps> = ({
  className,
  ...props
}) => {
  return (
    <button
      tabIndex={0}
      className={cn(
        `tw-block tw-cursor-pointer tw-text-[#1677ff] tw-underline tw-outline-offset-4 hover:tw-text-[#69b1ff] focus:tw-outline-primary-5 active:tw-text-[#0958d9] ${className}`,
      )}
      style={{ textDecorationSkipInk: 'none' }}
      {...props}
    />
  );
};
