import { QuestionCircleFilled } from '@ant-design/icons';
import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface IHgbInfoLinkProps extends LinkProps {}

const HgbInfoLink: React.FC<IHgbInfoLinkProps> = (props) => {
  return (
    <Link {...props}>
      <QuestionCircleFilled />
    </Link>
  );
};

export default HgbInfoLink;
