import { SaveOutlined } from '@ant-design/icons';
import { HgbButton } from '@common/atoms/HgbButton';
import { HgbRadioGroup } from '@common/antd/HgbRadioGroup';
import { HgbAntdInput, HgbDatePicker } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { EMAIL_REGEX } from '@constants/regex';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbMutationPut } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  BANK_FORM_DEFAULT_VALUE,
  Bank,
  UpdateBankRequest,
  UpdateBankResponse,
} from 'services/types/bank';
import * as yup from 'yup';

export const BankInfo = () => {
  const {
    text: { G01, common, E0000, E0087, E0086 },
  } = useContext(LanguageContext)!;
  const subscriptionOptions = [
    {
      label: G01.label.freeTrial,
      value: 'FREE_TRIAL',
    },
    {
      label: G01.label.complimentary,
      value: 'COMPLIMENTARY',
    },
  ];
  const { user } = useContext(AuthContext)!;

  const enterpriseTrialMonthsRangeMsg = E0086(
    G01.label.enterpriseTrialMonthsG02,
    '0',
    '99',
  );
  const enterpriseTrialMonthsEmptyMsg = E0087(
    G01.label.freeTrial,
    G01.label.enterpriseTrialMonthsG02,
  );
  const enterpriseMonthlyFeeRangeMsg = E0086(
    G01.label.enterpriseMonthlyFeeG02,
    '0',
    '99999',
  );
  const enterpriseMonthlyFeeEmptyMsg = E0087(
    G01.label.freeTrial,
    G01.label.enterpriseMonthlyFeeG02,
  );
  const expirationPeriodHoursRangeMsg = E0086(
    G01.label.URLExpirationPeriodHours,
    '24',
    '720',
  );

  const expirationPeriodHoursEmptyMsg = E0000(
    G01.label.URLExpirationPeriodHours,
  );

  const bankSchema = yup.object({
    bankCode: yup.string().required(E0000(common.label.bankCode)),
    bankName: yup.string().required(E0000(G01.label.bankName)),
    personInCharge: yup.string().required(E0000(G01.label.personInCharge)),
    personInChargeEmail: yup
      .string()
      .required(E0000(G01.label.personInChargeEmail))
      .matches(EMAIL_REGEX, common.message.emailFormat),
    privacyPolicyUrl: yup.string().required(E0000(G01.label.privacyPolicyUrl)),
    emailSignature: yup.string().required(E0000(G01.label.signature)),
    enterpriseTrialMonths: yup
      .number()
      .required(enterpriseTrialMonthsEmptyMsg)
      .typeError(enterpriseTrialMonthsEmptyMsg)
      .test('', enterpriseTrialMonthsRangeMsg, (value, schema) => {
        return (
          (Number(value) >= 0 && Number(value) <= 99) ||
          schema.parent.subscriptionType === 'COMPLIMENTARY'
        );
      }),
    enterpriseMonthlyFee: yup
      .number()
      .required(enterpriseMonthlyFeeEmptyMsg)
      .typeError(enterpriseMonthlyFeeEmptyMsg)
      .test('', enterpriseMonthlyFeeRangeMsg, (value, schema) => {
        return (
          (Number(value) >= 0 && Number(value) <= 99999) ||
          schema.parent.subscriptionType === 'COMPLIMENTARY'
        );
      }),
    expirationPeriodHours: yup
      .number()
      .required(expirationPeriodHoursEmptyMsg)
      .typeError(expirationPeriodHoursEmptyMsg)
      .min(24, expirationPeriodHoursRangeMsg)
      .max(720, expirationPeriodHoursRangeMsg),
  });

  const methods = useForm<Bank>({
    resolver: yupResolver(bankSchema),
    defaultValues: BANK_FORM_DEFAULT_VALUE,
  });

  const { register, watch, reset, handleSubmit } = methods;

  useHgbQuery<Bank, undefined>(
    `${API_URLS.BANK_API_URL}/${user.bankId}`,
    undefined,
    {
      onSuccess: (data) => {
        reset({
          ...data.result,
          emailSignature: (data.result?.emailSignature || '')
            .split('<br />')
            .join('\n'),
          loginUrl: `${
            window.location.toString().split('/')[2]
          }/signin?bankCode=${data.result?.bankCode}`,
          enterpriseMonthlyFee: data.result?.enterpriseMonthlyFee || 0,
          enterpriseTrialMonths: data.result?.enterpriseTrialMonths || 0,
          expirationPeriodHours: data.result?.expirationPeriodHours || 0,
          enterpriseTrialStartMonth:
            data.result?.enterpriseTrialStartMonth || '',
          subscriptionType: data.result?.subscriptionType || 'FREE_TRIAL',
        });
        localStorage.setItem(
          'billingStartDefault',
          data.result?.billingStartDefault ?? '',
        );
      },
      queryKey: [`${API_URLS.BANK_API_URL}/${user.bankId}`],
    },
  );

  const { mutate: updateBankMutate } = useHgbMutationPut<
    UpdateBankResponse,
    UpdateBankRequest
  >(API_URLS.BANK_API_URL);

  const currentBank = watch();

  const submitBank = () => {
    updateBankMutate({
      ...currentBank,
      emailSignature: watch('emailSignature').replace(
        new RegExp('\r?\n', 'g'),
        '<br />',
      ),
      privacyPolicyUrl: encodeURIComponent(currentBank.privacyPolicyUrl),
    });
  };

  return (
    <>
      <ManagementTemplate pageName={G01.pageTitleBankInfo}>
        <DataTableTemplate title={G01.pageTitleBankInfo}>
          <FormProvider {...methods}>
            <div className="tw-py-24">
              <form
                onSubmit={handleSubmit(submitBank)}
                className="tw-flex tw-flex-col tw-gap-24 pc:tw-flex-row"
              >
                <div className="tw-grid tw-flex-auto tw-auto-rows-[92px] tw-items-start tw-gap-16 pc:tw-grid-cols-2 pc:tw-gap-x-24">
                  <HgbAntdInput
                    {...register('bankCode')}
                    required
                    type="bankCode"
                    maxLength={4}
                    disabled
                    label={common.label.bankCode}
                    placeholder={G01.placeholder.bankCode}
                  />
                  <HgbAntdInput
                    {...register('bankName')}
                    required
                    maxLength={20}
                    type="K"
                    disabled
                    label={G01.label.bankName}
                    placeholder={G01.placeholder.bankName}
                  />
                  <HgbAntdInput
                    {...register('bankArea')}
                    required
                    // maxLength={20}
                    type="K"
                    disabled
                    label={G01.label.area}
                    placeholder={G01.placeholder.area}
                  />
                  <HgbAntdInput
                    {...register('bankProvince')}
                    required
                    type="K"
                    disabled
                    label={G01.label.province}
                    placeholder={G01.placeholder.province}
                  />
                  <HgbAntdInput
                    {...register('loginUrl')}
                    required
                    maxLength={100}
                    type="H"
                    disabled
                    label={G01.label.loginUrl}
                    placeholder={G01.placeholder.loginUrl}
                  />
                  <HgbAntdInput
                    {...register('personInCharge')}
                    maxLength={20}
                    required
                    type="K"
                    label={G01.label.personInCharge}
                    placeholder={G01.placeholder.personInCharge}
                  />
                  <HgbAntdInput
                    {...register('personInChargeEmail')}
                    maxLength={50}
                    required
                    type="hgbEmail"
                    label={G01.label.personInChargeEmail}
                    placeholder={G01.placeholder.personInChargeEmail}
                  />
                  <HgbRadioGroup
                    {...register('subscriptionType')}
                    options={subscriptionOptions}
                    label={G01.label.subscriptionType}
                    className="tw-gap-0 [&>div]:tw-grid [&>div]:tw-grid-cols-[auto_1fr]"
                    readOnly
                  />
                  <HgbDatePicker
                    format="YYYY/MM"
                    required={watch('subscriptionType') === 'FREE_TRIAL'}
                    label={G01.label.enterpriseTrialStartMonths}
                    {...register('enterpriseTrialStartMonth')}
                    disabled
                  />
                  <HgbAntdInput
                    type="positiveInteger"
                    required={watch('subscriptionType') === 'FREE_TRIAL'}
                    label={G01.label.enterpriseTrialMonthsG02}
                    {...register('enterpriseTrialMonths')}
                    disabled={watch('subscriptionType') === 'COMPLIMENTARY'}
                    addonAfter={G01.label.months}
                  />
                  <HgbAntdInput
                    required={watch('subscriptionType') === 'FREE_TRIAL'}
                    label={G01.label.enterpriseMonthlyFeeG02}
                    {...register('enterpriseMonthlyFee')}
                    disabled={watch('subscriptionType') === 'COMPLIMENTARY'}
                    type="positiveInteger"
                    addonAfter={G01.label['yen/month']}
                  />
                  <HgbAntdInput
                    label={G01.label.URLExpirationPeriodHours}
                    {...register('expirationPeriodHours')}
                    type="positiveInteger"
                    addonAfter={G01.label.hours}
                    required
                  />
                  <HgbAntdInput
                    {...register('privacyPolicyUrl')}
                    maxLength={255}
                    required
                    type="H"
                    label={G01.label.privacyPolicyUrl}
                    placeholder={G01.placeholder.privacyPolicyUrl}
                  />
                  <HgbAntdInput
                    type="area"
                    required
                    maxLength={255}
                    label={G01.label.signature}
                    placeholder={G01.label.signature}
                    {...register('emailSignature')}
                  />
                </div>
                <HgbButton
                  htmlType="submit"
                  type="primary"
                  className="tw-ml-auto"
                  icon={<SaveOutlined />}
                >
                  <>{common.button.save}</>
                </HgbButton>
              </form>
            </div>
          </FormProvider>
        </DataTableTemplate>
      </ManagementTemplate>
    </>
  );
};
