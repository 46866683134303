import { PlusOutlined } from '@ant-design/icons';
import { HgbButton } from '@common/atoms/HgbButton';
import { HgbEmpty } from '@common/antd/HgbEmpty';
import { HgbCellTooltip } from '@common/contents';
import { HgbAntdInput } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { useDebounce } from '@hooks/useDebound';
import { usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { checkDateRange } from '@utils/date.util';
import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbQueryWithPaging } from 'services/common/query';
import {
  DeleteMessageModalProps,
  IAddMessageModalProps,
  MessageRequestType,
  MessageResponseType,
  UpdateMessageModalProps,
} from 'services/types/D13';
import AddMessageModal from './AddMessageModal';
import { DeleteMessageModal } from './DeleteMessageModal';
import UpdateMessageModal from './UpdateMessageModal';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

export const D13: FC = () => {
  const {
    text: { common, D13 },
  } = useContext(LanguageContext)!;

  const { user } = useContext(AuthContext)!;
  const { addMessage } = useContext(PortalContext)!;

  const [addMessageModalProps, setAddMessageModalProps] =
    useState<IAddMessageModalProps>({
      open: false,
      isDuplicated: false,
    });
  const [updateMessageModalProps, setUpdateMessageModalProps] =
    useState<UpdateMessageModalProps>({
      open: false,
    });
  const [deleteMessageModalProps, setDeleteMessageModalProps] =
    useState<DeleteMessageModalProps>({
      open: false,
      id: '',
    });

  const {
    PaginationComponent,
    paginationProps,
    resetPaginations,
    setPaginationProps,
  } = usePagin();

  const searchMethods = useForm<any>({
    values: {
      nameSearch: '',
    },
  });

  const { register: registerSearchForm, watch: watchSearchForm } =
    searchMethods;
  const searchValues = watchSearchForm();

  const nameSearchDebounce = useDebounce(searchValues.nameSearch, 500);

  useEffect(() => {
    resetPaginations();
  }, [nameSearchDebounce]);

  const {
    data: messageQuery,
    refetch: messageRefetch,
    isFetching: messageFetching,
  } = useHgbQueryWithPaging<MessageResponseType[], MessageRequestType>(
    API_URLS.MESSAGE_API_URL,
    { ...searchValues, ...paginationProps },
    {
      queryKey: [API_URLS.MESSAGE_API_URL, paginationProps, nameSearchDebounce],
    },
  );

  const handleDuplicate = (item: MessageResponseType) => {
    setAddMessageModalProps((prev) => ({
      ...prev,
      open: true,
      item: {
        ...item,
        id: '',
        type: item.type.toString(),
        range: item.range.toString(),
      },
      isDuplicated: true,
    }));
  };

  const handleEdit = (item: MessageResponseType) => {
    const dateChecked = checkDateRange(
      item.displayStartDate,
      item.displayEndDate,
    );
    const isDraft =
      (item?.status ?? '').toLocaleLowerCase() === 'draft' ||
      item?.status === '下書き';

    if (dateChecked !== -1 && !isDraft) {
      addMessage('error', D13.message.edit);
    }
    setUpdateMessageModalProps((prev) => ({
      ...prev,
      open: true,
      item: {
        ...item,
        type: item.type.toString(),
        range: item.range.toString(),
      },
    }));
  };

  const handleDelete = (item: MessageResponseType) => {
    let content: any = D13.message.delete;
    if (item.messageStatusId !== 3) {
      content = D13.modal.deleteContent(item.title);
    }

    setDeleteMessageModalProps({
      id: item?.id?.toString(),
      open: true,
      content,
    });
  };

  const headers: ColumnsType<Omit<MessageResponseType, 'messageStatusId'>> = [
    {
      title: D13.label.id,
      dataIndex: 'id',
      width: 48,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: D13.label.title,
      dataIndex: 'title',
      width: 240,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: D13.label.type,
      dataIndex: 'typeName',
      width: 120,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: D13.label.delivery,
      dataIndex: 'rangeName',
      width: 120,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: D13.label.status,
      dataIndex: 'status',
      width: 120,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: D13.label.displayStartDate,
      dataIndex: 'displayStartDate',
      width: 120,
      render: (text) => (
        <HgbCellTooltip count={50} row={2}>
          {text}
        </HgbCellTooltip>
      ),
    },
    {
      title: D13.label.displayEndDate,
      dataIndex: 'displayEndDate',
      width: 120,
      render: (text) => (
        <HgbCellTooltip count={50} row={2}>
          {text}
        </HgbCellTooltip>
      ),
    },
    {
      title: D13.label.lastModified,
      dataIndex: 'lastModifiedDate',
      width: 120,
      render: (text) => (
        <HgbCellTooltip count={50} row={2}>
          <div
            dangerouslySetInnerHTML={{ __html: `<span>${text}</span>` }}
          ></div>
        </HgbCellTooltip>
      ),
    },
    {
      title: common.label.action,
      align: 'center',
      dataIndex: 'action',
      width: 210,
    },
  ];

  const rows = useMemo(
    () =>
      (messageQuery?.result?.content ?? []).map((item, index) => ({
        key: index,
        id: item.id,
        range: item.range,
        rangeName: item.rangeName,
        type: item.type,
        typeName: item.typeName,
        title: item.title,
        description: item.description,
        status: item.status,
        displayStartDate: item.displayStartDate,
        displayEndDate: item.displayEndDate,
        lastModifiedDate: item.lastModifiedDate,
        item: item,
        action: (
          <div className="tw-flex tw-justify-center tw-gap-24">
            <HgbActionButton
              onClick={() => handleDuplicate(item)}
              icon={<CopyOutlined />}
            >
              {common.button.duplicate}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => handleEdit(item)}
              icon={<EditOutlined />}
            >
              {common.button.update}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => handleDelete(item)}
              icon={<DeleteOutlined />}
            >
              {common.button.delete}
            </HgbActionButton>
          </div>
        ),
      })),
    [messageQuery?.result?.content],
  );

  const columns = useMemo(() => {
    const tmpHeaders = [...headers];
    if (user.isEnterpriseUser || user.isEnterpriseAdmin) tmpHeaders.shift();
    return tmpHeaders;
  }, [user]);

  const closeDeleteModal = () => {
    setDeleteMessageModalProps((prev) => ({ ...prev, open: false }));
  };

  const closeUpdateModal = () => {
    setUpdateMessageModalProps((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const closeAddModal = () => {
    setAddMessageModalProps((prev) => ({
      ...prev,
      open: false,
      isDuplicated: false,
      item: undefined,
    }));
  };

  return (
    <ManagementTemplate>
      <FormProvider {...searchMethods}>
        <DataTableTemplate
          title={D13.pageTitle}
          inputsComponent={
            <div className="tw-grid tw-flex-auto tw-gap-16 tablet:tw-grid-cols-[repeat(auto-fill,_minmax(220px,_1fr))]">
              <HgbAntdInput
                {...registerSearchForm('nameSearch')}
                type="K"
                maxLength={20}
                search
                placeholder={D13.placeholder.message}
              />
            </div>
          }
          buttonComponent={
            <HgbButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setAddMessageModalProps((prev) => ({ ...prev, open: true }));
              }}
            >
              <>{common.button.newMessage}</>
            </HgbButton>
          }
          paginationComponents={
            (messageQuery?.result?.totalRecord ?? 0) > 0 ? (
              <PaginationComponent
                total={messageQuery?.result?.totalRecord}
                current={messageQuery?.result?.currentPage}
                pageSize={messageQuery?.result?.pageSize}
              />
            ) : null
          }
        >
          {isEmpty(rows) ? (
            messageFetching ? (
              <Spin className="tw-flex tw-h-full tw-items-center tw-justify-center" />
            ) : (
              <HgbEmpty
                className="tw-flex tw-h-full tw-items-center tw-justify-center"
                description={common.message.noResult}
              />
            )
          ) : (
            <Table
              columns={columns}
              dataSource={rows}
              pagination={false}
              className="tw-overflow-hidden"
              scroll={{
                x: headers.reduce(
                  (prev, current) => prev + Number(current.width ?? 0),
                  0,
                ),
              }}
            />
          )}
        </DataTableTemplate>
      </FormProvider>
      {addMessageModalProps.open && (
        <AddMessageModal
          {...addMessageModalProps}
          onCancel={closeAddModal}
          onSubmitOk={() => {
            closeAddModal();
            messageRefetch();
          }}
          maskClosable={false}
          enterForSubmit={false}
        />
      )}
      {updateMessageModalProps.open && (
        <UpdateMessageModal
          {...updateMessageModalProps}
          onCancel={() => {
            closeUpdateModal();
          }}
          onSubmitOk={() => {
            closeUpdateModal();
            messageRefetch();
          }}
          maskClosable={false}
          enterForSubmit={false}
        />
      )}

      {deleteMessageModalProps.open && (
        <DeleteMessageModal
          {...deleteMessageModalProps}
          onCancel={closeDeleteModal}
          onSubmitOk={() => {
            closeDeleteModal();
            if (rows?.length === 1) {
              setPaginationProps((prev) => ({
                ...prev,
                pageNo: prev.pageNo > 0 ? prev.pageNo - 1 : 0,
              }));
            } else {
              messageRefetch();
            }
          }}
        />
      )}
    </ManagementTemplate>
  );
};
