import { HgbNavLink, HgbNavbar } from '@common/navigations';
import { DivProps } from '@common/types';
import { RoutDef } from '@constants/ROUT';
import { COLORS } from '@constants/colors';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

interface ManagementTemplateProps extends DivProps {
  breadcrumb?: boolean;
  pageName?: string;
  showTitle?: boolean;
  screenId?: string;
}

export const ManagementTemplate: React.FC<
  ManagementTemplateProps
> = ({
  className = '',
  breadcrumb = true,
  children,
  pageName,
  showTitle = true,
  screenId,
  ...props
}) => {
  const {
    routs,
    text: { D01 },
  } = React.useContext(LanguageContext)!;
  const location = useLocation();
  const pathname: RoutDef = location.pathname as RoutDef;
  const { user } = React.useContext(AuthContext)!;
  const title = user.isPresidingBank ? '肥後銀行' : user.departmentName;

  return (
    <div
      className={`tw-grid tw-min-h-screen tw-min-w-[375px] tw-grid-rows-[auto_1fr] ${className}`}
      {...props}
      style={{backgroundColor: COLORS.mainBackground}}
    >
      <HgbNavbar />
      <section className="tw-grid tw-grid-rows-[auto_1fr] tw-py-24">
        <div>
          {title && showTitle && (
            <h1 className="tw-pb-16 tw-font-bold pc:tw-pb-24">{title}</h1>
          )}
          {breadcrumb && (
            <div className="tw-flex tw-items-baseline tw-gap-8 tw-pb-24 tw-text-[14px]">
              <HgbNavLink className="!tw-p-0" to={routs['/dashboard'].link}>
                {routs['/dashboard'].name}
              </HgbNavLink>
              <span>/</span>
              <HgbNavLink className="!tw-p-0" to={routs[pathname]?.link}>
                {pageName ?? routs[pathname]?.name}
              </HgbNavLink>
            </div>
          )}
        </div>
        <div>{children}</div>
        {screenId === 'EnterpriseAdmin' && D01.label.leftGroupContent}
      </section>
    </div>
  );
};
