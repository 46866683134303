import { HgbButton } from '@common/atoms/HgbButton';
import { HgbAntdInput } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { ROLES, STATUS_CODE } from '@constants/consts';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthenTemplate } from '@layouts/templates';
import { LocalDataClass, UserLocal } from 'data-class/LocalDataClass';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHgbMutationPost } from 'services/common/mutation';
import { FORCE_REFRESH_EVENT_NAME, useHgbQuery } from 'services/common/query';
import {
  LoginRequest,
  LoginResponse,
  loginRequestDefault,
} from 'services/types/authen';
import * as yup from 'yup';
import { Logout } from '../Logout/Logout';
import { SessionTimeout } from '../SessionTimeout/SessionTimeout';
import { useNavigate } from 'react-router-dom';
import { CompaniesUnpaidFee } from '../CompaniesUnpaidFee/CompaniesUnpaidFee';
import { routs_en } from '@constants/ROUT';
declare global {
  interface Window {
    signinLink?: string;
  }
}

export const SignIn: React.FC = () => {
  const {
    text: { A01, common, E0000 },
    routs,
  } = useContext(LanguageContext)!;
  const { user, setCurrenBankCode } = useContext(AuthContext)!;

  const loginSchema = yup.object({
    bankCode: yup.string().required(E0000(common.label.bankCode)),
    username: yup.string().required(E0000(common.label.loginID)),
    password: yup.string().required(E0000(A01.label.password)),
  });

  const loginMethods = useForm<LoginRequest>({
    resolver: yupResolver(loginSchema),
    values: loginRequestDefault,
  });

  const redirectTo404 = () => {};

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const bankCode = searchParams.get('bankCode') || '';

    for (const key of searchParams.keys()) {
      if (key !== 'bankCode') {
        return redirectTo404();
      }
    }

    if (bankCode?.length === 0) {
      navigate(routs_en['/bank-code-not-found'].link);
    } else if (bankCode?.length === 4) {
      loginMethods.setValue('bankCode', bankCode);
    } else {
      loginMethods.setValue('bankCode', bankCode);
      redirectTo404();
    }
  }, []);

  const loginMutation = useHgbMutationPost<LoginResponse, LoginRequest>(
    API_URLS.ACCOUNT_LOGIN,
  );

  const bankNameQuery = useHgbQuery<string, { bankCode: string }>(
    API_URLS.BANK_NAME_API_URL,
    {
      bankCode: loginMethods.watch('bankCode')!,
    },
    {
      queryKey: [API_URLS.BANK_NAME_API_URL, loginMethods.watch('bankCode')],
      onSuccess(data) {
        if (data.statusCode === STATUS_CODE.notFound) {
          navigate(routs_en['/bank-code-not-found'].link);
        }
        isEmpty(data) && redirectTo404();
      },
      enabled: true,
    },
  );

  const onSubmit: SubmitHandler<LoginRequest> = (data) => {
    loginMutation
      .mutateAsync(data)
      .then((rs) => {
        const userStorage: UserLocal = {
          profileToken: rs.result?.profileToken || '',
          refreshToken: rs.result?.refreshToken || '',
          accountId: rs.result?.accountId || '0',
          email: rs.result?.email || '',
          username: rs.result?.username || '',
          nameKanji: rs.result?.nameKanji || '',
          nameKana: rs.result?.nameKana || '',
          isBuyer: rs.result?.departmentHierarchy?.isBuyer ?? false,
          isPresidingBank:
            rs.result?.authorities?.roles?.[0] === ROLES.ROLE_PRESIDING_BANK,
          isManagementBank:
            rs.result?.authorities?.roles?.[0] === ROLES.ROLE_MANAGEMENT_BANK,
          isEnterpriseAdmin:
            rs.result?.authorities?.roles?.[0] === ROLES.ROLE_ENTERPRISE_ADMIN,
          isEnterpriseUser:
            rs.result?.authorities?.roles?.[0] === ROLES.ROLE_ENTERPRISE_USER,
          bankId: rs.result?.departmentHierarchy?.bankId ?? 0,
          enterpriseId: rs.result?.departmentHierarchy?.enterpriseId as number,
          baseId: rs.result?.departmentHierarchy?.baseId ?? 0,
          role: rs.result?.authorities?.roles?.[0] ?? 'ROLE_ENTERPRISE_USER',
          departmentName: rs.result?.departmentHierarchy?.departmentName ?? '',
          bankName: rs.result?.departmentHierarchy?.bankName ?? '',
          scopeCode: rs.result?.scopeCode ?? null,
          isFirstGeneratePassword: rs.result?.isFirstGeneratePassword ?? false,
          authenStatus: 'LOGIN',
          bankCode: '',
          isMaintenance: false,
          subscriptionType:
            rs.result?.departmentHierarchy?.subscriptionType || '',
          billingStartDefault:
            rs.result?.departmentHierarchy?.billingStartDefault ?? null,
          isLocked: rs.result?.isLocked ?? false,
        };
        localStorage.removeItem('billingStartDefault');
        localStorage.setItem(
          'billingStartDefault',
          rs.result?.departmentHierarchy?.billingStartDefault ?? '',
        );

        switch (rs.statusCode) {
          case STATUS_CODE.shouldChangePassword:
            userStorage.authenStatus = 'SHOULD_CHANGE_PASSWORD';
            break;
          case STATUS_CODE.mustChangePassword:
            userStorage.authenStatus = 'MUST_CHANGE_PASSWORD';
            break;
          default:
            if (rs.result?.isFirstGeneratePassword) {
              userStorage.authenStatus = 'FIRST_GENERATE_PASSWORD';
            } else if (rs.result?.isLocked) {
              userStorage.authenStatus = 'LOCKED';
            } else if (rs.result) {
              userStorage.authenStatus = 'SUCCESS';
            }
            userStorage.bankCode = loginMethods.watch('bankCode') || '';
            break;
        }
        LocalDataClass.user = userStorage;
        window.dispatchEvent(new Event(FORCE_REFRESH_EVENT_NAME));
      })
      .catch((err) => {
        loginMethods.setFocus('username');
      });
  };

  const navigate = useNavigate();
  const goToReissue = () => {
    setCurrenBankCode(loginMethods.watch('bankCode') || '');
    const { search, pathname } = window.location;
    window.signinLink = `${pathname}${search}`;
    navigate(routs['/reissue'].link);
  };

  return (
    <>
      {user.authenStatus === 'SESSION_TIMEOUT' && <SessionTimeout />}
      {user.authenStatus === 'LOCKED' &&
        (user.isEnterpriseAdmin || user.isEnterpriseUser) && (
          <CompaniesUnpaidFee />
        )}
      {user.authenStatus === 'LOGOUT' && <Logout />}
      {user.authenStatus === 'LOGIN' && (
        <AuthenTemplate
          title={common.button.login}
          bankName={bankNameQuery?.data?.result}
          loginStatus={user.authenStatus}
        >
          <FormProvider {...loginMethods}>
            <form
              className="tw-flex tw-flex-col tw-gap-24"
              onSubmit={loginMethods.handleSubmit(onSubmit)}
            >
              <HgbAntdInput
                {...loginMethods.register('username')}
                placeholder={A01.placeholder.username}
                label={common.label.loginID}
                required
                type="H"
                maxLength={8}
              />
              <HgbAntdInput
                {...loginMethods.register('password')}
                placeholder={A01.placeholder.password}
                label={A01.label.password}
                required
                type="password"
                maxLength={16}
              />
              <div className="tw-grid tw-justify-center tw-gap-8">
                <HgbButton
                  htmlType="submit"
                  type="primary"
                  loading={loginMutation.isLoading}
                >
                  {common.button.login}
                </HgbButton>
                <HgbButton type="link" onClick={goToReissue}>
                  <>{common.button.forgotPassword}</>
                </HgbButton>
              </div>
            </form>
          </FormProvider>
        </AuthenTemplate>
      )}
    </>
  );
};
