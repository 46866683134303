import { HgbModal } from '@common/antd/HgbModal';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import * as React from 'react';
import { useHgbMutationDelete } from 'services/common/mutation';
import { DeleteMessageModalProps } from 'services/types/D13';

export const DeleteMessageModal: React.FC<
  DeleteMessageModalProps
> = ({ onSubmitOk, content, id, ...props }) => {
  const {
    text: {
      D13,
      common,
    },
  } = React.useContext(LanguageContext)!;

  const { mutate: deleteMessageMutate, isLoading } = useHgbMutationDelete<
    unknown,
    string
  >(API_URLS.MESSAGE_API_URL, { onSuccess: onSubmitOk });

  return (
    <HgbModal
      cancelText={common.button.cancel}
      okText={common.button.delete}
      title={D13.modal.deleteMessage}
      formProps={{
        onSubmit: () => deleteMessageMutate(id),
      }}
      disableOk={isLoading}
      {...props}
    >
      <p>{content}</p>
    </HgbModal>
  );
};
