import { ExclamationCircleOutlined } from '@ant-design/icons';
import { HgbButton } from '@common/atoms/HgbButton';
import HgbModalForm from '@common/antd/HgbModalForm';
import { HgbModalFormBody } from '@common/antd/HgbModalFormBody';
import { HgbModalFormFooter } from '@common/antd/HgbModalFormFooter';
import { HgbModalTitle } from '@common/antd/HgbModalTitle';
import { HgbFieldError } from '@common/contents/HgbFieldError';
import {
  HgbAntdInput,
  HgbDatePicker,
  HgbSelect,
  HgbSelectOption,
  parseToHgbSelectOptions,
} from '@common/forms';
import {
  HgbTreeSelect,
  HgbTreeSelectOption,
} from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { STATUS_CODE } from '@constants/consts';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { BooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import {
  getFiscalYearFromMonth,
  getIsoDate,
  validateMonthRange,
} from '@utils/date.util';
import { debug } from '@utils/debug';
import { calculationFormula, hgbFixed } from '@utils/number';
import { convertObjectToTree, getParentKey } from '@utils/object.utils';
import { bigNumberToString } from '@utils/text';
import { Modal } from 'antd';
import { debounce, isEmpty } from 'lodash';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationPost } from 'services/common/mutation';
import {
  AddSupplierEmissionInputPayload,
  GetSupplierEmissionInputRequest,
  defaultAddSupplierEmissionInputPayload,
} from 'services/types/emission';
import * as yup from 'yup';
import { FlowInputDetail, InputInfo, SelectionInfo } from '../C01/type';

interface AddSupplierAnswerModalProps {
  modalState: BooleanState;
  productId?: string;
  supplyChainNameId?: string;
  buyerId?: string;
}

export type GetSupplierEmissionInputResponse = {
  nextStepSelectionInfos: SelectionInfo[] | null;
  flowInputDetails: FlowInputDetail[] | null;
  flowResultItem: InputInfo | null;
  formula: string | null;
  flowFinished: boolean;
  supplierResponseUsed: number | null;
};

const AddSupplierAnswerModal: React.FC<
  AddSupplierAnswerModalProps
> = ({ modalState, productId = '', supplyChainNameId = '', buyerId = '' }) => {
  const {
    text: { common, H03, E0000, E0101, E0049, E0100, E0106 },
    language,
  } = React.useContext(LanguageContext)!;
  const queryClient = useQueryClient();
  const { addMessage } = React.useContext(PortalContext)!;

  const { ConfirmModal: ConfirmModalSubmit, hgbConfirm: hgbConfirmSubmit } =
    useConfirm();

  const { ConfirmModal: ConfirmModal, hgbConfirm: hgbConfirm } = useConfirm();

  const [err, setErr] = React.useState<string>('');
  const newDate = new Date();
  const fiscalYear = getFiscalYearFromMonth(
    `${newDate.getFullYear()}/${newDate.getMonth()}`,
  );

  const addSupplierAnswerSchema = yup.object({
    enterpriseId: yup.string().required(E0000(H03.groupBuyer)),
    supplierId: yup
      .string()
      .required(E0000(common.columns.supplyChainGroupName)),
    responseType: yup.string().required(E0000(H03.responseType)),
    baseVolume: yup.string().when({
      is: () => responseType !== '1',
      then: (schema) => schema.required(E0000(H03.scopeHalfEmissions)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    emissionIntensity: yup.string().when({
      is: () => responseType === '1',
      then: (schema) => schema.required(E0000(common.label.emissionIntensity)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    baseId: yup.string().when({
      is: () => responseType !== '1',
      then: (schema) =>
        schema.required(E0000(common.label.organizationBaseName)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    itemId: yup.string().when({
      is: () => responseType === '1',
      then: (schema) =>
        schema.required(E0000(H03.manufacturingCodeProductName)),
      otherwise: (schema) => {
        return schema.notRequired();
      },
    }),
    targetStartMonth: yup.string().required(E0000(H03.startDate)),
    targetEndMonth: yup.string().required(E0000(H03.endDate)),
    quantity: yup
      .string()
      .typeError(E0000(H03.quantity))
      .when({
        is: () => responseType === '1',
        then: (schema) => schema.required(E0000(H03.quantity)),
        otherwise: (schema) => {
          return schema.notRequired();
        },
      }),
    allocation: yup
      .string()
      .typeError(E0000(H03.allocation))
      .when({
        is: () => responseType !== '1',
        then: (schema) => schema.required(E0000(H03.allocation)),
        otherwise: (schema) => {
          return schema.notRequired();
        },
      })
      .test('validate_value', E0101, (value, schema) => {
        return Number(value) <= 100 || schema.parent.responseType === '1';
      }),
    emissionVolume: yup
      .string()
      .typeError(E0000(common.label.emissionVolume))
      .required(E0000(common.label.emissionVolume))
      .max(14, E0106),
  });

  const addSupplierAnswerMethods = useForm<AddSupplierEmissionInputPayload>({
    resolver: yupResolver(addSupplierAnswerSchema),
    values: {
      ...defaultAddSupplierEmissionInputPayload,
      targetStartMonth: fiscalYear + '/04',
      targetEndMonth: fiscalYear + 1 + '/03',
      itemId: productId,
      supplierId: supplyChainNameId,
      enterpriseId: buyerId,
      responseType: isEmpty(productId)
        ? defaultAddSupplierEmissionInputPayload.responseType
        : '1',
    },
  });

  const addSupplierAnswerMutation = useHgbMutationPost<
    unknown,
    AddSupplierEmissionInputPayload
  >(API_URLS.SUPPLIER_CHAIN_RESPONSE_SAVE, {});

  const validationPayloadMutation = useHgbMutationPost<
    unknown,
    AddSupplierEmissionInputPayload
  >(API_URLS.CHECK_SUPPLIER_RESPONSE, {
    isAlert: false,
  });

  const handleSubmit = addSupplierAnswerMethods.handleSubmit(
    async (data) => {
      const value = validateMonthRange(
        data.targetStartMonth || '',
        data.targetEndMonth || '',
      );

      if (value !== 'PASS') {
        if (value === 'ORDER') {
          setErr(E0049);
        } else setErr(E0100);
        return;
      }
      setErr('');
      const hierarchicalPath =
        responseType === '2'
          ? orgBaseArr.filter((item: any) => item.value === data.baseId)[0]
              .hierarchicalPath
          : '';
      setOrgName(
        responseType === '2'
          ? orgBaseArr.filter((item: any) => item.value === data.baseId)[0].name
          : '',
      );
      const payload = {
        ...data,
        hierarchicalPath: hierarchicalPath,
        targetStartMonth: data.targetStartMonth?.replace('/', ''),
        targetEndMonth: data.targetEndMonth?.replace('/', ''),
        supplierResponseIdCheck: null,
        sentTime: getIsoDate(),
      };
      let result: boolean = false;
      validationPayloadMutation.mutateAsync(payload).then(async (data) => {
        if (data.statusCode === STATUS_CODE.success) {
          result = await hgbConfirmSubmit();
        } else if (data.statusCode === STATUS_CODE.orgBaseAllocation) {
          result = await hgbConfirm();
        } else {
          modalState.turnOff();
          addMessage('error', data.message ?? '');
          return;
        }
        result &&
          addSupplierAnswerMutation.mutateAsync(payload).then(() => {
            handleSuccess();
          });
      });
    },
    (error) => {
      debug.error(error);
    },
  );

  const handleSubmitDebounce = debounce(handleSubmit, 500);

  const handleSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
    });
    modalState.turnOff();
  };

  const supplierEmissionMutation = useHgbMutationPost<
    GetSupplierEmissionInputResponse,
    GetSupplierEmissionInputRequest | null
  >(API_URLS.SUPPLIER_CHAIN_RESPONSE_FLOW, {
    isAlert: false,
  });

  const [enterpriseOptions, setEnterpriseOptions] = React.useState<
    HgbSelectOption[]
  >([]);
  const [suplierOptions, setSuplierOptions] = React.useState<HgbSelectOption[]>(
    [],
  );
  const [responseTypeOptions, setResponseTypeOptions] = React.useState<
    HgbSelectOption[]
  >([]);

  const [orgOptions, setOrgOptions] = React.useState<HgbTreeSelectOption[]>([]);
  const [orgBaseArr, setOrgBaseArr] = React.useState<any>([]);
  const [itemOptions, setItemOptions] = React.useState<HgbSelectOption[]>([]);
  const [emissionUnit, setEmissionUnit] = React.useState<string | undefined>(
    H03.correspondingUnit,
  );
  const [quantityUnit, setQuantityUnit] = React.useState<string | undefined>(
    H03.quantityUnit,
  );
  const [supplierResponseUsed, setSupplierResponseUsed] =
    React.useState<number>(0);
  const [orgName, setOrgName] = React.useState<string>('');
  const responseType = addSupplierAnswerMethods.watch('responseType');

  React.useEffect(() => {
    if (!modalState.value || !isEmpty(supplyChainNameId)) return;
    addSupplierAnswerMethods.setValue('supplierId', '');
  }, [addSupplierAnswerMethods.watch('enterpriseId'), modalState.value]);

  React.useEffect(() => {
    if (!modalState.value) return;
    addSupplierAnswerMethods.setValue(
      'itemId',
      !isEmpty(productId) ? productId : '',
    );
    addSupplierAnswerMethods.setValue('baseId', '');
    addSupplierAnswerMethods.setValue('quantity', '');
    addSupplierAnswerMethods.setValue('emissionIntensity', '');
    addSupplierAnswerMethods.setValue('baseVolume', '');
    addSupplierAnswerMethods.setValue('allocation', '');
    setEmissionUnit(H03.correspondingUnit);
    setQuantityUnit(H03.quantityUnit);
  }, [addSupplierAnswerMethods.watch('responseType'), modalState.value]);

  React.useEffect(() => {
    if (!modalState.value) return;
    supplierEmissionMutation
      .mutateAsync({
        ...addSupplierAnswerMethods.watch(),
        targetStartMonth: addSupplierAnswerMethods
          .watch('targetStartMonth')
          ?.replace('/', ''),
        targetEndMonth: addSupplierAnswerMethods
          .watch('targetEndMonth')
          ?.replace('/', ''),
      })
      .then((rs) => {
        rs?.result?.nextStepSelectionInfos?.forEach(
          (selectionInfo: SelectionInfo) => {
            switch (selectionInfo.fieldTarget) {
              case 'enterpriseId':
                isEmpty(supplyChainNameId) &&
                  addSupplierAnswerMethods.setValue(
                    selectionInfo.fieldTarget,
                    selectionInfo.selectedValue,
                  );
                setEnterpriseOptions(
                  parseToHgbSelectOptions(selectionInfo.data),
                );
                break;
              case 'supplierId':
                isEmpty(supplyChainNameId) &&
                  addSupplierAnswerMethods.setValue(
                    selectionInfo.fieldTarget,
                    selectionInfo.selectedValue,
                  );
                setSuplierOptions(parseToHgbSelectOptions(selectionInfo.data));
                break;

              case 'responseType':
                isEmpty(supplyChainNameId) &&
                  addSupplierAnswerMethods.setValue(
                    selectionInfo.fieldTarget,
                    selectionInfo.selectedValue,
                  );
                setResponseTypeOptions(
                  parseToHgbSelectOptions(selectionInfo.data),
                );
                break;
              case 'itemId':
                addSupplierAnswerMethods.setValue(
                  selectionInfo.fieldTarget,
                  selectionInfo.selectedValue,
                );
                setItemOptions(parseToHgbSelectOptions(selectionInfo.data));
                break;
              case 'baseId':
                addSupplierAnswerMethods.setValue(
                  selectionInfo.fieldTarget,
                  selectionInfo.selectedValue,
                );
                setOrgOptions(
                  convertObjectToTree(
                    (selectionInfo.dataOrg || []).filter(
                      (item: any) => !getParentKey(item.hierarchicalPath ?? ''),
                    ),
                    selectionInfo.dataOrg || [],
                  ),
                );
                setOrgBaseArr(selectionInfo.dataOrg);
                break;

              default:
                break;
            }
          },
        );
        rs.result?.flowInputDetails?.forEach((flowInputDetail: any) => {
          flowInputDetail.inputGroup.forEach((input: any) => {
            switch (input.fieldTarget) {
              case 'allocation':
                addSupplierAnswerMethods.setValue(
                  input.fieldTarget,
                  input.value || '',
                );
                break;
              case 'quantity':
                addSupplierAnswerMethods.setValue(
                  input.fieldTarget,
                  input.value || '',
                );
                setQuantityUnit(input.unit);
                break;
              case 'responseSummary':
                addSupplierAnswerMethods.setValue(
                  input.fieldTarget,
                  input.value || '',
                );
                break;
              default:
                break;
            }
          });

          switch (flowInputDetail.intensity.fieldTarget) {
            case 'baseVolume':
              addSupplierAnswerMethods.setValue(
                flowInputDetail.intensity.fieldTarget,
                bigNumberToString(flowInputDetail.intensity.value),
              );
              break;
            case 'emissionIntensity':
              addSupplierAnswerMethods.setValue(
                flowInputDetail.intensity.fieldTarget,
                bigNumberToString(flowInputDetail.intensity.value),
              );
              setEmissionUnit(flowInputDetail.intensity.unit);
              break;
            default:
              break;
          }
        });
        setSupplierResponseUsed(rs?.result?.supplierResponseUsed || 0);
      });
  }, [
    addSupplierAnswerMethods.watch('enterpriseId'),
    addSupplierAnswerMethods.watch('responseType'),
    addSupplierAnswerMethods.watch('itemId'),
    addSupplierAnswerMethods.watch('baseId'),
    addSupplierAnswerMethods.watch('targetEndMonth'),
    addSupplierAnswerMethods.watch('targetStartMonth'),
    modalState.value,
  ]);

  React.useEffect(() => {
    if (
      addSupplierAnswerMethods.watch('allocation') === '' &&
      addSupplierAnswerMethods.watch('quantity') === ''
    ) {
      addSupplierAnswerMethods.setValue('emissionVolume', '');
    } else {
      addSupplierAnswerMethods.setValue(
        'emissionVolume',
        hgbFixed(
          calculationFormula(
            responseType,
            addSupplierAnswerMethods.watch('allocation'),
            addSupplierAnswerMethods.watch('quantity'),
            addSupplierAnswerMethods.watch('emissionIntensity'),
            addSupplierAnswerMethods.watch('baseVolume'),
          ),
          15,
        ),
      );
    }
  }, [
    responseType,
    addSupplierAnswerMethods.watch('quantity'),
    addSupplierAnswerMethods.watch('allocation'),
    addSupplierAnswerMethods.watch('baseVolume'),
    addSupplierAnswerMethods.watch('emissionIntensity'),
  ]);

  return (
    <>
      <FormProvider {...addSupplierAnswerMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={
            <HgbModalTitle className="">{H03.titleAdd}</HgbModalTitle>
          }
          open={modalState.value}
          onCancel={modalState.turnOff}
          footer={null}
          closable={true}
          maskClosable={false}
          destroyOnClose={true}
          afterClose={() => {
            addSupplierAnswerMethods.reset(
              defaultAddSupplierEmissionInputPayload,
            );
            setEnterpriseOptions([]);
            setItemOptions([]);
            setOrgOptions([]);
            setSuplierOptions([]);
            setOrgBaseArr([]);
            setErr('');
            setEmissionUnit(H03.correspondingUnit);
            setQuantityUnit(H03.quantityUnit);
          }}
        >
          <HgbModalForm onSubmit={handleSubmitDebounce}>
            <HgbModalFormBody>
              {/* blank */}
              <HgbSelect
                label={H03.groupBuyer}
                {...addSupplierAnswerMethods.register('enterpriseId')}
                options={enterpriseOptions}
                required
                disabled={!isEmpty(buyerId)}
              />
              {/* blank */}
              <HgbSelect
                label={common.columns.supplyChainGroupName}
                {...addSupplierAnswerMethods.register('supplierId')}
                options={suplierOptions}
                required
                disabled={!isEmpty(supplyChainNameId)}
              />
              {/* blank */}
              <HgbSelect
                label={H03.responseType}
                {...addSupplierAnswerMethods.register('responseType')}
                options={responseTypeOptions}
                required
                infoTooltip={H03.responseTooltip}
                palcement={language === 'jp' ? 'topLeft' : 'topRight'}
                disabled={!isEmpty(productId)}
              />
              {/* org */}
              {responseType === '2' ? (
                <HgbTreeSelect
                  label={common.label.organizationBaseName}
                  {...addSupplierAnswerMethods.register('baseId')}
                  treeData={orgOptions}
                  required
                  showSearch
                />
              ) : null}
              {/* blank */}
              <div>
                <div className="tw-flex tw-justify-between tw-gap-36">
                  <HgbDatePicker
                    label={H03.startDate}
                    picker="month"
                    {...addSupplierAnswerMethods.register('targetStartMonth')}
                    required
                    infoTooltip={H03.startDateTooltip}
                    palcement="topLeft"
                    allowClear
                    format="YYYY/MM"
                  />
                  <HgbDatePicker
                    label={H03.endDate}
                    picker="month"
                    {...addSupplierAnswerMethods.register('targetEndMonth')}
                    required
                    infoTooltip={H03.endDateTooltip}
                    palcement="topRight"
                    allowClear
                    format="YYYY/MM"
                  />
                </div>
                {err ? <HgbFieldError>{err}</HgbFieldError> : null}
              </div>
              {/* product */}
              {responseType === '1' ? (
                <>
                  <HgbSelect
                    label={H03.manufacturingCodeProductName}
                    {...addSupplierAnswerMethods.register('itemId')}
                    options={itemOptions}
                    required
                    showSearch
                    infoTooltip={H03.manufacturingCodeProductNameTootip}
                    palcement={language === 'jp' ? 'topLeft' : 'topRight'}
                  />
                  <HgbAntdInput
                    label={H03.quantity}
                    {...addSupplierAnswerMethods.register('quantity')}
                    maxLength={13}
                    required
                    type="positiveDecimal"
                    suffix={quantityUnit}
                    forceMaxDecimal={3}
                  />
                  <HgbAntdInput
                    label={common.label.emissionIntensity}
                    {...addSupplierAnswerMethods.register('emissionIntensity')}
                    required
                    readOnly
                    suffix={emissionUnit}
                  />
                </>
              ) : null}

              {/* org */}
              {responseType === '2' ? (
                <>
                  <HgbAntdInput
                    label={H03.scopeHalfEmissions}
                    {...addSupplierAnswerMethods.register('baseVolume')}
                    required
                    readOnly
                    suffix={'tCO2'}
                  />
                  <HgbAntdInput
                    label={H03.allocation}
                    {...addSupplierAnswerMethods.register('allocation')}
                    required
                    maxLength={4}
                    infoTooltip={H03.allocationTooltip}
                    palcement="topLeft"
                    type={'positiveDecimal'}
                    suffix={'%'}
                  />
                </>
              ) : null}

              {/* blank */}
              <HgbAntdInput
                label={common.label.emissionVolume}
                {...addSupplierAnswerMethods.register('emissionVolume')}
                readOnly
                required
                suffix={'tCO2'}
              />
              {/* blank */}
              <HgbAntdInput
                label={H03.responseSummary}
                {...addSupplierAnswerMethods.register('responseSummary')}
                type="text"
              />
            </HgbModalFormBody>
            <HgbModalFormFooter>
              <HgbButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
              >
                {common.button.cancel}
              </HgbButton>
              <HgbButton
                type="primary"
                className="tw-min-w-100"
                htmlType="submit"
                loading={validationPayloadMutation.isLoading}
              >
                {common.button.respond}
              </HgbButton>
            </HgbModalFormFooter>
          </HgbModalForm>
        </Modal>
      </FormProvider>
      <ConfirmModalSubmit
        title={H03.modalCreate.title}
        subTitle={H03.modalCreate.subTitle}
        okText={common.button.respond}
        cancelText={common.button.cancel}
        className="tw-grid tw-grid-cols-[minmax(100px,_auto),_1fr] [&_p]:tw-font-regular"
      ></ConfirmModalSubmit>
      <ConfirmModal
        title={
          <ExclamationCircleOutlined className="tw-text-[50px] tw-text-[#fd7e14]" />
        }
        okText={common.button.confirm}
        cancelText={common.button.cancel}
        className="tw-font-regular"
      >
        {H03.modalSubmit.content(
          orgName,
          supplierResponseUsed,
          addSupplierAnswerMethods.getValues('targetStartMonth') || '',
          addSupplierAnswerMethods.getValues('targetEndMonth') || '',
        )}
      </ConfirmModal>
    </>
  );
};

export { AddSupplierAnswerModal };
