import { cn } from '@utils/cn';
import React from 'react';

export type LoadingType = 'xLarge' | 'large' | 'regular' | 'small';

interface HgbLoadingProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  type?: LoadingType;
}

export const HgbLoading: React.FC<HgbLoadingProps> = ({
  type = 'regular',
  className = '',
}) => {
  return (
    <div
      className={cn(
        `tw-fixed tw-z-10 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-white tw-opacity-90 ${className}`,
      )}
    ></div>
  );
};
