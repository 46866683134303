import { HgbButton } from '@common/atoms/HgbButton';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { AuthenTemplate } from '@layouts/templates';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { SpsvCredit } from '@pages/Management/Setting/D08/SpsvCredit';
import { useBooleanState } from '@hooks/useBooleanState';
import { LocalDataClass } from 'data-class/LocalDataClass';

interface CompaniesUnpaidFeeProps {}

const CompaniesUnpaidFee: React.FC<
  CompaniesUnpaidFeeProps
> = () => {
  const {
    text: { common, A05 },
  } = React.useContext(LanguageContext)!;
  const { user } = React.useContext(AuthContext)!;
  const navigate = useNavigate();
  const { routs } = React.useContext(LanguageContext)!;
  const spsvCreditState = useBooleanState(false);

  const userLocal = LocalDataClass.user;
  userLocal.isLocked = false;
  LocalDataClass.user = userLocal;

  return (
    <>
      <SpsvCredit modalState={spsvCreditState} />
      <AuthenTemplate
        title={
          user.isEnterpriseAdmin
            ? common.label.titleAdminUnpaidFee
            : common.label.titleUserUnpaidFee
        }
      >
        <>
          {user.isEnterpriseAdmin && common.message.messAdminUnpaidFee}

          {user.isEnterpriseUser && common.message.messUserUnpaidFee}

          <div className="tw-flex tw-flex-col tw-gap-16 ">
            <HgbButton
              htmlType="submit"
              type="link"
              onClick={() => navigate(routs['/logout'].link)}
            >
              {A05.pageTitleLogout}
            </HgbButton>
            {user.isEnterpriseAdmin && (
              <HgbButton
                htmlType="submit"
                type="primary"
                onClick={spsvCreditState.turnOn}
              >
                {common.button.cardPayment}
              </HgbButton>
            )}
          </div>
        </>
      </AuthenTemplate>
    </>
  );
};

export { CompaniesUnpaidFee };
