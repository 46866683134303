import { HgbButton } from '@common/atoms/HgbButton';
import HgbModalForm from '@common/antd/HgbModalForm';
import { HgbModalFormBody } from '@common/antd/HgbModalFormBody';
import { HgbModalFormFooter } from '@common/antd/HgbModalFormFooter';
import { HgbModalTitle } from '@common/antd/HgbModalTitle';
import { HgbAntdInput } from '@common/forms';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { BooleanState } from '@hooks/useBooleanState';
import { debug } from '@utils/debug';
import { trimWithEn } from '@utils/text';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationPost } from 'services/common/mutation';
import * as yup from 'yup';

interface IAddSupplyOwnerModalProps {
  modalState: BooleanState;
}

type AddSupplyOwnerPayload = {
  supplyChainName: string;
  supplyChainDescription: string;
};

const defaultAddSupplyOwnerPayload: AddSupplyOwnerPayload = {
  supplyChainName: '',
  supplyChainDescription: '',
};

const AddSupplyOwnerModal: React.FC<
  IAddSupplyOwnerModalProps
> = ({ modalState }) => {
  const {
    text: { common, D15, E0000 },
  } = React.useContext(LanguageContext)!;
  const queryClient = useQueryClient();
  const { user } = React.useContext(AuthContext)!;

  const addSupplyOwnerSchema = yup.object({
    supplyChainName: yup
      .string()
      .trim()
      .required(E0000(common.columns.supplyChainGroupName)),
  });

  const addSupplyOwnerMethods = useForm<AddSupplyOwnerPayload>({
    resolver: yupResolver(addSupplyOwnerSchema),
    values: defaultAddSupplyOwnerPayload,
  });

  const addSupplyOwnerMutate = useHgbMutationPost<
    unknown,
    AddSupplyOwnerPayload
  >(API_URLS.BUYER_CHAIN, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.BUYER_CHAIN_LIST],
      });
      modalState.turnOff();
      addSupplyOwnerMethods.reset(defaultAddSupplyOwnerPayload);
    },
    onError: () => {},
  });

  const handleSubmit = addSupplyOwnerMethods.handleSubmit(
    (data, e) => {
      addSupplyOwnerMutate.mutate({
        ...data,
        supplyChainDescription: trimWithEn(data.supplyChainDescription),
        supplyChainName: trimWithEn(data.supplyChainName),
      });
    },
    (error) => {
      debug.error(error);
    },
  );

  return (
    <FormProvider {...addSupplyOwnerMethods}>
      <Modal
        className="ant-modal-hgb-custom"
        centered
        title={<HgbModalTitle>{D15.titleAddModal}</HgbModalTitle>}
        open={modalState.value}
        onCancel={modalState.turnOff}
        footer={null}
        destroyOnClose={true}
        closable={true}
        maskClosable={false}
        afterClose={() =>
          addSupplyOwnerMethods.reset(defaultAddSupplyOwnerPayload)
        }
      >
        <HgbModalForm onSubmit={handleSubmit}>
          <HgbModalFormBody>
            <HgbAntdInput
              {...addSupplyOwnerMethods.register('supplyChainName')}
              maxLength={50}
              type={'K'}
              required
              label={common.columns.supplyChainGroupName}
            />
            <HgbAntdInput
              {...addSupplyOwnerMethods.register('supplyChainDescription')}
              maxLength={200}
              type={'area'}
              label={D15.columns.note}
              rows={2}
            />
            <div className="tw-space-y-4">
              <label className="tw-text-paragraph tw-font-bold">
                {D15.label.inviteCode}
              </label>
              <p>{D15.noteInvite}</p>
            </div>
          </HgbModalFormBody>
          <HgbModalFormFooter>
            <HgbButton
              className="tw-min-w-100"
              onClick={modalState.turnOff}
            >
              {common.button.cancel}
            </HgbButton>
            <HgbButton
              type="primary"
              className="tw-min-w-100"
              htmlType="submit"
            >
              {common.button.add}
            </HgbButton>
          </HgbModalFormFooter>
        </HgbModalForm>
      </Modal>
    </FormProvider>
  );
};

export default AddSupplyOwnerModal;
