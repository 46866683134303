import { cn } from '@utils/cn';
import { Button } from 'antd';
import { FC } from 'react';
import { Link, LinkProps, useNavigate } from 'react-router-dom';

interface HgbLinkProps extends LinkProps {
  disabled?: boolean;
}

export const HgbLink: FC<HgbLinkProps> = ({
  className,
  disabled = false,
  children,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <Button
      type="link"
      size="large"
      shape="round"
      className={cn("tw-min-h-44 [&>a]:tw-text-inherit [&>a]:tw-underline [&>a]:tw-underline-offset-2", className)}
      onClick={() => {
        navigate(props.to);
      }}
    >
      <Link
        {...props}
        style={{ textDecorationSkipInk: 'none', textUnderlineOffset: '4px' }}
      >
        {children}
      </Link>
    </Button>
  );
};
