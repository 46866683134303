import { HgbButton } from '@common/atoms/HgbButton';
import { HgbBulletItem } from '@common/contents';
import { HgbAntdInput } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { PASSWORD_REGEX } from '@constants/regex';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthenTemplate } from '@layouts/templates';
import { LocalDataClass } from 'data-class/LocalDataClass';
import React, { useContext, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useHgbMutationPost } from 'services/common/mutation';
import { ChangePasswordRequest } from 'services/types/authen';
import * as yup from 'yup';

const CHANGE_PASSWORD_REQUEST_DEFAULT: ChangePasswordRequest = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const ChangePassword = () => {
  let timer = React.useRef<any>(null);
  const navigate = useNavigate();
  const {
    text: { A04, common, E0000 },
    routs,
  } = useContext(LanguageContext)!;
  const { user } = React.useContext(AuthContext)!;

  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const changePasswordSchema = yup.object({
    oldPassword: yup.string().required(E0000(A04.label.oldPassword)),
    newPassword: yup
      .string()
      .required(E0000(A04.label.newPassword))
      .test(
        'len',
        A04.message.newPasswordLength,
        (val) => val.length >= 12 && val.length <= 16,
      )
      .matches(PASSWORD_REGEX, A04.message.newPasswordFormat),
    confirmPassword: yup.string().required(E0000(A04.label.confirmPassword)),
  });

  const methods = useForm<ChangePasswordRequest>({
    defaultValues: CHANGE_PASSWORD_REQUEST_DEFAULT,
    resolver: yupResolver(changePasswordSchema),
  });
  const { register, handleSubmit, reset, setFocus, trigger } = methods;

  const changePasswordSuccessHandler = (data: any) => {
    if (data.statusCode !== 10000) {
      changePasswordFailHandler();
      return;
    } else {
      setChangePasswordSuccess(true);
    }
  };

  const changePasswordFailHandler = () => {
    setFocus('oldPassword');
  };

  const { mutate: changePasswordMutate } = useHgbMutationPost<
    unknown,
    ChangePasswordRequest
  >(API_URLS.CHANGE_PASSWORD, {
    onSuccess: changePasswordSuccessHandler,
    onError: changePasswordFailHandler,
  });

  const onSubmit: SubmitHandler<ChangePasswordRequest> = (data) => {
    changePasswordMutate(data);
  };

  const onNameChange = async (value: string) => {
    const valid = await trigger('newPassword');
    if (!valid) {
      return;
    }
  };

  const cancelChangePassword = () => {
    LocalDataClass.user = {
      ...user,
      authenStatus: 'SUCCESS',
    };
    timer.current = setTimeout(() => {
      navigate(routs['/dashboard'].link);
      if (timer.current) clearTimeout(timer.current);
    }, 200);
  };

  React.useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, []);
  return (
    <AuthenTemplate
      title={changePasswordSuccess ? A04.pageTitleSuccess : common.button.changePassword}
    >
      <FormProvider {...methods}>
        <form className="tw-grid tw-gap-24" onSubmit={handleSubmit(onSubmit)}>
          {changePasswordSuccess ? (
            <>
              <small className="tw-block">
                {A04.message.changePasswordSuccess}
              </small>
              <HgbButton
                onClick={cancelChangePassword}
                className="tw-mx-auto"
              >
                {common.button.gotoDashboard}
              </HgbButton>
            </>
          ) : (
            <>
              {user.authenStatus === 'MUST_CHANGE_PASSWORD' && (
                <div>
                  <HgbBulletItem className="tw-font-regular">
                    {A04.message.passwordExpired}
                  </HgbBulletItem>
                  <HgbBulletItem className="tw-font-regular">
                    {A04.message.enterCurrentPasswordAndNewPassword}
                  </HgbBulletItem>
                </div>
              )}
              {user.authenStatus === 'SUCCESS' && (
                <p>{A04.message.enterCurrentPasswordAndNewPassword}</p>
              )}

              <HgbAntdInput
                {...register('oldPassword')}
                label={A04.label.oldPassword}
                required
                placeholder={A04.placeholder.oldPassword}
                maxLength={16}
                type="password"
              />
              <HgbAntdInput
                {...register('newPassword')}
                label={A04.label.newPassword}
                required
                placeholder={A04.placeholder.newPassword}
                maxLength={16}
                type="password"
                onChangeValue={(e) => {
                  onNameChange(e.target?.value);
                }}
              />
              <HgbAntdInput
                {...register('confirmPassword')}
                label={A04.label.confirmPassword}
                required
                placeholder={A04.placeholder.confirmPassword}
                maxLength={16}
                type="password"
              />
              <div className="tw-flex tw-items-center tw-justify-center tw-gap-16">
                {user.authenStatus !== 'MUST_CHANGE_PASSWORD' && (
                  <HgbButton
                    className="tw-min-w-[140px]"
                    htmlType="button"
                    onClick={cancelChangePassword}
                  >
                    <>{common.button.cancel}</>
                  </HgbButton>
                )}
                <HgbButton
                  type="primary"
                  htmlType="submit"
                  className="tw-min-w-[140px]"
                >
                  <>{common.button.change}</>
                </HgbButton>
              </div>
            </>
          )}
        </form>
      </FormProvider>
    </AuthenTemplate>
  );
};
