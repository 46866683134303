import {
  EditOutlined,
  PlusOutlined,
  StopOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { HgbButton } from '@common/atoms/HgbButton';
import { HgbEmpty } from '@common/antd/HgbEmpty';
import { HgbSpin } from '@common/antd/HgbSpin';
import { HgbCellTooltip } from '@common/contents';
import { HgbCellLink } from '@common/contents/HgbCellLink';
import { HgbAntdInput } from '@common/forms';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { useBooleanState } from '@hooks/useBooleanState';
import { useConfirm } from '@hooks/useConfirm';
import { useDebounce } from '@hooks/useDebound';
import { PaginationProps, usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { hgbAxiosGetSingle } from '@utils/axios';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import BigNumber from 'bignumber.js';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationDelete } from 'services/common/mutation';
import { useHgbQueryWithPaging } from 'services/common/query';
import { WithKey } from 'types/common';
import { AddSupplierAnswerModal } from '../H03/AddSupplierAnswerModal';
import AddSupplierProductModal from './AddSupplierProductModal';
import EditSupplierProductModal from './EditSupplierProductModal';
import ViewSupplierProductModal from './ViewSupplierProductModal';

const STATUS = {
  active: 'ACTIVE',
  inActive: 'INACTIVE',
  register: 'REGISTER',
  pending: 'PENDING',
};

export type SupplierProduct = {
  id: number;
  productId: number;
  supplierId: number;
  serialNumber: string;
  productName: string;
  emissionIntensity: string;
  emissionIntensityUnit: string;
  gtinCode: number;
  upcCode: number;
  numberOfUses: number;
  status: string;
  createdDate: string;
  lastModifiedDate: string;
  unit?: string;
};

export type GetSupplierProductsRequest = PaginationProps & {
  sortBy?: string;
  nameSearch?: string;
};

export type GetSupplierProductID = {
  productId?: number;
};

const D19 = () => {
  const {
    text: { common, D19, E0041 },
  } = useContext(LanguageContext)!;

  const supplierProductsSearchMethods = useForm<{ nameSearch: string }>({
    values: { nameSearch: '' },
  });
  const { addMessage } = useContext(PortalContext)!;
  const { paginationProps, resetPaginations, PaginationComponent } = usePagin();

  useEffect(() => {
    resetPaginations();
  }, [supplierProductsSearchMethods.watch('nameSearch')]);

  const nameSearchDebounce = useDebounce(
    supplierProductsSearchMethods.watch('nameSearch'),
    300,
  );

  const [confirmData, setConfirmData] = useState<SupplierProduct>();

  const addModalState = useBooleanState(false);

  const [editData, setEditData] = useState<SupplierProduct>();
  const editModalState = useBooleanState(false);

  const [viewData, setViewData] = useState<SupplierProduct>();
  const viewModalState = useBooleanState(false);

  const [responseModalData, setResponseModalData] = useState<string>();
  const responseModalState = useBooleanState(false);

  const queryClient = useQueryClient();
  const { ConfirmModal, hgbConfirm } = useConfirm();

  const showViewModal = (record: SupplierProduct) => {
    setViewData(record);
    viewModalState.turnOn();
  };

  const supplierProductsRequest: GetSupplierProductsRequest = {
    ...paginationProps,
    nameSearch: nameSearchDebounce,
  };

  const supplierProductsQuery = useHgbQueryWithPaging<
    SupplierProduct[],
    GetSupplierProductsRequest
  >(API_URLS.SUPPLIER_PRODUCT, supplierProductsRequest, {
    queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST, supplierProductsRequest],
  });

  const suspendSupplierProductMutate = useHgbMutationDelete<unknown, number>(
    API_URLS.SUPPLIER_PRODUCT,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
        });
        responseModalState.turnOff();
      },
      onError: () => {},
      isAlert: false,
    },
  );

  const handleResponse = async (record: SupplierProduct) => {
    const product = await hgbAxiosGetSingle<SupplierProduct>(
      API_URLS.SUPPLIER_PRODUCT,
      record.id,
    );
    if (product?.status !== record.status) {
      addMessage('error', E0041);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
      });
      return;
    }

    setResponseModalData(record.productId.toString());
    responseModalState.turnOn();
  };

  const handleEdit = async (record: SupplierProduct) => {
    const product = await hgbAxiosGetSingle<SupplierProduct>(
      API_URLS.SUPPLIER_PRODUCT,
      record.id,
    );
    if (product?.status !== record.status || product.numberOfUses > 0) {
      addMessage('error', E0041);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
      });
      return;
    }

    setEditData(record);
    editModalState.turnOn();
  };

  const handleSuspend = async (record: SupplierProduct) => {
    const product = await hgbAxiosGetSingle<SupplierProduct>(
      API_URLS.SUPPLIER_PRODUCT,
      record.id,
    );
    if (product?.status !== record.status) {
      addMessage('error', E0041);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
      });
      return;
    }

    setConfirmData(record);
    const result = await hgbConfirm();
    if (result) {
      suspendSupplierProductMutate.mutate(record?.productId);
    }
  };

  const columns: ColumnsType<SupplierProduct> = [
    {
      title: D19.serialNumber,
      dataIndex: 'serialNumber',
      width: 140,
      className: 'tw-max-w-200',
      render(value, record) {
        return (
          <HgbCellLink onClick={() => showViewModal(record)}>
            <HgbCellTooltip>{value}</HgbCellTooltip>
          </HgbCellLink>
        );
      },
    },
    {
      title: D19.productName,
      dataIndex: 'productName',
      align: 'left',
      width: 250,
      className: 'tw-max-w-200',
      render(value) {
        return <HgbCellTooltip>{value}</HgbCellTooltip>;
      },
    },
    {
      title: D19.gtinCode,
      dataIndex: 'gtinCode',
      align: 'left',
      width: 120,
    },
    {
      title: D19.upcCode,
      dataIndex: 'upcCode',
      align: 'left',
      width: 120,
    },
    {
      title: D19.emissionsPerUnit,
      dataIndex: 'emissionIntensity',
      align: 'center',
      width: 160,
      render(value, record) {
        if (!!value) {
          const valueCustom = new BigNumber(value).toFixed();
          const unit = [record?.emissionIntensityUnit, record?.unit]
            .filter((item) => item !== null && item !== undefined)
            .join('/');
          return <div>{`${valueCustom} ${unit}`}</div>;
        }
        return '';
      },
    },
    {
      title: D19.columns.dateOfRegistration,
      dataIndex: 'createdDate',
      align: 'center',
      width: 170,
    },
    {
      title: D19.columns.updatedOn,
      dataIndex: 'lastModifiedDate',
      align: 'center',
      width: 120,
    },
    {
      title: D19.columns.numberOfUses,
      dataIndex: 'numberOfUses',
      align: 'center',
      width: 140,
    },
    {
      title: D19.columns.status,
      dataIndex: 'status',
      align: 'center',
      width: 120,
      render(_, record) {
        return (
          <div>
            {record?.status === STATUS.inActive
              ? D19.columns.inactive
              : D19.columns.active}
          </div>
        );
      },
    },
    {
      title: common.label.action,
      align: 'center',
      dataIndex: '_',
      render(_, record) {
        return (
          <div className="tw-flex tw-items-center tw-justify-center tw-gap-24">
            <HgbActionButton
              disabled={record?.status === STATUS.inActive}
              onClick={() => handleResponse(record)}
              icon={<UserSwitchOutlined />}
            >
              {common.button.respond}
            </HgbActionButton>
            <HgbActionButton
              icon={<EditOutlined />}
              disabled={
                record?.numberOfUses > 0 || record?.status === STATUS.inActive
              }
              onClick={() => handleEdit(record)}
            >
              {common.button.update}
            </HgbActionButton>
            <HgbActionButton
              disabled={record?.status === STATUS.inActive}
              onClick={() => handleSuspend(record)}
              icon={<StopOutlined />}
            >
              {common.button.suspend}
            </HgbActionButton>
          </div>
        );
      },
    },
  ];

  const rows: WithKey<SupplierProduct>[] = useMemo(() => {
    return (supplierProductsQuery?.data?.result?.content ?? []).map((item) => ({
      ...item,
      id: item.productId,
      key: item.productId,
    }));
  }, [supplierProductsQuery?.data?.result?.content]);

  return (
    <ManagementTemplate>
      <DataTableTemplate
        title={D19.title}
        buttonComponent={
          <HgbButton
            type="primary"
            icon={<PlusOutlined />}
            onClick={addModalState.turnOn}
          >
            {common.button.register}
          </HgbButton>
        }
        paginationComponents={
          <PaginationComponent
            current={supplierProductsQuery.data?.result?.currentPage}
            total={supplierProductsQuery.data?.result?.totalRecord}
            pageSize={supplierProductsQuery.data?.result?.pageSize}
          />
        }
        inputsComponent={
          <div className="tw-rounded-8 tw-bg-primary-1/20 tw-p-16">
            <FormProvider {...supplierProductsSearchMethods}>
              <HgbAntdInput
                {...supplierProductsSearchMethods.register('nameSearch')}
                type="K"
                maxLength={100}
                search
                className="pc:tw-w-240"
                placeholder={common.placeholder.filter}
              />
            </FormProvider>
          </div>
        }
      >
        {rows.length > 0 ? (
          <Table
            columns={columns}
            dataSource={rows}
            pagination={false}
            className="tw-overflow-hidden"
            scroll={{ x: 'max-content' }}
          />
        ) : null}

        {supplierProductsQuery.isLoading ? (
          <HgbSpin placement="middle" />
        ) : null}

        {supplierProductsQuery.isFetched && rows.length === 0 ? (
          <HgbEmpty description={common.message.noResult} />
        ) : null}
      </DataTableTemplate>

      <AddSupplierProductModal modalState={addModalState} />

      {editData ? (
        <EditSupplierProductModal modalState={editModalState} data={editData} />
      ) : null}

      {viewData ? (
        <ViewSupplierProductModal modalState={viewModalState} data={viewData} />
      ) : null}

      <AddSupplierAnswerModal
        modalState={responseModalState}
        productId={responseModalData}
      />

      <ConfirmModal
        cancelText={common.button.cancel}
        okText={common.button.suspend}
        title={D19.titleModal}
        subTitle={D19.modal.attentionMessage}
        className="tw-grid tw-grid-cols-[minmax(100px,_auto),_1fr] [&_p]:tw-font-regular"
      >
        <p>{D19.modal.serialNumber}&nbsp;:</p>
        <p>&nbsp;{confirmData?.serialNumber}</p>
        <p>{D19.modal.productName}&nbsp;:</p>
        <p>&nbsp;{confirmData?.productName}</p>
      </ConfirmModal>
    </ManagementTemplate>
  );
};

export default D19;
