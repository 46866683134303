import { HgbLink } from '@common/components';
import { routs_en } from '@constants/ROUT';
import { LanguageContext } from '@contexts/LanguageContext';
import { AuthenTemplate } from '@layouts/templates';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// not found
export const CM02: React.FC = () => {
  const {
    text: { common },
  } = React.useContext(LanguageContext)!;

  const { pathname } = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    if (pathname.trim() !== routs_en['/not-found'].link) {
      navigate(routs_en['/not-found'].link);
    }
  }, [pathname]);

  return (
    <AuthenTemplate title={common.message.tit_not_found} logo={false}>
      <p className="tw-text-center">{common.message.mess_not_found}</p>
      <HgbLink to={routs_en['/dashboard'].link}>
        {common.button.gotoDashboard}
      </HgbLink>
    </AuthenTemplate>
  );
};
