import { HgbModal, HgbModalProps } from '@common/antd/HgbModal';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { useDelete } from '@hooks/useApi';
import * as React from 'react';
import { useHgbQuery } from 'services/common/query';
import {
  EmissionOutput,
  OffsetDetails,
} from 'types/api/emission-output/emission-output.type';

export type EmissionDeleteData = {
  id: string;
  scopeCode: string;
  categoryCode: string;
};

export interface EmissionDeleteModalProps extends HgbModalProps {
  data?: OffsetDetails | EmissionOutput;
  onSuccess?: () => void;
  onFail?: () => void;
}

export const EmissionDeleteModal: React.FC<
  EmissionDeleteModalProps
> = ({ data, onSuccess, onFail, ...props }) => {
  const {
    text: { C01, common },
    language,
  } = React.useContext(LanguageContext)!;
  const { addMessage } = React.useContext(PortalContext)!;

  const [emissionResponse, deleteEmission] = useDelete<undefined, string>(
    () => {
      onSuccess?.();
    },
    () => {
      onFail?.();
    },
  );

  const [, deleteOffset] = useDelete<undefined, string>(() => {
    onSuccess?.();
  });

  const removeListIdQuery = useHgbQuery<string[], { listId: string }>(
    API_URLS.LIST_ID_REMOVE,
    { listId: data?.id.toString() || '' },
    {
      enabled: data?.id !== undefined && data?.scopeCode !== '4',
      queryKey: [API_URLS.LIST_ID_REMOVE, data?.id],
    },
  );

  const deleteHandler = () => {
    const { scopeCode, categoryCode, id } = data ?? {
      scopeCode: '',
      categoryCode: '',
      id: '',
    };

    if (scopeCode === '4') {
      const url = `/api/v1/offsets?lang=${language}&listOffsetId=${(
        removeListIdQuery.data?.result || [id]
      ).toString()}`;
      deleteOffset(url, (removeListIdQuery.data?.result || [id]).toString());
    } else {
      let url = '';
      if (data?.typeOfGas === 'CO2') {
        url = `/api/v1/emission-input/scope-${scopeCode}${
          categoryCode !== '00' ? `/category-${Number(categoryCode)}` : ''
        }?lang=${language}&listActivityId=${(
          removeListIdQuery.data?.result || []
        ).toString()}`;
      } else {
        url = `/api/v1/emission-input/green-house-gas?lang=${language}&listActivityId=${(
          removeListIdQuery.data?.result || []
        ).toString()}`;
      }
      deleteEmission(url, (removeListIdQuery.data?.result || []).toString());
    }
  };

  return (
    <HgbModal
      title={
        data?.insertMethodCode !== '3'
          ? C01.modal.emissionDeleteTitle
          : C01.modal.supplierDeleteTitle
      }
      width={510}
      cancelText={common.button.cancel}
      okText={common.button.delete}
      onOk={deleteHandler}
      {...props}
    >
      {data?.insertMethodCode !== '3'
        ? C01.message.deleteEmissionConfirm
        : C01.modal.supplierConfirmModalSubTitle(
            (removeListIdQuery.data?.result || []).length.toString(),
          )}
    </HgbModal>
  );
};
