import { HgbButton } from '@common/atoms/HgbButton';
import HgbModalForm from '@common/antd/HgbModalForm';
import { HgbModalFormBody } from '@common/antd/HgbModalFormBody';
import { HgbModalFormFooter } from '@common/antd/HgbModalFormFooter';
import { HgbModalTitle } from '@common/antd/HgbModalTitle';
import { HgbAntdInput } from '@common/forms';
import { LanguageContext } from '@contexts/LanguageContext';
import { BooleanState } from '@hooks/useBooleanState';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Supplier } from './D17';

interface ViewSupplierModalProps {
  modalState: BooleanState;
  data?: Supplier;
}

const ViewSupplierModal: React.FC<ViewSupplierModalProps> = ({
  modalState,
  data,
}) => {
  const {
    text: { common, D15, D17 },
  } = React.useContext(LanguageContext)!;
  const viewSupplierMethods = useForm<Supplier>({ values: data });
  return (
    <>
      <FormProvider {...viewSupplierMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={<HgbModalTitle>{D17.titleViewModal}</HgbModalTitle>}
          open={modalState.value}
          onCancel={modalState.turnOff}
          destroyOnClose={true}
          footer={null}
          closable={true}
          maskClosable={false}
        >
          <HgbModalForm>
            <HgbModalFormBody>
              <HgbAntdInput
                {...viewSupplierMethods.register('inviteCode')}
                required
                label={D15.label.inviteCode}
                readOnly
              />
              <HgbAntdInput
                {...viewSupplierMethods.register('buyerName')}
                type={'K'}
                label={D17.label.supplyChainGroupBuyer}
                readOnly
              />
              <HgbAntdInput
                {...viewSupplierMethods.register('supplyChainName')}
                type={'K'}
                label={common.columns.supplyChainGroupName}
                readOnly
              />
            </HgbModalFormBody>
            <HgbModalFormFooter>
              <HgbButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
                htmlType="button"
              >
                {common.button.cancel}
              </HgbButton>
            </HgbModalFormFooter>
          </HgbModalForm>
        </Modal>
      </FormProvider>
    </>
  );
};

export default ViewSupplierModal;
