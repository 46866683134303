import { HgbErrorFilledIcon } from '@common/icons/filled';
import { DivProps } from '@common/types';
import { cn } from '@utils/cn';
import * as React from 'react';

interface HgbFieldErrorProps extends DivProps { }

export const HgbFieldError: React.FC<HgbFieldErrorProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(
        `tw-relative tw-mt-4 tw-indent-12 tw-text-error-7 ${className}`,
      )}
      {...props}
    >
      <HgbErrorFilledIcon
        className="tw-absolute tw-left-0 tw-top-4 tw-mr-4 tw-mt-4"
        size="16"
      />
      <span className="tw-text-tiny">{children}</span>
    </div>
  );
};
