import { HgbButton } from '@common/atoms/HgbButton';
import { HgbBulletItem } from '@common/contents';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { AuthenTemplate } from '@layouts/templates';
import React, { useContext } from 'react';

export const SessionTimeout: React.FC = () => {
  const {
    text: { A05, common },
  } = useContext(LanguageContext)!;
  const { backToLogin } = useContext(AuthContext)!;

  return (
    <AuthenTemplate title={A05.pageTitleSessionTimeOut}>
      <div>
        <HgbBulletItem className="tw-font-regular">
          {A05.message.sessionTimeout}
        </HgbBulletItem>
        <HgbBulletItem className="tw-font-regular">
          {A05.message.loginAgain}
        </HgbBulletItem>
      </div>
      <HgbButton type="link" onClick={backToLogin} className="tw-mx-auto">
        {common.button.backToLogin}
      </HgbButton>
    </AuthenTemplate>
  );
};
