import { HgbButton } from '@common/atoms/HgbButton';
import HgbModalForm from '@common/antd/HgbModalForm';
import { HgbModalFormBody } from '@common/antd/HgbModalFormBody';
import { HgbModalFormFooter } from '@common/antd/HgbModalFormFooter';
import { HgbModalTitle } from '@common/antd/HgbModalTitle';
import { HgbAntdInput } from '@common/forms';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { STATUS_CODE } from '@constants/consts';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { BooleanState } from '@hooks/useBooleanState';
import { debug } from '@utils/debug';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import * as yup from 'yup';
import { SupplyOwner } from '../D15/D15';

interface IAddSupplierModalProps {
  modalState: BooleanState;
}

type AddSupplierPayload = {
  inviteCode: string;
  supplyChainName: string;
  buyerId: number;
  supplyChainBuyerId?: number;
};

type AddSupplier = AddSupplierPayload & {
  buyerName: string;
};

const defaultAddSupplierPayload: AddSupplier = {
  inviteCode: '',
  supplyChainName: '',
  buyerId: 0,
  buyerName: '',
};

export type GetInfoBuyerRequest = {
  inviteCode: string;
};

const AddSupplierModal: React.FC<IAddSupplierModalProps> = ({
  modalState,
}) => {
  const {
    text: { common, D15, E0000, D17, E0102, I0005 },
  } = React.useContext(LanguageContext)!;
  const queryClient = useQueryClient();

  const addSupplierSchema = yup.object({
    inviteCode: yup
      .string()
      .required(E0000(D15.label.inviteCode))
      .length(10, E0102),
    supplyChainName: yup
      .string()
      .required(E0000(common.columns.supplyChainGroupName)),
    buyerName: yup
      .string()
      .required(E0000(D17.label.supplyChainGroupBuyer)),
  });

  const addSupplierMethods = useForm<AddSupplier>({
    resolver: yupResolver(addSupplierSchema),
    values: defaultAddSupplierPayload,
  });

  useHgbQuery<SupplyOwner, GetInfoBuyerRequest>(
    API_URLS.GET_INFO_BUYER,
    { inviteCode: addSupplierMethods.getValues('inviteCode') },
    {
      onSuccess(data) {
        addSupplierMethods.setValue('buyerId', data.result?.buyerId || 0);
        addSupplierMethods.setValue('buyerName', data.result?.buyerName || '');
        addSupplierMethods.setValue('supplyChainBuyerId', data.result?.id || 0);
        addSupplierMethods.setValue(
          'supplyChainName',
          data.result?.supplyChainName || '',
        );
        if (data.statusCode === STATUS_CODE.notFound) {
          addSupplierMethods.setError('inviteCode', {
            message: I0005,
          });
        }
      },
      enabled: addSupplierMethods.getValues('inviteCode').length === 10,
      queryKey: [
        API_URLS.GET_INFO_BUYER,
        addSupplierMethods.getValues('inviteCode'),
      ],
    },
  );

  const addSupplierMutate = useHgbMutationPost<unknown, AddSupplierPayload>(
    API_URLS.SUPPLIER_CHAIN,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
        });
        modalState.turnOff();
        addSupplierMethods.reset(defaultAddSupplierPayload);
      },
    },
  );

  const handleSubmit = addSupplierMethods.handleSubmit(
    (data, e) => {
      addSupplierMutate.mutate({
        ...data,
        supplyChainBuyerId: addSupplierMethods.getValues('supplyChainBuyerId'),
      });
    },
    (error) => {
      debug.error(error);
    },
  );

  return (
    <>
      <FormProvider {...addSupplierMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={<HgbModalTitle>{D17.titleAddModal}</HgbModalTitle>}
          open={modalState.value}
          onCancel={modalState.turnOff}
          footer={null}
          destroyOnClose={true}
          closable={true}
          maskClosable={false}
          afterClose={() => addSupplierMethods.reset(defaultAddSupplierPayload)}
        >
          <HgbModalForm onSubmit={handleSubmit}>
            <HgbModalFormBody>
              <HgbAntdInput
                {...addSupplierMethods.register('inviteCode')}
                maxLength={10}
                required
                label={D15.label.inviteCode}
              />
              <HgbAntdInput
                {...addSupplierMethods.register('buyerName')}
                type={'K'}
                label={D17.label.supplyChainGroupBuyer}
                readOnly
              />
              <HgbAntdInput
                {...addSupplierMethods.register('supplyChainName')}
                type={'K'}
                label={common.columns.supplyChainGroupName}
                readOnly
              />
            </HgbModalFormBody>
            <HgbModalFormFooter>
              <HgbButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
              >
                {common.button.cancel}
              </HgbButton>
              <HgbButton
                type="primary"
                className="tw-min-w-100"
                htmlType="submit"
              >
                {common.button.participate}
              </HgbButton>
            </HgbModalFormFooter>
          </HgbModalForm>
        </Modal>
      </FormProvider>
    </>
  );
};

export default AddSupplierModal;
