import { TimeoutType } from '@common/fnTypes';
import { HgbExpandMoreOutlinedIcon } from '@common/icons/outlined';
import { HgbCheckedModalOutlinedIcon } from '@common/icons/outlined/HgbCheckedModalOutlinedIcon';
import { HgbErrorModalOutlinedIcon } from '@common/icons/outlined/HgbErrorModalOutlinedIcon';
import { cn } from '@utils/cn';
import { Modal, ModalProps, Popconfirm, PopconfirmProps } from 'antd';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { HgbButton } from '../atoms/HgbButton';

export interface HgbModalProps extends ModalProps {
  // onCancel?: () => void;
  onOk?: () => void;
  onBack?: () => void;
  onDraft?: () => void;
  disableOk?: boolean;
  disableDraft?: boolean;
  formProps?: React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  >;
  okConfirmProps?: PopconfirmProps;
  enterForSubmit?: boolean;
  successModal?: boolean;
  hgbLogo?: boolean;
  errorModal?: boolean;
  draftText?: string;
  reject?: boolean;
  preventSubmitTime?: number;
}
export const HgbModal: React.FC<HgbModalProps> = ({
  className,
  children,
  okText,
  cancelText,
  draftText,
  title,
  onOk,
  disableOk = false,
  disableDraft = false,
  formProps,
  destroyOnClose,
  onBack,
  onDraft,
  okConfirmProps,
  enterForSubmit = true,
  successModal = false,
  hgbLogo = false,
  errorModal = false,
  width = 440,
  reject = false,
  preventSubmitTime = 0,
  ...props
}) => {
  const timer = React.useRef<TimeoutType>(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  const submitRef = React.useRef<HTMLButtonElement>(null);
  const [preventSubmit, setPreventSubmit] = React.useState(false);

  React.useEffect(() => {
    if (preventSubmitTime === 0) return;
    if (preventSubmit) {
      timer.current = setTimeout(() => {
        setPreventSubmit(false);
      }, preventSubmitTime);
    }
    return () => {
      if (timer.current && !preventSubmit) clearTimeout(timer.current);
    };
  }, [preventSubmit, preventSubmitTime]);

  React.useEffect(() => {
    const preventFunction = (e: any) => {
      if (e.key === 'Enter' && !enterForSubmit) {
        e.preventDefault();
      }
    };
    document.addEventListener('keypress', preventFunction);
    return () => document.removeEventListener('keypress', preventFunction);
  }, [enterForSubmit]);

  const formSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (preventSubmit && preventSubmitTime !== 0) {
      return;
    }
    if (onDraft) {
      onDraft();
      setPreventSubmit(true);
    }
    if (onOk) {
      onOk();
      setPreventSubmit(true);
    }
    if (formProps?.onSubmit) {
      setTimeout(() => {
        formProps?.onSubmit?.(e);
        setPreventSubmit(true);
      }, 50);
    }
  };

  return (
    <Modal
      width={width}
      centered
      footer={false}
      closable={true}
      bodyStyle={{
        margin: '-20px -24px',
        width: 'unset',
      }}
      maskClosable={false}
      {...props}
    >
      <form
        ref={formRef}
        className="tw-relative tw-flex tw-flex-col tw-gap-24 tw-overflow-hidden tw-py-32"
        style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'hidden' }}
        {...{
          ...formProps,
          onSubmit: formSubmitHandler,
        }}
      >
        {(onBack || !!title) && (
          <div className="tw-flex tw-flex-col tw-items-center tw-gap-16 tw-px-32">
            {hgbLogo && (
              <img
                src="/background/hgb-logo.png"
                className="tw-mx-auto tw-mb-12 tw-h-64"
                alt=""
              ></img>
            )}
            {successModal && (
              <div className="tw-flex-auto tw-text-center">
                <HgbCheckedModalOutlinedIcon />
              </div>
            )}
            {errorModal && (
              <div className="tw-flex-auto tw-text-center">
                <HgbErrorModalOutlinedIcon />
              </div>
            )}
            <div className="tw-flex tw-justify-center tw-self-stretch">
              {onBack && (
                <button
                  type="button"
                  className="tw-mt-12 tw-h-24 tw-w-24 tw-cursor-pointer tw-justify-self-start"
                  onClick={onBack}
                >
                  <HgbExpandMoreOutlinedIcon
                    size="24"
                    className="tw-rotate-90"
                  />
                </button>
              )}
              {title && (
                <h1 className="tw-flex-auto tw-text-center tw-font-bold tw-leading-[48px]">
                  {title}
                </h1>
              )}
            </div>
          </div>
        )}

        <div className="tw-mx-4 tw-gap-16 tw-overflow-y-scroll tw-px-28">
          {children}
        </div>
        {!isEmpty(draftText) && (
          <div className="tw-flex tw-justify-start tw-gap-8 tw-px-32 tablet:tw-justify-center pc:tw-justify-between">
            <HgbButton
              type="primary"
              ghost
              htmlType="button"
              onClick={(e: any) => props?.onCancel?.(e)}
            >
              {cancelText}
            </HgbButton>
            <div className="tw-flex tw-flex-col tw-flex-wrap tw-gap-8 tablet:tw-flex-row pc:tw-gap-24">
              <HgbButton
                onClick={() => {
                  formRef.current?.setAttribute('isdraft', '1');
                }}
                type="primary"
                ghost
                htmlType={'submit'}
                disabled={disableDraft}
              >
                {draftText}
              </HgbButton>
              <HgbButton
                onClick={() => {
                  formRef.current?.setAttribute('isdraft', '0');
                }}
                disabled={disableOk}
                htmlType={'submit'}
                type="primary"
              >
                {okText}
              </HgbButton>
            </div>
          </div>
        )}
        {(!isEmpty(cancelText) || !isEmpty(okText)) && isEmpty(draftText) && (
          <div
            className={cn(
              'tw-mx-4 tw-grid tw-justify-center tw-gap-24 tw-pl-28 tw-pr-36',
              {
                'tw-grid-cols-[repeat(2,minmax(140px,auto))]':
                  !isEmpty(cancelText) && !isEmpty(okText),
              },
            )}
          >
            {!isEmpty(cancelText) && (
              <HgbButton
                htmlType="button"
                onClick={(e: any) => props?.onCancel?.(e)}
                className={hgbLogo ? 'tw-bg-primary-6 tw-text-white' : ''}
              >
                <>{cancelText}</>
              </HgbButton>
            )}
            {!isEmpty(okText) &&
              (okConfirmProps && !disableOk ? (
                <div className="tw-relative">
                  <Popconfirm
                    {...okConfirmProps}
                    onConfirm={submitRef.current?.click}
                  >
                    <HgbButton
                      ref={submitRef}
                      disabled={disableOk}
                      htmlType={'submit'}
                      type="primary"
                    >
                      <>{okText}</>
                    </HgbButton>
                    <div className="tw-absolute tw-inset-0 tw-cursor-pointer" />
                  </Popconfirm>
                </div>
              ) : (
                <HgbButton
                  disabled={disableOk}
                  htmlType={'submit'}
                  type={'primary'}
                  danger={reject}
                >
                  <>{okText}</>
                </HgbButton>
              ))}
          </div>
        )}
      </form>
    </Modal>
  );
};
