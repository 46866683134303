import { HgbButton } from '@common/atoms/HgbButton';
import HgbModalForm from '@common/antd/HgbModalForm';
import { HgbModalFormBody } from '@common/antd/HgbModalFormBody';
import { HgbModalFormFooter } from '@common/antd/HgbModalFormFooter';
import { HgbModalTitle } from '@common/antd/HgbModalTitle';
import { HgbAntdInput } from '@common/forms';
import { API_URLS, QUERY_KEYS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { BooleanState } from '@hooks/useBooleanState';
import { debug } from '@utils/debug';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useHgbMutationPost } from 'services/common/mutation';
import * as yup from 'yup';

interface IAddSupplierProductModalProps {
  modalState: BooleanState;
}

type AddSupplierProductPayload = {
  inviteCode: string;
  serialNumber: string;
  productName: string;
  emissionIntensity: string;
  unit: string;
  gtinCode: string;
  upcCode: string;
  emissionIntensityUnit: string;
};

const defaultAddSupplierProductPayload: AddSupplierProductPayload = {
  inviteCode: '',
  serialNumber: '',
  productName: '',
  emissionIntensity: '',
  unit: '',
  gtinCode: '',
  upcCode: '',
  emissionIntensityUnit: '',
};

const AddSupplierProductModal: React.FC<
  IAddSupplierProductModalProps
> = ({ modalState }) => {
  const {
    text: {
      common: { button },
      D19,
      E0000,
      E0104,
    },
  } = React.useContext(LanguageContext)!;
  const queryClient = useQueryClient();

  const addSupplierProductSchema = yup.object({
    serialNumber: yup.string().required(E0000(D19.serialNumber)),
    productName: yup.string().required(E0000(D19.productName)),
    unit: yup.string().required(E0000(D19.modal.unit)),
    emissionIntensity: yup.string().required(E0000(D19.emissionsPerUnit)),
    gtinCode: yup
      .string()
      .test('is-valid-length', E0104(8, 13, 14), (value) => {
        if (!value) return true;
        const length = value.length;
        return length === 8 || length === 13 || length === 14;
      }),
    upcCode: yup.string().test('is-valid-length', E0104(8, 12), (value) => {
      if (!value) return true;
      const length = value.length;
      return length === 8 || length === 12;
    }),
  });

  const addSupplierProductMethods = useForm<AddSupplierProductPayload>({
    resolver: yupResolver(addSupplierProductSchema),
    values: defaultAddSupplierProductPayload,
  });

  const addSupplierProductMutate = useHgbMutationPost<
    unknown,
    AddSupplierProductPayload
  >(API_URLS.SUPPLIER_PRODUCT, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.D17_D19_H02_TABLE_LIST],
      });
      modalState.turnOff();
      addSupplierProductMethods.reset(defaultAddSupplierProductPayload);
    },
    onError: () => {},
  });

  const handleSubmit = addSupplierProductMethods.handleSubmit(
    (data, e) => {
      addSupplierProductMutate.mutate({
        ...data,
        emissionIntensityUnit: 'tCO2',
      });
    },
    (error) => {
      debug.error(error);
    },
  );

  return (
    <>
      <FormProvider {...addSupplierProductMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={
            <HgbModalTitle>
              {D19.modal.supplierProductRegistration}
            </HgbModalTitle>
          }
          open={modalState.value}
          onCancel={modalState.turnOff}
          footer={null}
          closable={true}
          maskClosable={false}
          afterClose={() =>
            addSupplierProductMethods.reset(defaultAddSupplierProductPayload)
          }
          destroyOnClose
        >
          <HgbModalForm onSubmit={handleSubmit}>
            <HgbModalFormBody>
              <HgbAntdInput
                {...addSupplierProductMethods.register('serialNumber')}
                maxLength={50}
                type={'K'}
                label={D19.serialNumber}
                required
              />
              <HgbAntdInput
                {...addSupplierProductMethods.register('productName')}
                maxLength={100}
                type={'K'}
                label={D19.productName}
                required
              />
              <HgbAntdInput
                {...addSupplierProductMethods.register('unit')}
                maxLength={15}
                type={'K'}
                label={D19.modal.unit}
                required
              />
              <HgbAntdInput
                {...addSupplierProductMethods.register('emissionIntensity')}
                label={D19.emissionsPerUnit}
                maxLength={10}
                type="positiveDecimal"
                required
                suffix="tCO2"
              />
              <HgbAntdInput
                {...addSupplierProductMethods.register('gtinCode')}
                label={D19.gtinCode}
                maxLength={14}
                type="positiveInteger"
              />
              <HgbAntdInput
                {...addSupplierProductMethods.register('upcCode')}
                label={D19.upcCode}
                maxLength={12}
                type="positiveInteger"
              />
            </HgbModalFormBody>
            <HgbModalFormFooter>
              <HgbButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
              >
                {button.cancel}
              </HgbButton>
              <HgbButton
                type="primary"
                className="tw-min-w-100"
                htmlType="submit"
              >
                {button.add}
              </HgbButton>
            </HgbModalFormFooter>
          </HgbModalForm>
        </Modal>
      </FormProvider>
    </>
  );
};

export default AddSupplierProductModal;
