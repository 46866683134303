import { HgbButton } from '@common/atoms/HgbButton';
import HgbUpload from '@common/antd/HgbUpload';
import { HgbSelect } from '@common/forms';
import { HgbTreeSelect } from '@common/forms/HgbTreeSelect/HgbTreeSelect';
import { API_URLS } from '@constants/API_URLS';
import { REACT_APP_UPLOAD_OCR_URL } from '@constants/consts';
import { routs_en } from '@constants/ROUT';
import { LanguageContext } from '@contexts/LanguageContext';
import { PortalContext } from '@contexts/PortalContext';
import { useConfirm } from '@hooks/useConfirm';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { hgbAxios } from '@utils/axios';
import { debug } from '@utils/debug';
import { isEmpty, isUndefined } from 'lodash';
import { FC, useContext, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  useBase,
  useFiscalYearMonth,
  useLastTenYearsListBox,
} from '../C01/utils';
import { MANUAL_NAMEDDESTS } from '../Support';
import UploadModal, { UploadModalProps } from './UploadModal';
import { HgbRadioGroup2 } from '@common/antd/HgbRadioGroup2';

export type C02Step1Request = {
  fileType: string;
  targetFiscalYear: string;
  targetYearMonth: string;
  baseId: string;
  scopeCode?: string;
  file?: File;
};

const C02: FC = () => {
  const {
    text: { C01, common },
    language,
  } = useContext(LanguageContext)!;
  const { ConfirmModal, hgbConfirm } = useConfirm();
  const navigate = useNavigate();
  const { addMessage } = useContext(PortalContext)!;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const step1Methods = useForm<C02Step1Request>({
    values: {
      baseId: '',
      file: undefined,
      fileType: '1',
      scopeCode: '',
      targetFiscalYear: '',
      targetYearMonth: '',
    },
  });

  const [uploadModalProps, setUploadModalProps] = useState<UploadModalProps>({
    step1: undefined,
  });

  const targetFiscalYearOptions = useLastTenYearsListBox().options;
  const targetYearMonthOptions = useFiscalYearMonth(
    step1Methods.watch().targetFiscalYear,
  ).options;

  const scopeOptions = useMemo(() => {
    if (step1Methods.watch().fileType === '1')
      return [
        { label: common.label.scopeNote1, value: '1' },
        { label: common.label.scopeNote2, value: '2' },
      ];
    return [{ label: common.label.offset, value: '4' }];
  }, [step1Methods.watch().fileType, language]);

  const baseOptions = useBase().options;

  const submitHandler: SubmitHandler<C02Step1Request> = async (data) => {
    const result = await hgbConfirm();
    if (!result) return;
    setLoading(true);
    const formData = new FormData();
    formData.append('baseId', data.baseId);
    formData.append('targetFiscalYear', data.targetFiscalYear);
    formData.append('targetYearMonth', data.targetYearMonth);
    formData.append('scopeCode', data.scopeCode ?? '');
    formData.append('file', data.file!);
    try {
      const response = await hgbAxios(undefined, REACT_APP_UPLOAD_OCR_URL).post(
        API_URLS.PDF_UPLOAD_API_URL,
        formData,
      );
      if (response) {
        setLoading(false);
        const { result } = response.data;
        setOpen(true);
        setUploadModalProps({
          step1: step1Methods.watch(),
          scope: {
            activityVolume: result?.activityVolume,
            activityVolumeUnit: result?.activityVolumeUnit,
          },
          offset: {
            offsetAmount: result?.offsetAmount,
            offsetCertificateDate: result?.offsetCertificateDate,
            offsetAmountUnit: result?.offsetAmountUnit,
          },
          step1Options: {
            targetFiscalYearOptions,
            targetYearMonthOptions,
            baseOptions,
            scopeOptions,
          },
        });
      }
    } catch (error: any) {
      addMessage('error', error?.response?.data?.message);
      setLoading(false);
    }
  };

  const submitErrorHandler: SubmitErrorHandler<C02Step1Request> = (err) => {
    debug.error('Error:', err);
  };

  const disableSubmit = useMemo(() => {
    const values = Object.values(step1Methods.watch());
    return !values.every((item) => !isEmpty(item) && !isUndefined(item));
  }, [step1Methods.watch()]);

  const fileTypeOptions = useMemo(
    () => [
      {
        label: C01.label.invoice,
        value: '1',
        infoLink: '/support/manual/' + MANUAL_NAMEDDESTS.about_upload_invoice,
      },
      {
        label: C01.label.certificate,
        value: '2',
        infoLink: '/support/manual/' + MANUAL_NAMEDDESTS.about_upload_offset,
      },
    ],
    [C01.label.invoice, C01.label.certificate],
  );

  return (
    <ManagementTemplate>
      <DataTableTemplate title={C01.label.uploadFile}>
        <FormProvider {...step1Methods}>
          <form
            onSubmit={step1Methods.handleSubmit(
              submitHandler,
              submitErrorHandler,
            )}
            className="tw-mx-auto tw-overflow-y-scroll tw-pt-24 pc:tw-w-600 large:tw-w-800"
          >
            <div className="tw-flex tw-flex-col tw-gap-24">
              <HgbRadioGroup2
                label={C01.label.fileType}
                name={'fileType'}
                options={fileTypeOptions}
                optionsClassName='tw-w-180'
              />
              <div className="tw-grid tw-grid-cols-2 tw-gap-24 pc:tw-grid-cols-4">
                <HgbSelect
                  className="tw-col-span-2 pc:tw-col-span-1"
                  label={C01.label.fiscalYear}
                  options={targetFiscalYearOptions}
                  autoInitValue
                  {...step1Methods.register('targetFiscalYear')}
                />
                <HgbSelect
                  className="tw-col-span-2 pc:tw-col-span-1"
                  label={C01.label.targetYearMonth}
                  options={targetYearMonthOptions}
                  autoInitValue
                  {...step1Methods.register('targetYearMonth')}
                />
                <HgbTreeSelect
                  className="tw-col-span-2 tw-flex tw-flex-col tw-justify-end"
                  label={C01.label.baseId}
                  treeData={baseOptions}
                  required
                  showSearch
                  {...step1Methods.register('baseId')}
                />
                <HgbSelect
                  className="tw-col-span-2"
                  label={C01.label.scopeCode}
                  options={scopeOptions}
                  disabled={step1Methods.watch().fileType === '2'}
                  autoInitValue
                  {...step1Methods.register('scopeCode')}
                />
              </div>
              <HgbUpload
                disabled={isEmpty(step1Methods.watch('scopeCode'))}
                name="file"
                size={5}
              />
              <div
                className={`tw-mt-24 tw-grid tw-grid-cols-1 tw-gap-24 tw-self-center pc:tw-grid-cols-2`}
              >
                <HgbButton
                  onClick={() => {
                    return navigate(routs_en['/data-entry'].link);
                  }}
                  type="primary"
                  ghost
                  htmlType="submit"
                >
                  {C01.label.returnToDataList}
                </HgbButton>
                <HgbButton
                  type="primary"
                  htmlType="submit"
                  disabled={disableSubmit}
                  loading={loading}
                  className="-tw-order-1 pc:tw-order-1"
                >
                  {C01.label.upload}
                </HgbButton>
              </div>
            </div>
          </form>
        </FormProvider>
        {open && (
          <UploadModal open={open} setOpen={setOpen} {...uploadModalProps} />
        )}
        <ConfirmModal
          title={C01.modal.uploadConfirmModalTitle}
          cancelText={common.button.cancel}
          okText={C01.modal.uploadConfirmModalOkButton}
        >
          <div className="tw-text-center">
            {C01.modal.uploadConfirmModalContent}
          </div>
        </ConfirmModal>
      </DataTableTemplate>
    </ManagementTemplate>
  );
};

export default C02;
