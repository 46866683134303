import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FlowInputDetail, InputInfo, OffsetFields, Step2Fields } from '../type';
import {
  OFFSET_DEFAULT,
  preValidateInputInfoCondition,
  preValidateIntensityCondition,
  STEP2_DEFAULT_VALUE,
} from '../utils';

export const useStep2 = (
  scopeCode: string,
  flowInputDetails: FlowInputDetail[],
) => {
  const {
    text: { C01, common, E0000, E0119, E0113, E0071, E0122, E0116 },
  } = useContext(LanguageContext)!;

  const validateByRange = (name: string) => {
    let validate: Partial<InputInfo> = {};
    flowInputDetails
      .map((flow) => flow.inputGroup)
      .filter((s) => s)
      .map((input) =>
        input?.forEach((rs) => {
          if (rs.fieldTarget === name) {
            validate = { ...rs };
          }
        }),
      );
    if (validate && validate.disabled === false) {
      if (validate.fieldTarget === 'greenEnergyCertificateVolume') {
        return yup
          .number()
          .required(E0000(C01.label.greenEnergyCertificateVolume))
          .min(0, E0000(C01.label.greenEnergyCertificateVolume))
          .typeError(E0000(C01.label.greenEnergyCertificateVolume))
          .lessThan(
            Number(step2Methods.getValues('activityVolume')) + 0.000000000001,
            E0113,
          )
          .test(
            'len',
            E0116,
            (val: any) => Number(val) <= (validate.constraints?.MAX_VALUE ?? 0),
          );
      }
      return yup
        .number()
        .min(
          validate.constraints?.MIN_VALUE ?? 0,
          validate.fieldTarget + common.message.isRequired,
        )
        .max(
          validate.constraints?.MAX_VALUE ?? 0,
          validate.fieldTarget + common.message.isRequired,
        )
        .required(E0000(C01.label.fuelUsagePerUse))
        .typeError(E0000(C01.label.fuelUsagePerUse));
    }
    return {};
  };

  const step2Schema = yup.object({
    activityName: yup.string().when('calculationTargetCode', {
      is: (value: string) => value && value !== 'ST126' && scopeCode === '3',
      then: (schema) => schema.required(E0000(C01.label.activityName)),
      otherwise: (schema) => schema,
    }),

    activityVolume: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('activityVolume', flowInputDetails),
      then: (schema) =>
        schema
          .required(E0000(C01.label.activityVolume))
          .min(0, E0000(C01.label.activityVolume))
          .typeError(E0000(C01.label.activityVolume)),

      otherwise: (schema) => schema,
    }),
    greenEnergyCertificateVolume: yup.number().when({
      is: () => !isEmpty(validateByRange('greenEnergyCertificateVolume')),
      then: (schema) =>
        validateByRange('greenEnergyCertificateVolume') as yup.NumberSchema<
          number | undefined,
          yup.AnyObject,
          undefined,
          ''
        >,
      otherwise: (schema) => schema,
    }),
    fillingVolume: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('fillingVolume', flowInputDetails),
      then: (schema) =>
        schema
          .required(E0000(C01.label.fillingVolume))
          .min(0, E0000(C01.label.fillingVolume))
          .typeError(E0000(C01.label.fillingVolume)),
      otherwise: (schema) => schema,
    }),
    recoveryVolume: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('recoveryVolume', flowInputDetails),
      then: (schema) =>
        schema
          .min(0, C01.message.recoveryVolumeFormat)
          .required(C01.message.recoveryVolume)
          .typeError(C01.message.recoveryVolumeFormat),
      otherwise: (schema) => schema,
    }),

    emissionVolume: yup.string().test('', '', (value, context) => {
      const { emissionIntensityLocation, greenHouseGasOptionSelected } =
        context.parent;

      if (
        emissionIntensityLocation === undefined &&
        !greenHouseGasOptionSelected
      ) {
        if (!value) {
          return context.createError({
            message: E0000(common.label.emissions),
            path: 'emissionVolume',
          });
        }

        if (value.length <= 0) {
          return context.createError({
            message: C01.message.emissionVolumeNegativeCreate,
            path: 'emissionVolume',
          });
        }
        if (value.includes('.') && value.split('.')[0].length > 10) {
          return context.createError({
            message: E0122(common.label.emissions),
            path: 'emissionVolume',
          });
        }
      }

      return true;
    }),

    co2Equivalents: yup.string().test('', '', (value, context) => {
      if (
        (value?.includes('.') && value?.split('.')[0].length > 10) ||
        (value && !value?.includes('.') && value?.length > 10)
      ) {
        return context.createError({
          message: E0122(C01.label.C02Equivalent),
          path: 'co2Equivalents',
        });
      }

      return true;
    }),

    emissionIntensity: yup.number().when({
      is: () =>
        preValidateIntensityCondition('emissionIntensity', flowInputDetails),
      then: (schema) =>
        schema
          .required(E0000(C01.label.emissionIntensity))
          .min(0, E0000(C01.label.emissionIntensity))
          .typeError(E0000(C01.label.emissionIntensity)),
      otherwise: (schema) => schema,
    }),
    rawActivityVolume: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('rawActivityVolume', flowInputDetails),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawActivityVolume))
          .min(0, E0000(C01.label.rawActivityVolume))
          .typeError(E0000(C01.label.rawActivityVolume)),
      otherwise: (schema) => schema,
    }),
    rawEmissionIntensity: yup.number().when({
      is: () =>
        preValidateIntensityCondition('rawEmissionIntensity', flowInputDetails),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawEmissionIntensity))
          .min(0, E0000(C01.label.rawEmissionIntensity))
          .typeError(E0000(C01.label.rawEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    rawTransportActivityVolume: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'rawTransportActivityVolume',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.rawTransportActivityVolume))
          .required(E0000(C01.label.rawTransportActivityVolume))
          .typeError(E0000(C01.label.rawTransportActivityVolume)),
      otherwise: (schema) => schema,
    }),
    rawTransportEmissionIntensity: yup.number().when({
      is: () =>
        preValidateIntensityCondition(
          'rawTransportEmissionIntensity',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .required(E0000(C01.label.rawTransportEmissionIntensity))
          .min(0, E0000(C01.label.rawTransportEmissionIntensity))
          .typeError(E0000(C01.label.rawTransportEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    capitalWasteActivityVolume: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'capitalWasteActivityVolume',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .required(E0000(C01.label.capitalWasteActivityVolume))
          .min(0, C01.message.capitalWasteActivityVolumeFormat)
          .typeError(C01.message.capitalWasteActivityVolumeFormat),
      otherwise: (schema) => schema,
    }),
    capitalWasteEmissionIntensity: yup.number().when({
      is: () =>
        preValidateIntensityCondition(
          'capitalWasteEmissionIntensity',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .required(E0000(C01.label.capitalWasteEmissionIntensity))
          .min(0, E0000(C01.label.capitalWasteEmissionIntensity))
          .typeError(E0000(C01.label.capitalWasteEmissionIntensity)),
      otherwise: (schema) => schema,
    }),
    transportCargoWeight: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('transportCargoWeight', flowInputDetails),
      then: (schema) =>
        schema
          .required(E0000(C01.label.transportCargoWeight))
          .min(0, E0000(C01.label.transportCargoWeight))
          .typeError(E0000(C01.label.transportCargoWeight)),
      otherwise: (schema) => schema,
    }),
    transportMaximumCargoWeight: yup.string().when({
      is: () =>
        preValidateInputInfoCondition(
          'transportMaximumCargoWeight',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .required(E0000(C01.label.transportMaximumCargoWeight))
          .min(0, E0000(C01.label.transportMaximumCargoWeight))
          .max(14, E0071(C01.label.transportMaximumCargoWeight))
          .typeError(E0000(C01.label.transportMaximumCargoWeight)),
      otherwise: (schema) => schema,
    }),
    transportDistance: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('transportDistance', flowInputDetails),
      then: (schema) =>
        schema
          .required(E0000(C01.label.transportDistance))
          .min(0, E0000(C01.label.transportDistance))
          .typeError(E0000(C01.label.transportDistance)),
      otherwise: (schema) => schema,
    }),
    numberOfPeople: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('numberOfPeople', flowInputDetails),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.numberOfPeople))
          .required(E0000(C01.label.numberOfPeople))
          .typeError(E0000(C01.label.numberOfPeople)),
      otherwise: (schema) => schema,
    }),
    numberOfNight: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('numberOfNight', flowInputDetails),
      then: (schema) =>
        schema
          .required(E0000(C01.label.numberOfNight))
          .min(0, E0000(C01.label.numberOfNight))
          .typeError(E0000(C01.label.numberOfNight)),
      otherwise: (schema) => schema,
    }),
    numberOfDay: yup.number().when({
      is: () => preValidateInputInfoCondition('numberOfDay', flowInputDetails),
      then: (schema) =>
        schema
          .required(E0000(C01.label.numberOfDay))
          .min(0, E0000(C01.label.numberOfDay))
          .typeError(E0000(C01.label.numberOfDay)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsagePer: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'expectedLifetimeUsagePer',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsagePer))
          .required(E0000(C01.label.expectedLifetimeUsagePer))
          .typeError(E0000(C01.label.expectedLifetimeUsagePer)),
      otherwise: (schema) => schema,
    }),
    saleCount: yup.number().when({
      is: () => preValidateInputInfoCondition('saleCount', flowInputDetails),
      then: (schema) =>
        schema
          .required(E0000(C01.label.saleCount))
          .min(0, E0000(C01.label.saleCount))
          .typeError(E0000(C01.label.saleCount)),
      otherwise: (schema) => schema,
    }),
    perLessonUsage: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('perLessonUsage', flowInputDetails),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.perLessonUsage))
          .required(E0000(C01.label.perLessonUsage))
          .typeError(E0000(C01.label.perLessonUsage)),
      otherwise: (schema) => schema,
    }),
    emissionLifetimeUse: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('emissionLifetimeUse', flowInputDetails),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.emissionLifetimeUse))
          .required(E0000(C01.label.emissionLifetimeUse))
          .typeError(E0000(C01.label.emissionLifetimeUse)),
      otherwise: (schema) => schema,
    }),
    distribution: yup.number().when({
      is: () => !isEmpty(validateByRange('distribution')),
      then: () =>
        validateByRange('distribution') as yup.NumberSchema<
          number | undefined,
          yup.AnyObject,
          undefined,
          ''
        >,
      otherwise: (schema) => schema,
    }),
    fuelEconomyBasic: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('fuelEconomyBasic', flowInputDetails),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.fuelEconomy))
          .required(E0000(C01.label.fuelEconomy))
          .typeError(E0000(C01.label.fuelEconomy)),
      otherwise: (schema) => schema,
    }),
    transportLoadRate: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('transportLoadRate', flowInputDetails),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.transportLoadRate))
          .required(E0000(C01.label.transportLoadRate))
          .typeError(E0000(C01.label.transportLoadRate)),
      otherwise: (schema) => schema,
    }),
    businessDaysYear: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('businessDaysYear', flowInputDetails),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.businessDaysYear))
          .required(E0000(C01.label.businessDaysYear))
          .typeError(E0000(C01.label.businessDaysYear)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsage: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'expectedLifetimeUsage',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsage))
          .required(E0000(C01.label.expectedLifetimeUsage))
          .typeError(E0000(C01.label.expectedLifetimeUsage)),
      otherwise: (schema) => schema,
    }),
    expectedLifetimeUsagePercentage: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'expectedLifetimeUsagePercentage',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.expectedLifetimeUsagePercentage))
          .required(E0000(C01.label.expectedLifetimeUsagePercentage))
          .typeError(E0000(C01.label.expectedLifetimeUsagePercentage)),
      otherwise: (schema) => schema,
    }),
    useEmissionCoefficient: yup.number().when({
      is: () => !isEmpty(validateByRange('useEmissionCoefficient')),
      then: () =>
        validateByRange('useEmissionCoefficient') as yup.NumberSchema<
          number | undefined,
          yup.AnyObject,
          undefined,
          ''
        >,
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume1: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume1',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume1))
          .required(E0000(C01.label.unclearActivityVolume1))
          .typeError(E0000(C01.label.unclearActivityVolume1)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume2: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume2',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume2))
          .required(E0000(C01.label.unclearActivityVolume2))
          .typeError(E0000(C01.label.unclearActivityVolume2)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume3: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume3',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume3))
          .required(E0000(C01.label.unclearActivityVolume3))
          .typeError(E0000(C01.label.unclearActivityVolume3)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume4: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume4',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume4))
          .required(E0000(C01.label.unclearActivityVolume4))
          .typeError(E0000(C01.label.unclearActivityVolume4)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume5: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume5',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume5))
          .required(E0000(C01.label.unclearActivityVolume5))
          .typeError(E0000(C01.label.unclearActivityVolume5)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume6: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume6',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume6))
          .required(E0000(C01.label.unclearActivityVolume6))
          .typeError(E0000(C01.label.unclearActivityVolume6)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume7: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume7',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, C01.message.unclearActivityVolume7Format)
          .required(C01.message.unclearActivityVolume7)
          .typeError(C01.message.unclearActivityVolume7Format),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume8: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume8',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume8))
          .required(E0000(C01.label.unclearActivityVolume8))
          .typeError(E0000(C01.label.unclearActivityVolume8)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume9: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume9',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, C01.message.unclearActivityVolume9Format)
          .required(C01.message.unclearActivityVolume9)
          .typeError(C01.message.unclearActivityVolume9Format),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume10: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume10',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume10))
          .required(E0000(C01.label.unclearActivityVolume10))
          .typeError(E0000(C01.label.unclearActivityVolume10)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume11: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume11',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume11))
          .required(E0000(C01.label.unclearActivityVolume11))
          .typeError(E0000(C01.label.unclearActivityVolume11)),
      otherwise: (schema) => schema,
    }),
    unclearActivityVolume12: yup.number().when({
      is: () =>
        preValidateInputInfoCondition(
          'unclearActivityVolume12',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearActivityVolume12))
          .required(E0000(C01.label.unclearActivityVolume12))
          .typeError(E0000(C01.label.unclearActivityVolume12)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity1: yup.number().when({
      is: () =>
        preValidateIntensityCondition(
          'unclearEmissionIntensity1',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity1))
          .required(E0000(C01.label.unclearEmissionIntensity1))
          .typeError(E0000(C01.label.unclearEmissionIntensity1)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity2: yup.number().when({
      is: () =>
        preValidateIntensityCondition(
          'unclearEmissionIntensity2',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity2))
          .required(E0000(C01.label.unclearEmissionIntensity2))
          .typeError(E0000(C01.label.unclearEmissionIntensity2)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity3: yup.number().when({
      is: () =>
        preValidateIntensityCondition(
          'unclearEmissionIntensity3',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity3))
          .required(E0000(C01.label.unclearEmissionIntensity3))
          .typeError(E0000(C01.label.unclearEmissionIntensity3)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity4: yup.number().when({
      is: () =>
        preValidateIntensityCondition(
          'unclearEmissionIntensity4',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity4))
          .required(E0000(C01.label.unclearEmissionIntensity4))
          .typeError(E0000(C01.label.unclearEmissionIntensity4)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity5: yup.number().when({
      is: () =>
        preValidateIntensityCondition(
          'unclearEmissionIntensity5',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity5))
          .required(E0000(C01.label.unclearEmissionIntensity5))
          .typeError(E0000(C01.label.unclearEmissionIntensity5)),
      otherwise: (schema) => schema,
    }),
    unclearEmissionIntensity6: yup.number().when({
      is: () =>
        preValidateIntensityCondition(
          'unclearEmissionIntensity6',
          flowInputDetails,
        ),
      then: (schema) =>
        schema
          .min(0, E0000(C01.label.unclearEmissionIntensity6))
          .required(E0000(C01.label.unclearEmissionIntensity6))
          .typeError(E0000(C01.label.unclearEmissionIntensity6)),
      otherwise: (schema) => schema,
    }),
    manualIdeaInput: yup.number().when({
      is: () =>
        preValidateInputInfoCondition('manualIdeaInput', flowInputDetails),
      then: (schema) =>
        schema
          .min(0, C01.message.manualIdeaInput)
          .required(C01.message.manualIdeaInput)
          .typeError(C01.message.manualIdeaInput),
      otherwise: (schema) => schema,
    }),
  });

  const step2Methods: any = useForm<Step2Fields>({
    values: STEP2_DEFAULT_VALUE,
    resolver: yupResolver(step2Schema),
  });

  const offsetSchema = yup.object({
    offsetCertificateDate: yup
      .string()
      .required(E0000(C01.label.offsetCertificateDate))
      .typeError(E0000(C01.label.offsetCertificateDate)),
    offsetAmount: yup
      .number()
      .min(0, E0000(C01.label.offsetAmount))
      .required(E0000(C01.label.offsetAmount))
      .typeError(E0000(C01.label.offsetAmount)),
  });

  const offsetMethods = useForm<OffsetFields>({
    values: OFFSET_DEFAULT,
    resolver: yupResolver(offsetSchema),
  });

  return {
    offsetMethods,
    offsetSchema,
    step2Methods,
    step2Schema,
  };
};
