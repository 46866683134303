import { isMobile } from '@hooks/useMobile';
import { cn } from '@utils/cn';
import { Tooltip, TooltipProps } from 'antd';
import * as React from 'react';
import { v4 } from 'uuid';

export type HgbCellTooltipProps = {
  value: string;
} & TooltipProps;
export const HgbCellTooltipEmissionVolume: React.FC<HgbCellTooltipProps> = ({
  value,
  children,
  ...props
}) => {
  const formatChildren = React.useMemo(() => {
    if (value && value.includes('.')) {
      if (value.split('.')[1].length > 3) {
        return (
          value.split('.')[0] + '.' + value.split('.')[1].slice(0, 3) + '...'
        );
      }
    }
    return children;
  }, [value]);

  return (
    <Tooltip
      id={v4()}
      placement="topLeft"
      trigger="hover"
      color="#fff"
      title={children}
      overlayInnerStyle={{
        color: '#3F494F',
      }}
      {...props}
    >
      <div
        className={cn(
          `tw-cursor-pointer tw-overflow-hidden tw-break-all tw-text-inherit`,
        )}
      >
        {formatChildren}
      </div>
    </Tooltip>
  );
};
