import { HgbButton } from '@common/atoms/HgbButton';
import { HgbFieldError } from '@common/contents/HgbFieldError';
import {
  HgbAntdInput,
  HgbSelect,
  parseToHgbSelectOptions,
} from '@common/forms';
import { HgbCheckCircleOutlineIcon } from '@common/icons/outlined';
import { API_URLS } from '@constants/API_URLS';
import { routs_en } from '@constants/ROUT';
import { areaOptions } from '@constants/area';
import { STATUS_CODE } from '@constants/consts';
import { EMAIL_REGEX } from '@constants/regex';
import { LanguageContext } from '@contexts/LanguageContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthenTemplate } from '@layouts/templates';
import { cn } from '@utils/cn';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { debounce } from 'lodash';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useHgbMutationPost } from 'services/common/mutation';
import { useHgbQuery } from 'services/common/query';
import {
  BankNameListBoxRequest,
  BankNameListBoxResponse,
  REGISTER_DEFAULT_VALUE,
  RegisterRequest,
} from 'services/types/A06';
import * as yup from 'yup';

const A06: FC = () => {
  const {
    text: { A06, common, E0000 },
  } = useContext(LanguageContext)!;

  const removeBankCodeQuery = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('bankCode');
    navigate(url);
  };

  const navigate = useNavigate();

  const [step, setStep] = useState<number>(1);
  const [verifiedBankName, setVerifiedBankName] = useState(false);

  const schema = yup.object({
    bankCode: yup.string().when({
      is: () => step === 1,
      then: (schema: any) => schema.required(E0000(A06.label.bankName)),
    }),
    bankArea: yup.string().when({
      is: () => step === 1 && !verifiedBankName,
      then: (schema) => schema.required(E0000(A06.label.bankArea)),
    }),
    email: yup.string().when({
      is: () => step === 2,
      then: (schema) =>
        schema
          .required(E0000(A06.label.email))
          .matches(EMAIL_REGEX, A06.message.emailFormat),
    }),
    emailConfirm: yup.string().when({
      is: () => step === 2,
      then: (schema) => {
        if (
          registerMethods.watch('email') ===
          registerMethods.watch('emailConfirm')
        ) {
          return schema;
        }

        return schema
          .required(E0000(A06.label.emailConfirm))
          .test(
            'len',
            A06.message.emailConfirmFormat,
            (value) => value.length > 300,
          );
      },
    }),
    references: yup.array().test('', A06.label.select1Option, (value) => {
      return value?.length !== 0 || step !== 2;
    }),
    fromBankDescription: yup.string().when({
      is: () => step === 2,
      then: (schema) =>
        schema.test('', E0000(A06.label.staffName), (value, schema) => {
          if (
            schema.from?.[0]?.value?.references?.some(
              (item: any) => item.value === 'our_bank',
            )
          ) {
            return (value || '').trim().length > 0;
          }
          return true;
        }),
    }),
  });

  const registerMethods = useForm<RegisterRequest>({
    values: REGISTER_DEFAULT_VALUE,
    resolver: yupResolver(schema),
  });

  const bankNameListboxQuery = useHgbQuery<
    BankNameListBoxResponse,
    BankNameListBoxRequest
  >(
    API_URLS.BANK_NAME_LISTBOX,
    { bankArea: registerMethods.watch('bankArea') },
    {
      enabled: !!registerMethods.watch('bankArea'),
      queryKey: [API_URLS.BANK_NAME_LISTBOX, registerMethods.watch('bankArea')],
    },
  );

  const [shouldFetchBankName, setShouldFetchBankName] = useState(false);
  useHgbQuery<string, { bankCode: string }>(
    API_URLS.BANK_NAME_API_URL,
    {
      bankCode: registerMethods.watch('bankCode'),
    },
    {
      onSuccess: (data) => {
        if (data?.result) {
          if (data.statusCode === STATUS_CODE.success) {
            registerMethods.setValue('bankName', data.result);
            setStep(2);
            setVerifiedBankName(true);
          }
        } else {
          removeBankCodeQuery();
          registerMethods.setValue('bankCode', '');
        }
      },
      enabled: shouldFetchBankName,
      queryKey: [API_URLS.BANK_NAME_API_URL, registerMethods.watch('bankCode')],
    },
  );

  useEffect(() => {
    const url = new URL(window.location.href);
    const bankCode = url.searchParams.get('bankCode') || '';
    const inviteCode = url.searchParams.get('InviteCode') || '';
    if (bankCode.length !== 4) {
      return removeBankCodeQuery();
    } else {
      registerMethods.setValue('bankCode', bankCode);
      setShouldFetchBankName(true);
    }
    registerMethods.setValue('inviteCode', inviteCode);
  }, []);

  const [hasIntroducer, setHasIntroducer] = useState(false);
  const introducingCompanyName = useHgbQuery<string, { inviteCode: string }>(
    API_URLS.INTRODUCER_NAME,
    {
      inviteCode: registerMethods.watch('inviteCode'),
    },
    {
      enabled: !!registerMethods.watch('inviteCode'),
      queryKey: [API_URLS.INTRODUCER_NAME, registerMethods.watch('inviteCode')],
      onSuccess(data) {
        if (data.statusCode === STATUS_CODE.success) {
          registerMethods.setValue('references', [
            { label: A06.label.introCompany, value: 'intro_company', more: '' },
          ]);
          setHasIntroducer(true);
        } else {
          return navigate(routs_en['/register/fail'].link);
        }
      },
    },
  );

  const registerMutation = useHgbMutationPost<
    undefined,
    Omit<RegisterRequest, 'fromBankDescription' | 'fromOthersDescription'>
  >(API_URLS.TEMPORARY_REGISTER_API_URL, {
    onSuccess: (data) => {
      if (data.statusCode === 10011) {
        registerMethods.reset({ ...REGISTER_DEFAULT_VALUE });
        setStep(4);
      }
    },
  });

  const onSubmit = useCallback(debounce(() => {
    switch (step) {
      case 1:
        registerMethods.setValue(
          'bankName',
          bankNameListboxQuery.data?.result?.find(
            (s) => s.value === registerMethods.watch('bankCode'),
          )?.name ?? '',
        );
        setStep(2);
        break;
      case 2:
        setStep(3);
        break;
      default:
        const { fromBankDescription, fromOthersDescription, ...payload } =
          registerMethods.watch();
        const others = payload.references.find(
          (item) => item.value === 'others',
        );
        const our_bank = payload.references.find(
          (item) => item.value === 'our_bank',
        );
        if (others) {
          others.more = registerMethods.watch('fromOthersDescription') || '';
        }
        if (our_bank) {
          our_bank.more = registerMethods.watch('fromBankDescription') || '';
        }
        registerMutation.mutate(payload);
        break;
    }
  }, 500), [JSON.stringify(bankNameListboxQuery.data?.result), step]);

  const options = [
    { label: A06.label.ourBank, value: 'our_bank' },
    { label: A06.label.otherCompany, value: 'other_company' },
    { label: A06.label.introCompany, value: 'intro_company' },
    { label: A06.label.homepage, value: 'homepage' },
    { label: A06.label.cm, value: 'cm' },
    { label: A06.label.seminars, value: 'seminars' },
    { label: A06.label.newspaper, value: 'newspaper' },
    { label: A06.label.sns, value: 'sns' },
    { label: A06.label.mail, value: 'mail' },
    { label: A06.label.others, value: 'others' },
  ];

  const onChangeCheckbox = (checkedValue: CheckboxValueType[]) => {
    const references = checkedValue.map((item) => ({
      ...options.find((o) => o.value === item)!,
      more: '',
    }));
    registerMethods.setValue('references', references);
  };

  const isOurBank = registerMethods
    .watch('references')
    .some((item) => item.value === 'our_bank');
  const isOthers = registerMethods
    .watch('references')
    .some((item) => item.value === 'others');

  const Step1Footer = () => {
    return (
      <HgbButton
        loading={registerMutation.isLoading}
        htmlType="submit"
        type="primary"
        className="tw-min-w-80 tw-self-center"
      >
        {common.button.next}
      </HgbButton>
    );
  };

  const Step2Footer = () => {
    const url = new URL(window.location.href);
    return (
      <div className="tw-flex tw-justify-center tw-gap-16">
        {!url.searchParams.has('bankCode') ? (
          <HgbButton
            htmlType="button"
            onClick={() => {
              setStep(step - 1);
            }}
            type="primary"
            ghost
            className="tw-min-w-80"
          >
            {common.button.return}
          </HgbButton>
        ) : null}

        <HgbButton
          loading={registerMutation.isLoading}
          htmlType="submit"
          type="primary"
          className="tw-min-w-80"
        >
          {common.button.next}
        </HgbButton>
      </div>
    );
  };
  const Step3Footer = () => {
    return (
      <div className="tw-flex tw-justify-center tw-gap-16">
        <HgbButton
          htmlType="button"
          onClick={() => {
            setStep(step - 1);
          }}
          type="primary"
          ghost
          className={cn('tw-min-w-80')}
        >
          {common.button.return}
        </HgbButton>
        <HgbButton
          loading={registerMutation.isLoading}
          htmlType="submit"
          type="primary"
          className="tw-min-w-80"
        >
          {A06.pageTitle}
        </HgbButton>
      </div>
    );
  };

  const Title = () => {
    if (step === 3) return <>{A06.pageTitle2}</>;
    if (step === 4)
      return (
        <HgbCheckCircleOutlineIcon className="tw-h-56 tw-w-56 tw-text-[#81B646]" />
      );
    return <>{A06.pageTitle}</>;
  };

  return (
    <AuthenTemplate
      title={<Title />}
      className="[&_.contain]:tw-flex [&_.contain]:tw-max-h-[calc(100vh_-_64px)] [&_.contain]:tw-flex-col"
    >
      <FormProvider {...registerMethods}>
        <form
          onSubmit={registerMethods.handleSubmit(onSubmit)}
          className="-tw-mx-12 tw-flex tw-flex-col tw-gap-32 tw-overflow-hidden"
        >
          {step === 1 && (
            <div className="tw-flex tw-flex-col tw-gap-24 tw-overflow-auto tw-px-12">
              <div className="tw-rounded-16 tw-bg-[#FFF4C9] tw-p-12">
                <h4 className="tw-mb-[10px] tw-font-bold">
                  {A06.label.L1NoticeTitle}
                </h4>
                <div className="tw-pl-4">
                  <p>{A06.label.L1NoticeContent1}</p>
                  <p>{A06.label.L1NoticeContent2}</p>
                </div>
              </div>
              <HgbSelect
                {...registerMethods.register('bankArea')}
                label={A06.label.bankArea}
                options={areaOptions}
                placeholder={A06.placeholder.bankArea}
                required
              />
              <HgbSelect
                {...registerMethods.register('bankCode')}
                label={A06.label.bankName}
                options={parseToHgbSelectOptions(
                  bankNameListboxQuery.data?.result,
                )}
                required
                placeholder={A06.placeholder.bankName}
                disabled={!registerMethods.watch('bankArea')}
              />
            </div>
          )}

          {step === 2 && (
            <div className="tw-flex tw-flex-col tw-gap-24 tw-overflow-auto tw-px-12">
              <div className="tw-flex tw-justify-between tw-gap-8">
                <div>
                  <h4 className="tw-font-bold">{A06.label.bankName}</h4>
                  <h4>{registerMethods.watch('bankName')}</h4>
                </div>
                {hasIntroducer && (
                  <div>
                    <h4 className="tw-font-bold">
                      {A06.label.introducingCompanyName}
                    </h4>
                    <h4>{introducingCompanyName?.data?.result}</h4>
                  </div>
                )}
              </div>

              <HgbAntdInput
                {...registerMethods.register('email')}
                label={A06.label.email}
                placeholder={A06.placeholder.email}
                type="H"
                maxLength={100}
                required
              />
              <HgbAntdInput
                {...registerMethods.register('emailConfirm')}
                label={A06.label.emailConfirm}
                placeholder={A06.placeholder.emailConfirm}
                type="H"
                maxLength={100}
                required
              />
              <div className="tw-flex tw-flex-col tw-gap-16">
                <div className="tw-flex tw-flex-col">
                  <label className="tw-text-paragraph tw-font-bold">
                    {A06.label.reference}{' '}
                    <span className="tw-text-paragraph tw-font-bold tw-text-error-7">
                      *
                    </span>
                  </label>
                  {registerMethods.formState.errors.references?.message ? (
                    <HgbFieldError>
                      {registerMethods.formState.errors.references?.message}
                    </HgbFieldError>
                  ) : null}
                </div>
                <Checkbox.Group
                  onChange={onChangeCheckbox}
                  className="tw-flex tw-flex-col tw-flex-wrap tw-gap-8"
                  value={registerMethods
                    .watch('references')
                    .map((item) => item.value)}
                >
                  <Checkbox value={options[0].value}>
                    {options[0].label}
                  </Checkbox>
                  {isOurBank ? (
                    <HgbAntdInput
                      {...registerMethods.register('fromBankDescription')}
                      required
                      label={A06.label.fromBankDescription}
                      className="-tw-mt-8"
                    />
                  ) : null}
                  {options.slice(1).map((item) => {
                    return (
                      <Checkbox
                        value={item.value}
                        key={item.value}
                        className={cn({
                          'tw-pointer-events-none':
                            item.value === 'intro_company' && hasIntroducer,
                        })}
                      >
                        {item.label}
                      </Checkbox>
                    );
                  })}
                  {isOthers ? (
                    <HgbAntdInput
                      label={A06.label.fromOthersDescription}
                      type="area"
                      {...registerMethods.register('fromOthersDescription')}
                      className="-tw-mt-8"
                    />
                  ) : null}
                </Checkbox.Group>
              </div>

              <span className="tw-text-[12px] tw-leading-[18px]">
                {A06.label.L2Notice}
              </span>
            </div>
          )}

          {step === 3 && (
            <div className="tw-flex tw-flex-col tw-gap-24 tw-overflow-auto tw-px-12">
              <h4>{A06.label.L3Notice}</h4>

              <div className="tw-flex tw-justify-between tw-gap-8">
                <div>
                  <h4 className="tw-font-bold">{A06.label.bankName}</h4>
                  <h4>{registerMethods.watch('bankName')}</h4>
                </div>
                {hasIntroducer && (
                  <div>
                    <h4 className="tw-font-bold">
                      {A06.label.introducingCompanyName}
                    </h4>
                    <h4>{introducingCompanyName?.data?.result}</h4>
                  </div>
                )}
              </div>

              <div className="tw-flex tw-flex-col tw-gap-8">
                <h4 className="tw-font-bold">{A06.label.email}</h4>
                <h4 className="tw-break-all">
                  {registerMethods.watch('email')}
                </h4>
              </div>

              <h4 className="tw-flex tw-flex-col">
                <span>{A06.label.step3Notice1}</span>
                <span>{A06.label.step3Notice2}</span>
                <span>{A06.label.step3Notice3}</span>
              </h4>
            </div>
          )}

          {step === 4 && (
            <div className="tw-flex tw-flex-col tw-gap-24 tw-overflow-auto tw-px-12">
              <p className="tw-text-center tw-text-h3 tw-font-bold">
                {A06.label.success}
              </p>
              <p className="tw-text-center tw-text-[13px]">
                {A06.label.L4Notice}
              </p>
            </div>
          )}

          {step === 1 ? <Step1Footer /> : null}
          {step === 2 ? <Step2Footer /> : null}
          {step === 3 ? <Step3Footer /> : null}
        </form>
      </FormProvider>
    </AuthenTemplate>
  );
};

export default A06;
