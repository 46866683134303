import { HgbSpin } from '@common/antd/HgbSpin';
import { Role } from '@constants/consts';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface RoleBasedGuardProps {
  roles: Role[];
  children: ReactNode;
}

export function RoleBasedGuard({ roles, children }: RoleBasedGuardProps) {
  const { routs } = useContext(LanguageContext)!;
  const { user } = useContext(AuthContext)!;
  const [isReady, setIsReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      setIsReady(true);
    }, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (!roles.includes(user.role)) {
      navigate(routs['/access-denied'].link);
    }
  }, [[...roles], user.role]);
  
  return <>{isReady ? children : <HgbSpin placement="middle" />}</>;
}
