import { HgbModal, HgbModalProps } from '@common/antd/HgbModal';
import { API_URLS } from '@constants/API_URLS';
import { LanguageContext } from '@contexts/LanguageContext';
import * as React from 'react';
import { useHgbMutationDelete } from 'services/common/mutation';
import { DeleteUserRequest } from 'services/types/user';

export interface DeleteUserModalProps extends HgbModalProps {
  id: string;
  onSubmitOk?: () => void;
  content?: string;
}

export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  onSubmitOk,
  content,
  id,
  ...props
}) => {
  const {
    text: { D04, common },
  } = React.useContext(LanguageContext)!;

  const { mutate: deleteUserMutate } = useHgbMutationDelete<
    unknown,
    DeleteUserRequest
  >(API_URLS.USER_API_URL, { onSuccess: onSubmitOk });

  return (
    <HgbModal
      cancelText={common.button.cancel}
      okText={common.button.delete}
      title={D04.modal.userDeleteTitle}
      formProps={{
        onSubmit: () => deleteUserMutate(id),
      }}
      {...props}
    >
      <p>{content}</p>
    </HgbModal>
  );
};
