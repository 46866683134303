import { HgbBulletItem } from '@common/contents';
import { HIGO_QUATRICS_URL } from '@constants/consts';
import { createMessage, createNode } from '@utils/text';
import { ReactNode } from 'react';

export const en = {
  common: {
    button: {
      login: 'Login',
      forgotPassword: 'If you forget your password',
      reissue: 'Reissue',
      backToLogin: 'Return to login screen',
      gotoDashboard: 'To Homepage',
      changePassword: 'Change password',
      cancel: 'Cancel',
      save: 'Save',
      register: 'New Signup',
      delete: 'Delete',
      update: 'Update',
      add: 'Register',
      participate: 'Participate',
      remand: 'Remand',
      change: 'Change',
      changeAndResend: 'Edit & Resend',
      next: 'Next',
      return: 'Back',
      duplicate: 'Duplicate',
      now: '',
      preview: 'Preview',
      draft: 'Draft',
      active: 'Active',
      delivery: 'Delivery',
      close: 'Close',
      ok: 'Ok',
      confirm: 'OK',
      newMessage: 'New Message',
      enterPayment: 'Enter payment information',
      passedPayment: 'Make this application',
      backToA06: 'Go to provisional application',
      enterpriseOutput: 'Corporate information list output',
      approve: 'Approve',
      denied: 'Reject',
      export: 'Export',
      enterpriseAdminCard:
        '+New registration/change of credit card information',
      respond: 'Respond',
      suspend: 'Suspend',
      cardPayment: 'Change credit card information',
    },
    message: {
      emailFormat: 'Invalid email format',
      partnerBanksEmpty: 'Partner banks do not exist.',
      choosePartnerBankAndCompany: 'Choose a partner bank and company.',
      choosePartnerBank: 'Choose a partner bank.',
      companyEmpty: 'This partner bank does not have a company.',
      chooseCompany: 'Please select a company.',
      noResult: 'No results.',
      noData: 'No data.',
      startDateEmpty: 'Choose start date.',
      endDateEmpty: 'Choose end date.',
      startMonthEmpty: 'Choose start month.',
      endMonthEmpty: 'Choose end month.',
      mes_access_deny: (
        <>
          Currently, access to this page is prohibited. <br /> If you are a site
          administrator, please check if the permission settings for purging are
          appropriate.
        </>
      ),
      mess_not_found: (
        <>
          Temporarily can't access. <br /> Please try again later.
        </>
      ),
      mess_not_found_bank_code: (
        <>
          To log in to the system, please log in from the website of the
          financial institution you have contracted with.
        </>
      ),
      tit_access_deny: 'No access',
      tit_not_found: 'Have a problem',
      tit_not_found_bank_code: (
        <span
          className="tw-flex tw-flex-wrap tw-justify-center"
          style={{ fontWeight: 'inherit' }}
        >
          <span style={{ fontWeight: 'inherit' }}>About logging in to</span>
          <span style={{ fontWeight: 'inherit' }}>Tansaku-kun</span>
        </span>
      ),
      isRequired: 'is required',
      fileExceedMb: 'File size exceeds $Mb',
      fileFormat: 'File format is not correct',
      fileExceedLimit: 'Exceeded number of files allowed',
      fileExisted: 'Please double check that the file exists',
      device_not_match:
        'This feature is not supported on mobile, so please do it on your computer.',

      messAdminUnpaidFee: (
        <>
          <div>
            <p className="tw-mb-4 tw-font-regular">
              Due to the non-payment of your service fee, we have temporarily
              suspended your account.
            </p>
            <p>
              Should you wish to resume your service, please contact us at the
              following.
            </p>
            <br />
            <p className="tw-mb-4 tw-font-regular">
              If we do not receive your payment by the end of this month, your
              membership will be terminated in accordance with our terms of
              service.
            </p>
            <p>
              Please note that if you cancel your membership, you will still be
              responsible for any outstanding fees.
            </p>
            <br />
          </div>
          <div className="tw-border tw-border-neutral-2 tw-p-12">
            <p className="tw-mb-4 tw-font-regular">
              【Questions about payment】
            </p>
            <p className="tw-mb-4 tw-ml-[10px] tw-font-regular">
              Tansaku-kun Support Center : 0120 - 15 - 4020
            </p>
            <p className="tw-mb-4 tw-ml-[10px] tw-font-regular">
              Email address :{' '}
              <a
                className="!tw-text-primary-8 !tw-underline"
                href={''}
                target="_blank"
              >
                tansaku@higobank.co.jp
              </a>
            </p>
          </div>
        </>
      ),
      messUserUnpaidFee: (
        <div>
          <p className="tw-mb-4 tw-font-regular">
            We are currently limiting the use of our service.
            <br></br>
            For more details, please contact your company administrator.
          </p>
        </div>
      ),
    },
    label: {
      total: 'Total',
      emissionVolume: 'Emission volume',
      action: 'Action',
      scope: 'Scope',
      scope1: 'Scope 1',
      scope2: 'Scope 2',
      scope3: 'Scope 3',
      scopeNote1: 'Scope 1 : Use of Fuel by The Company (Gasoline, Gas, etc.)',
      scopeNote2:
        'Scope 2 : Use of Electricity, Heat, etc. Supplied by Other Companies',
      scopeNote3:
        'Scope 3 : Emissions from Purchasing Products and Services and The Entire Supply Chain',
      offset: 'Offset',
      category: 'Category ',
      emissions: 'Emissions',
      optional: '(optional)',
      all: 'All',
      fileUpload: {
        text1: 'Drag and drop file or,',
        text2: 'File selection ',
        text3: 'please select.',
      },
      fileName: 'File name',
      notification: 'Notification',
      pagingDetail: (total: number, current: number, pageSize: number) => (
        <>
          Showing {(current - 1) * pageSize + 1}-
          {current * pageSize > total ? total : current * pageSize} of {total}{' '}
          items
        </>
      ),
      bankCode: 'Bank code',
      loginID: 'Login ID',
      enterpriseCode: 'Company code',
      enterpriseName: 'Company name',
      enterpriseNameKana: 'Company name by Katakana',
      tradeName: 'Trade name',
      representative: 'Representative person',
      representativeKana: 'Representative person by Katakana',
      postalCode: 'Postal code',
      address2: 'Address 2',
      phoneNumber: 'Representative phone number',
      numberOfEmployees: 'Number of employees',
      specifiedEmitterCode: 'Specified emitter code',
      specifiedBusinessNumber: 'Specified business number',
      corporateNumber: 'Corporate number',
      controlNumber: 'Management number',
      startMonthOfYear: 'Business year start month',
      business: 'Business',
      businessCode: 'Business code',
      businessMinister: 'Business minister',
      billingDate: 'Billing start date (YYYY/MM)',
      maximumNumberOfUsers: 'Maximum number of users',
      industryMajorCodeClassification: 'Major industrial classification code',
      industryMediumCodeClassification: 'Medium industrial classification code',
      paymentMethod: 'Payment method',
      isCorporateAccount: 'Is there a corporate account?',
      branchNumber: 'Branch number',
      corporateAccountNumber: 'Corporate account number',
      picName: 'Person in charge name',
      picNameKana: 'Person in charge name in Kana',
      picEmail: 'Person in charge email address',
      fullAddress: 'Address (Prefecture,city,ward,town,village,area)',
      productName: 'Product name',
      organizationBaseName: 'Base-Organization',
      groupBuyer: "Group's Buyer",
      emissionIntensity: 'Emission intensity',
      titleAdminUnpaidFee: 'Request for payment confirmation',
      titleUserUnpaidFee: 'Regarding the service usage restriction',
    },
    placeholder: {
      enterpriseName: 'XXX Corporation',
      enterpriseNameKana: 'XXX Kabushiki Gaisha',
      postalCode: '8120011',
      phoneNumber: 'Enter without hyphens',
      bankId: 'Partner bank',
      enterpriseNameSearch: 'Company Name',
      filter: 'Filter',
    },
    columns: { supplyChainGroupName: 'Supply Chain Group Name' },

    month: {
      Jan: 'Jan',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Apr',
      May: 'May',
      Jun: 'Jun',
      Jul: 'Jul',
      Aug: 'Aug',
      Sep: 'Sep',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Dec',
      '01': 'Jan',
      '02': 'Feb',
      '03': 'Mar',
      '04': 'Apr',
      '05': 'May',
      '06': 'Jun',
      '07': 'Jul',
      '08': 'Aug',
      '09': 'Sep',
      '10': 'Oct',
      '11': 'Nov',
      '12': 'Dec',
    },
  },
  A01: {
    label: {
      password: 'Password',
    },
    placeholder: {
      bankCode: 'Please enter your bank code',
      username: 'Please enter your login ID',
      password: 'Please enter your password',
    },
  },
  A03: {
    pageTitle: 'Password reissue',
    pageTitleSuccess: 'Password reissue completed',
    label: {
      passwordRule:
        'Please enter your registered bank code (4 digits), login ID (8 digits), and email address.',
      thePasswordWillBeSent:
        'An email containing your ID and temporary password will be sent to the email address you entered.',
      contactCompany:
        'If you have forgotten your login ID, please contact your company administrator.',
      theNewPasswordIsSent:
        'A temporary password has been sent to your email address.',
      recommendSplit1: 'The new password is',
      recommendSplit2: '"temporary password".',
      recommendSplit3: 'Therefore, on the password change screen ',
      recommendSplit4: 'Please change your password as soon as possible.',
      email: 'Mail address',
    },
    placeholder: {
      bankCode: ' Please enter your bank code',
      username: 'Please enter your login ID',
      email: 'Please enter your e-mail address',
    },
  },
  A04: {
    pageTitleSuccess: 'Password change completed',
    label: {
      oldPassword: 'Current password',
      newPassword: 'New password',
      confirmPassword: 'Confirm password (confirming)',
    },
    placeholder: {
      oldPassword: 'Please enter your current password',
      newPassword: 'Please enter a new password',
      confirmPassword: 'Please enter a new password',
    },
    message: {
      passwordExpired:
        'Your password has expired. Please change your password.',
      enterCurrentPasswordAndNewPassword:
        'Enter your current password and new password respectively.',
      changePasswordSuccess: 'You have successfully changed your password.',
      newPasswordLength:
        'Please set your password with 12 to 16 characters, including a combination of uppercase letters, lowercase letters, numbers, and symbols.',
      newPasswordFormat:
        'Please set your password with 12 to 16 characters, including a combination of uppercase letters, lowercase letters, numbers, and symbols.',
    },
  },
  A05: {
    pageTitleLogout: 'Logout',
    pageTitleSessionTimeOut: 'Session Expired',
    message: {
      logoutSuccessfully: 'You have successfully logged out.',
      sessionTimeout:
        'You have been automatically logged out because your session has expired.',
      loginAgain: 'Please log in again.',
    },
  },
  A06: {
    pageTitle: 'Temporary Register',
    pageTitle2: 'Confirm provisional application input details',
    label: {
      bankArea: 'Bank Area',
      bankName: 'Financial institution name',
      introducingCompanyName: "Tansaku-kun's Introducer",
      email: 'Email',
      emailConfirm: 'Email Confirmation',
      L1NoticeTitle: 'Something you need to prepare',
      L1NoticeContent1: (
        <>
          ・Credit card
          <br />
          ※Please prepare a credit card in the name of the corporation or the
          card of the corporation representative.
          <br />
          ※Please check with your tax advisor regarding accounting procedures.
        </>
      ),
      L1NoticeContent2: (
        <>
          ・Email address
          <br />
          ※Please register the email address of the person in charge who will
          primarily use this service.
          <br />
          ※The registered email address will be the destination for various
          notifications. Please be sure to register an email address that can
          receive emails.
        </>
      ),

      L2Notice: (
        <>
          We will send the URL for this application to the email address you
          entered, so please fill in the necessary information.
          <br />
          Please have your company information and credit card information ready
          in advance.
        </>
      ),
      L3Notice: (
        <>
          Please check the information you entered. <br />
          If you wish to make changes, please press the "Back" button.
        </>
      ),
      success: 'Temporary register completed',
      L4Notice: (
        <>
          The URL for this application has been sent to the email address you
          entered.
          <br />
          Please close it using the close button of your browser (or tab).
        </>
      ),
      step3Notice1:
        'Please be aware that if you have configured your email settings to only receive emails from specific domains or addresses as a spam prevention measure, you may not be able to receive our application email.',
      step3Notice2:
        'We kindly request that you set your email settings to allow emails from [system@zero-carbon-system.jp] in advance.',
      step3Notice3:
        'Additionally, depending on the type of email you are using, automated emails may be delivered to your spam folder.',
      ourBank: 'From our bank staff',
      staffName: `Staff's name`,
      otherCompany: 'From other companies',
      introCompany: 'Referral from an introducer',
      homepage: 'Homepage',
      cm: 'CM / Flyer',
      seminars: 'Seminars / Lectures',
      newspaper: 'Newspaper/magazine articles',
      sns: 'SNS',
      mail: 'Mail',
      others: 'Others',
      reference: 'How did you hear about us?',
      fromBankDescription: `Please enter the staff's name`,
      fromOthersDescription: 'Please specify',
      details: 'Details',
      select1Option: 'Please select one option',
      titleResult: 'Something went wrong',
      contentResult: (
        <>
          It looks like the URL you entered is incorrect. <br />
          Please check the URL and try again.
        </>
      ),
    },
    placeholder: {
      bankArea: 'Please select your region',
      bankName: 'Please select your financial institution',
      email: 'Please enter your email address',
      emailConfirm: 'Please enter your email address',
    },
    message: {
      emailFormat: 'The email address you entered cannot be used.',
      emailConfirmFormat:
        'Email address and email address confirmation are different. Please check that you have entered the correct email address.',
    },
  },
  A07: {
    L1Title: 'Enter corporate information (main application)',
    L2Title: 'Agreement',
    L3Title:
      'Confirm the input information for corporate use application information',
    label: {
      bankName: 'Name of financial institution to which you are applying',
      specifiedBusinessNumber: 'Specified Business Number',
      corporateNumber: 'Corporate Number',
      businessMinister: 'Business Minister',
      L2Agreement:
        'I agree to the terms of service and personal information handling regulations.',
      policy: 'Regulations regarding the handling of personal information',
      term1: 'Contract details',
      term2: (
        <>
          <div className="tw-border-[1px] tw-border-neutral-8">
            <div className="tw-grid tw-grid-cols-5 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-neutral-8">
              <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-neutral-8 tw-bg-[#B9EBFE]">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">Service contents</p>
              </div>
              <div className="tw-col-span-4">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">
                  CO2 emissions calculation system <br />
                  「Zero-Carbon-System（炭削くん）」
                </p>
              </div>
            </div>
            <div className="tw-grid tw-grid-cols-5 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-neutral-8">
              <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-neutral-8 tw-bg-[#B9EBFE]">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">Basic usage fee </p>
              </div>
              <div className="tw-col-span-4">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">
                  2,200 yen per month（tax included）
                </p>
              </div>
            </div>
            <div className="tw-grid tw-grid-cols-5 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-neutral-8">
              <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-neutral-8 tw-bg-[#B9EBFE]">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">Payment method </p>
              </div>
              <div className="tw-col-span-4">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">
                  Pay by a credit card
                </p>
              </div>
            </div>
            <div className="tw-grid tw-grid-cols-5 tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-neutral-8">
              <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-neutral-8 tw-bg-[#B9EBFE]">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">Usage period</p>
              </div>
              <div className="tw-col-span-4">
                <p className="tw-ml-6 tw-mt-6 tw-text-left">
                  The usage period will be automatically extended for one month
                  on the 1st of every month.
                </p>
              </div>
            </div>
          </div>
          <div>
            <p>Usage fee details</p>
            <div className="tw-border-[1px] tw-border-neutral-8">
              <div className="tw-grid tw-grid-cols-2 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-neutral-8">
                <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-neutral-8">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">Account </p>
                </div>
                <div className="">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">Fee</p>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-2 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-neutral-8">
                <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-neutral-8">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">1~5 users</p>
                </div>
                <div className="">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">
                    2,200 yen per month（tax included）
                  </p>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-2 tw-border-b-[1px] tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-neutral-8">
                <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-neutral-8">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">6~10ユーザー </p>
                </div>
                <div className="">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">
                    3,300 yen per month（tax included）
                  </p>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-2 tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-neutral-8">
                <div className="tw-border-b-0 tw-border-l-0 tw-border-r-[1px] tw-border-t-0 tw-border-neutral-8">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">11~15ユーザー </p>
                </div>
                <div className="">
                  <p className="tw-ml-6 tw-mt-6 tw-text-left">
                    4,400 yen per month（tax included）
                  </p>
                </div>
              </div>
            </div>
            <p>※After that, it will be added for every 5 users.</p>
          </div>
        </>
      ),
      L3Agreement:
        'I agree to the terms of service and regulations regarding the handling of personal information.',
      success: 'Temporary register complete',
      successMsg: (
        <>
          ・This application has been accepted. An automatic reply email will be
          sent to the email address you entered. please confirm.
          <br />
          ・After the secretariat has confirmed the information you have
          entered, we will contact you by email or phone with the confirmation
          results.
          <br />
          ・It takes 1 to 3 business days for confirmation by the secretariat.
          Thank you for your understanding.
        </>
      ),
      successMsg2:
        'Please close it using the close button of your browser (or tab).',
      fail: 'URL expired',
      failMsg: (expirationPeriodHours: string) => (
        <>
          URL has expired. <br />
          Please make your full application within{' '}
          {expirationPeriodHours !== 'undefined'
            ? expirationPeriodHours
            : 72}{' '}
          hours of your provisional application.
          <br />
          We apologize for the inconvenience, but please use the link below to
          complete the provisional application procedure again.
        </>
      ),
      A09_fail: 'There was a problem processing your payment',
      A09_failMsg: (msg: string) => (
        <div className="tw-flex tw-flex-col tw-gap-24">
          <p>
            We apologize for the inconvenience, but an error occurred while
            registering your credit card information. Please check the following
            points.
          </p>
          <HgbBulletItem>{msg}</HgbBulletItem>
          <p>Please check these and try again in a few minutes.</p>
          <p>
            If this problem persists, please contact your card issuer or contact
            the Sumikokun office.
          </p>
          <p>
            We apologize for any inconvenience this may cause and ask for your
            understanding and cooperation.
          </p>
        </div>
      ),
    },
    placeholder: {
      enterpriseCode: '',
      tradeName: '',
      representative: '',
      representativeKana: '',
      address1: '',
      address2: '',
      numberOfEmployees: '',
      specifiedEmitterCode: '',
      specifiedBusinessNumber: '',
      corporateNumber: '',
      controlNumber: '',
      startMonthOfYear: '',
      business: '',
      businessCode: '',
      businessMinister: '',
      billingDate: '',
      maximumNumberOfUsers: '',
    },
    message: {
      postalCodeFormat: 'Invalid postal code.',
      deleteEnterpriseAlertSplit1: 'Are you sure you want to delete "',
      deleteEnterpriseAlertSplit2: '" company?',
      branchNumberFormat: 'Please enter 3 digits',
      corporateAccountNumberFormat: 'Please enter 7 digits',
      phoneNumberFormat: 'Invalid phone number.',
    },
  },
  A08: {
    mainTitle: 'Application for corporate use (main application)',
    title: 'Billing and payment',
    subTitle: (
      enterpriseMonthlyFree: string | 0,
      enterpriseTrialMonth: number | undefined,
      subscriptionType: string,
    ) => (
      <>
        Basic monthly amount{' '}
        <span className="tw-text-[24px] tw-font-bold">
          {subscriptionType !== 'COMPLIMENTARY' ? enterpriseMonthlyFree : 0} yen
        </span>
        {subscriptionType !== 'COMPLIMENTARY' && '(tax included)'} <br />
        {subscriptionType !== 'COMPLIMENTARY'
          ? `※Free for ${
              enterpriseTrialMonth ? enterpriseTrialMonth : 0
            } months including the month you start using it`
          : `※Available for free`}
      </>
    ),
    content: (enterpriseMonthlyFree: string | 0) => (
      <>
        <p className="tw-text-h4">
          {' '}
          <p className="tw-text-h4 tw-font-bold">How to pay:</p>
          <HgbBulletItem>Payment by credit card is available</HgbBulletItem>
          <HgbBulletItem>
            Payment by bank transfer or invoice is not supported.
          </HgbBulletItem>
        </p>
        <p className="tw-text-h4">
          {' '}
          <p className="tw-text-h4 tw-font-bold">Billing schedule</p>
          <HgbBulletItem>
            Billing for one month will be finalized at 0:00 on the 1st of each
            month.
          </HgbBulletItem>
          <HgbBulletItem>
            Prices vary depending on the number of users.
          </HgbBulletItem>
          <HgbBulletItem>
            The basic fee is {enterpriseMonthlyFree} yen for up to 5 users.
          </HgbBulletItem>
          <HgbBulletItem>
            Please refer to the terms and conditions for details.
          </HgbBulletItem>
        </p>
      </>
    ),
    notice:
      '※From now on, procedures will be processed at Sony Payment Service Co., Ltd., which is affiliated with Sumikake-kun.',
  },
  D01: {
    pageTitleEnterprise: 'Enterprise information',
    pageTitleBank: 'List company',
    modal: {
      enterpriseAddTitle: 'Add company',
      enterpriseDeleteTitle: 'Delete company',
      enterpriseUpdateTitle: 'Update company',
      enterpriseViewTitle: 'Company information',
      enterpriseApproveTitle: 'Approval confirmation',
      enterpriseDeniedTitle: 'Denial confirmation',
      enterpriseUpdateConfirmModalTitle:
        'Company information change confirmation',
      enterpriseAppropvalModalTitle: 'Approval of corporate use application',
      enterpriseUpdateCradModalTitle: (
        <p className="tw-text-[16px] tw-font-bold tw-leading-[24px]">
          Credit card change completed
        </p>
      ),
    },
    table: {
      id: 'Id',
      representative: 'Representative',
      address1: 'Address',
      phoneNumber: 'Phone number',
      billingDate: 'Billing start date',
      number: 'Branch number - Corporate number',
      picName: 'PIC name',
      status: 'Status',
      email: 'Email',
      url: 'URL expiration date',
      lastActivityModifyDate: 'Last modified date',
    },
    label: {
      scope3DirectInput: 'SCOPE3 direct input usage category',
      isInvested: 'Investment',
      activeTab: 'Main application',
      pendingTab: 'Temporary application',
      cif: 'Company identification key information',
      approve: 'Corporate usage status',
      pending: 'Waiting for secretariat approval (temporarily saved)',
      active: 'Secretariat approval',
      inactive: 'Secretariat denial',
      approveContent: (compName: string) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-24">
            <p>We approve the application from the following companies.</p>
            <HgbBulletItem>{compName}</HgbBulletItem>
            <p>
              Once approved, account information will be sent to the company
              representative via email. Email sending cannot be deleted.
            </p>
            <p>Is it OK?</p>
          </div>
        );
      },
      deniedContent: (compName: string) => {
        return (
          <div className="tw-flex tw-flex-col tw-gap-24">
            <p>Applications from the following companies will be rejected.</p>
            <HgbBulletItem>{compName}</HgbBulletItem>
            <p>
              If rejected, a notification of rejection will be sent to the
              person in charge of the company via email. Email sending cannot be
              canceled.
            </p>
            <p>Is it OK?</p>
          </div>
        );
      },

      updateConfirmContent: (
        <div className="tw-flex tw-flex-col tw-gap-24">
          <p>Change company information. Is it OK?</p>
          <p>
            If you wish to change any of the information below, please contact
            your financial institution.
          </p>
          <div>
            <HgbBulletItem>Company name, company name in kana</HgbBulletItem>
            <HgbBulletItem>
              Representative, representative in kana
            </HgbBulletItem>
            <HgbBulletItem>
              Postal code, address, building name, etc.
            </HgbBulletItem>
            <HgbBulletItem>Phone number</HgbBulletItem>
          </div>
        </div>
      ),

      updateCardContent: (
        <p className="tw-text-center">
          Your credit card information has been successfully changed.
        </p>
      ),

      leftGroupContent: (
        <div className="tw-flex tw-flex-col tw-gap-24 tw-p-24">
          <p>
            To cancel your membership, please apply from the link below. Our
            office will contact you.
          </p>
          <p>
            <a
              className="!tw-text-primary-8 !tw-underline"
              href={HIGO_QUATRICS_URL}
              target="_blank"
            >
              Tansaku-kun Cancel Membership
            </a>
            （a new window will open）
          </p>
        </div>
      ),
    },
    placeholder: {
      enterpriseCode: '',
      tradeName: '',
      representative: '',
      representativeKana: '',
      address1: '',
      address2: '',
      numberOfEmployees: '',
      specifiedEmitterCode: '',
      specifiedBusinessNumber: '',
      corporateNumber: '',
      controlNumber: '',
      startMonthOfYear: '',
      business: '',
      businessCode: '',
      businessMinister: '',
      billingDate: '',
      maximumNumberOfUsers: '',
    },
    message: {
      postalCodeFormat: 'Invalid postal code.',
      deleteEnterpriseAlertSplit1: 'Are you sure you want to delete "',
      deleteEnterpriseAlertSplit2: '" company?',
      branchNumberFormat: 'Please enter 3 digits',
      corporateAccountNumberFormat: 'Please enter 7 digits',
      phoneNumberFormat: 'Invalid phone number.',
      billingStartDateFormat:
        'The billing start date is within the free period.',
    },
  },
  D02: {
    pageTitle: 'List of Base-Organization',
    modal: {
      organizationDeleteTitle:
        'Notice for delete information about Base-Organization',
      organizationUpdateTitle: 'Edit Base-Organization',
      organizationAddTitle: 'New signup Base-Organization',
      organizationViewTitle: 'Base-Organization information',
    },
    label: {
      enterpriseId: 'Company',
      classification: 'Division',
      baseName: 'Name of base/organization',
      parentOrganizationId: 'Organization',
      parentBaseId: 'Upper base',
      displayOrder: 'Display order',
      baseId: 'Base-Organization ID',
    },
    placeholder: {
      bankId: '',
      enterpriseId: '',
      baseName: 'Name of base/organization',
      parentOrganizationId: '',
      parentBaseId: '',
      displayOrder: '',
    },
    message: {
      // baseName: 'Name is required item.',
      // classification: 'Division is required item.',
      // parentOrganizationId: 'Upper Organization is a required item.',
      deleteBaseAlertSplit1: 'Are you sure you want to delete "',
      deleteBaseAlertSplit2: '" Base-Organization?',
    },
  },
  D04: {
    pageTitle: 'User input',
    modal: {
      userDeleteTitle: 'Notice delete user',
      userUpdateTitle: 'Edit User',
      userAddTitle: 'New user registration',
      userViewTitle: 'User Information',
    },
    label: {
      bankName: 'Bank name',
      companyName: 'Bank / Company name',
      name: 'Name',
      nameKana: 'Name by Katakana',
      roleName: 'Authority',
      email: 'Email address',
      userId: 'User ID',
      roleId: 'Authority',
      departmentId: 'Organization',
      partnerBank: 'Partner Bank',
      enterprise: 'Enterprise',
      baseOrganization: 'Base/Organization',
    },
    placeholder: {
      nameSearch: 'filter',
      userId: '',
      name: 'Example: Higo',
      nameKana: 'Example: Higo',
      email: 'Example: higo@higobank.co.jp',
      roleId: '',
      departmentId: '',
    },
    message: {
      email: 'Email address is required item.',
      name: 'Name is required item.',
      nameKana: 'Kana name is required item.',
      partnerBank: 'Partner bank is required item.',
      enterprise: 'Enterprise is required item.',
      baseOrganization: 'Base/Organization is required item.',
      deleteUserAlertSplit1: 'Are you sure you want to delete "',
      deleteUserAlertSplit2: '" user?',
    },
  },
  D06: {
    pageTitle: 'Emissions Target List',
    modal: {
      emissionDeleteTitle: 'Notice of target deletion',
      emissionUpdateTitle: 'Edit Emission Target',
      emissionAddTitle: 'Emission Target Registration',
      emissionViewTitle: 'Emission Target Information',
    },
    label: {
      enterpriseIdSearch: 'Company',
      yearSearch: 'Business year',
      year: 'Year',
      target: 'Emission target',
    },
    placeholder: {
      bankIdSearch: '',
      enterpriseIdSearch: '',
      yearSearch: '',
      year: 'Example: 2022',
      target: 'Example: 150t',
      scopeCode: '',
      categoryCode: '',
      baseId: '',
    },
    message: {
      year: 'Year is required item.',
      target: 'Emission target is required item.',
      scopeCode: 'Scope is required item.',
      categoryCode: 'Category is required item.',
      baseId: 'Base is required item.',
      deleteYearAlertSplit1: 'Are you sure you want to delete year "',
      deleteYearAlertSplit2: '"?',
    },
  },
  G01: {
    pageTitleBankInfo: 'Partner bank info',
    pageTitleBankList: 'Partner bank list',
    modal: {
      bankDeleteTitle: 'Delete partner bank',
      bankViewTitle: 'Partner Bank Information',
      bankAddTitle: 'Partner Bank Registration',
      bankUpdateTitle: 'Edit Partner Bank',
    },
    label: {
      area: 'Bank Area',
      province: 'Bank Province',
      bankName: 'Bank Name',
      loginUrl: 'Login URL',
      personInCharge: 'Manager',
      personInChargeEmail: "Manager's email",
      nameSearch: 'Name',
      privacyPolicyUrl:
        'Regulations regarding the handling of personal information URL',
      signature: 'Email signature',
      numberCompany: 'Number of companies using',
      freePeriod: 'Free period',
      usageFee: 'Corporate monthly usage fee',
      billStartMonth: 'Billing start month',
      urlExpired: 'Expiration date of this application URL',
      subscriptionType: 'Subscription type',
      complimentary: 'COMPLIMENTARY (Free of charge)',
      freeTrial: 'FREE TRIAL',
      numberOfActiveEnterprises: 'Number of active enterprises',
      enterpriseTrialStartMonths: 'Enterprise trial start month',
      enterpriseTrialMonths: 'Enterprise trial months',
      enterpriseMonthlyFee: 'Enterprise monthly fee',
      enterpriseTrialMonthsG02: 'Enterprise trial months',
      enterpriseMonthlyFeeG02: 'Enterprise monthly fee (Tax included)',
      billingStartMonth: 'Billing start month',
      URLExpirationPeriodHours: 'URL Expiration period hours',
      months: 'months',
      'yen/month': 'yen / month',
      yen: 'yen',
      hours: 'hours',
    },
    placeholder: {
      area: 'Kyushu',
      province: 'Kumamoto',
      bankCode: 'Example: 0045',
      bankName: 'Example: Higo Bank',
      loginUrl: '',
      personInCharge: 'Example: Ichiro Suzuki',
      personInChargeEmail: 'info@higobank.co.jp',
      nameSearch: 'Partner bank name',
      privacyPolicyUrl:
        'Example://www.higobank.co.jp/policy/privacy/declaration.html',
      signature: '',
    },
    message: {
      deleteBankAlertSplit1: 'Are you sure you want to delete "',
      deleteBankAlertSplit2: '" bank?',
    },
  },
  CM01: {
    modal: {
      linkAddTitle: 'Add external link',
      linkUpdateTitle: 'Edit external link',
      linkDeleteTitle: 'Delete external link',
      linkDeleteSubTitle:
        'Are you sure you want to delete the following link ?',
    },
    label: {
      externalLink: 'External link',
      tutorialVideo: 'Tutorial video',
      addExternalLink: 'Add external link',
      linkName: 'Link name',
      url: 'External URL',
      userInfo: 'Information of user',
      english: 'English',
      japanese: 'Japanese',
      higoManual: 'Superviser manual',
      partnerManual: 'Management manual',
      userManual: 'User manual',
      supplyChainManual: 'Supply chain manual',
      supplyChain: 'Supply Chain',
      inputUpload: 'Input/Upload',
    },
    placeholder: { linkName: 'external link name', url: 'external link' },
    message: {
      externalLinksEmpty: 'No external links',
    },
  },
  F01: {
    message: {
      fileNotFound: 'Manual file does not exist.',
    },
  },
  F03: {
    message: {
      fileNotFound: 'The FAQ file does not exist.',
    },
  },
  C01: {
    pageTitle: 'Data list',
    modal: {
      supplierDeleteTitle: 'Notice of Bulk Data Deletion',
      emissionDeleteTitle: 'Notice of data deletion',
      emissionDeleteAllTitle: 'Notice of bulk data deletion',
      emissionCreateConfirmTitle: 'Register confirmation',
      emissionUpdateConfirmTitle: 'Edit confirmation',
      emissionCreateTitle: 'Direct input',
      emissionUpdateTitle: 'Edit',
      emissionDuplicateTitle: 'Duplicate',
      uploadConfirmModalTitle: <>Upload confirmation</>,
      uploadConfirmModalContent: 'Perform file upload and load?',
      uploadConfirmModalOkButton: 'Upload',
      csvConfirmModalTitle: 'Import confirmation',
      csvConfirmModalContent: 'Are you sure you want to import the CSV file?',
      csvConfirmModalOkButton: 'Import',

      csvErrorModalTitle: 'Import Fail',
      csvErrorModalContent1: 'Failed to import the CSV file.',
      csvErrorModalContent2: 'Check your error log.',
      csvErrorModalCancelButton: 'Download error file log',
      numberImported: 'Number of items to be imported: $columns',
      numberFail: 'Number of import failures: $columns',
      lcidbErrorModalContent: (
        <>
          File import failed. <br />
          Please check the error.
        </>
      ),
      recalculationConfirmModalTitle: 'Recalculation confirmation',
      recalculationConfirmModalContent:
        'Do you want to recalculate your emissions?',
      recalculationConfirmModalOk: 'Recalculation',
      supplierConfirmModalTitle: 'Notice of answer deletion',
      supplierConfirmModalSubTitle: (quantities: string) => (
        <>
          {quantities} selected record(s) and associated contents will be
          irreversibly deleted. Are you sure you wish to continue?
          <br />
          All emission data that have been assigned over multiple months from
          the supplier will be deleted. Additionally, data registered from the
          "Received Response List" screen can be re-registered from the same
          screen.
        </>
      ),
    },
    label: {
      newRegistrationEmissionData: 'New registration of emissions data',
      targetYearMonth: 'Month',
      enterpriseName: 'Company',
      calculationTargetName: 'Calculated Subject',
      calculationMethodName: 'Calculated Method',
      supplierCompanyName: 'Supplier company name',
      emissionExplanation: 'Emissions explanation',
      types: 'Type',
      amount: 'Amount to use',
      intensity: 'Unit of Emission',
      createdBy: 'Registered person',
      createdDate: 'Date Of Registration ',
      createdMethod: 'Method of Registration ',
      ideaYear:
        'Fiscal year of Ministry of the Environment DB used for calculation',
      offsetAmountWithUnit: 'Offset amount (with unit)',
      offsetAmountUnit: 'Offset amount unit',
      offsetAmount: 'Offset amount ',
      offsetCertificateDate: 'Date of issue offset',
      offsetTarget: 'Offset Subject',
      projectName: 'Project Name',
      creditType: 'Credit type',
      insertMethodName: 'Method of Registration',
      fiscalYear: 'Fiscal year',
      uploadFile: 'File upload',
      uploadCSV: 'Import CSV',
      emissionDataSearch: 'Emission data search',
      enterpriseId: 'Company',
      baseId: 'Base/Organization',
      displayRangeSpecification: 'Display range specification',
      search: 'Search',
      dataEntry: 'Input data',
      scopeCode: 'Scope/Offset',
      activityName: 'Activity name',
      note: 'Note',
      lcidb: 'Apply LCI-DB',
      supplierEmissionsInput: 'Supplier emissions input',
      returnToDataList: 'Return to data list screen',
      upload: 'Upload',
      invoice: 'Invoice',
      certificate: 'Carbon credit',
      fileType: 'File type',
      csvUpload: 'CSV Data Import',
      download: 'Download format CSV',
      import: 'Import',
      businessYear: 'Business Year',
      lcidbImport: 'LCI-DB Import',
      deleteAll: 'Delete All',
      startMonth: 'Start date',
      endMonth: 'End date',
      targetFiscalYear: 'Fiscal year',
      activityVolume: 'Amount of usage',
      greenEnergyCertificateVolume: 'Deducted Amount',
      fillingVolume: 'Filling amount',
      rawActivityVolume: 'Raw material input quantity or price',
      rawEmissionIntensity: 'Emission unit value',
      rawTransportActivityVolume: 'Raw material transport volume',
      rawTransportEmissionIntensity: 'Emission unit value',
      capitalWasteActivityVolume:
        'Weight of waste associated with capital goods',
      capitalWasteEmissionIntensity: 'Emission unit value',
      emissionIntensity: 'Emission unit value',
      transportCargoWeight: 'Cargo Weight',
      transportMaximumCargoWeight: 'Maximum cargo weight',
      transportDistance: 'Transportation distance',
      numberOfPeople: 'The number of employees',
      numberOfNight: 'Number of nights',
      numberOfDay: 'Number of days',
      expectedLifetimeUsagePer: 'Expected Lifetime Use',
      saleCount: 'The number of sales',
      perLessonUsage: 'The amount of fuel used per use',
      emissionLifetimeUse: 'Lifetime use emission rate (%)',
      distribution: '',
      fuelEconomy: 'Fuel consumption',
      transportLoadRate: 'Load rate',
      businessDaysYear: 'Number of business days in a year',
      expectedLifetimeUsage: 'Expected Lifetime Use',
      expectedLifetimeUsagePercentage: 'Percentage of expected lifetime usage',
      useEmissionCoefficient: '',
      unclearActivityVolume1: 'Emissions for each equity investment',
      unclearActivityVolume2: 'Shareholding ratio (%)',
      unclearActivityVolume3: 'Emissions for each fixed income investment',
      unclearActivityVolume4: "Percentage of investee's total capital (%)",
      unclearActivityVolume5: 'Emissions for each project',
      unclearActivityVolume6: 'Project investment percentage (%)',
      unclearActivityVolume8: "Percentage of investee's total capital (%)",
      unclearActivityVolume10: 'The customer ratio (%) to the total business',
      unclearActivityVolume11: 'Emissions for each other investment.',
      unclearActivityVolume12: 'Percentage of total investment (%)',
      unclearEmissionIntensity1: 'Emission of the investment sector unit value',
      unclearEmissionIntensity2: 'Emission of the investment sector unit value',
      unclearEmissionIntensity3: 'Emission of the investment sector unit value',
      unclearEmissionIntensity4: 'Emission of the investment sector unit value',
      unclearEmissionIntensity5: 'Emission of the investment sector unit value',
      unclearEmissionIntensity6: 'Emission of the investment sector unit value',
      fuelUsagePerUse: 'Fuel usage per use',
      unit: 'Unit',
      greenHouseGases: 'Types of greenhouse gases',
      greenHouseGasCode: 'Green House Gas Code',
      C02Equivalent: 'CO2 Equivalent',
      activityType: 'Activity type',
    },
    placeholder: {
      bankId: '',
      enterpriseId: '',
      baseId: 'Base. Organization',
      targetYear: '',
      categoryCode: '',
      offsetAmountUnit: '',
      offsetAmount: '',
      offsetTarget: '',
      offsetCertificateDate: '',
      projectName: '',
      creditType: '',
      fiscalYear: '',
      targetYearMonth: '',
      scopeCode: '',
      activityName: '',
    },
    message: {
      startMonthendMonth:
        'The display start date and display end date are not correct.',
      deleteEmissionConfirm: 'Are you sure you want to delete data?',
      deleteAllEmissionConfirm: (s: string) => (
        <>
          {s} selected record(s) and associated contents will be irreversibly
          deleted. Are you sure you wish to continue?
        </>
      ),
      emissionCreateConfirm:
        'Are you sure you want to perform data registration?',
      emissionUpdateConfirm: 'Are you sure you want to update data?',
      dataEntryScopeNotice: (
        <>
          ※Emission intensity and emissions are automatically calculated, so
          there is no need to input them. (However, depending on the input
          contents of other items, the user may need to input them.)
        </>
      ),
      dataEntryScopeNoticeReset:
        '※Since the fiscal year has been changed and the emission calculation logic is different, duplication is not allowed. Please start by entering the information from the beginning.',
      dataEntryOffsetNotice:
        '※Offset target, project name, and credit type are optional inputs.',
      recoveryVolume:
        'Collection and appropriate disposal amount are required items.',
      recoveryVolumeFormat:
        'Collection and appropriate disposal amount are required items.',
      emissionVolumeNegativeCreate:
        'Registration failed because emission data value is negative. ',
      emissionVolumeNegativeUpdate:
        'Update failed because emission data value is negative. ',
      capitalWasteActivityVolumeFormat:
        'Weight of waste associated with capital goods',
      distributionFormat: '',
      useEmissionCoefficientFormat: '',
      unclearActivityVolume7:
        'Emissions from bond investments for which the use of each income is not clear',
      unclearActivityVolume7Format:
        'Emissions from bond investments for which the use of each income is not clear is a required item.',
      unclearActivityVolume9:
        'Emissions for each managed investment and client operation are mandatory.',
      unclearActivityVolume9Format:
        'Emissions for each managed investment and client operation are mandatory.',
      unit: 'The selected units are not suitable. Please reselect',
      manualIdeaInput:
        'The selected manualIdeaInput are not suitable. Please reselect',
      c02NoticeScope:
        '※Emission intensity and emissions are automatically calculated, so there is no need to input them. (However, depending on the input contents of other items, the user may need to input them.)',
      c02NoticeOffset:
        '※Offset target, project name, and credit type are optional inputs.',
      categoryNotSupport:
        'The selected category has been disabled by enterprise settings.',
      fileFormatCsv: '"CSV file format does not exist.',
    },
  },
  C07: {
    title: 'Green Energy Certificate List',
    titleDetail: 'Green Energy Certificate Details',
    certificateType: 'Certificate Type',
    certificateNumber: 'Certificate number',
    certificateNo: 'Certificate No.',
    serialNo: 'Serial No.',
    deductionTarget: 'Deduction Target',
    generatedElectricityAmount: 'Generated Electricity Amount',
    generatedPowerAmount: 'Generated Power Amount',
    generatedHeatAmount: 'Generated Heat Amount',
    issueDate: 'Issued Date',
    startDate: 'Start Date',
    endDate: 'End Date',
    certificateValidityPeriod: 'Certificate Validity Period',
    certificateName: 'Certifcate Name',
    buttonBack: 'Return to Green Energy Certificate List Screen',

    column: {
      creditID: 'Credit ID',
      generatedPowerAmount: <>Generated Power Amount</>,
      deductedAmount: 'Deducted Amount',
      remainingAmount: 'Remaining Amount',
      certificateIssueDate: 'Certificate issue date',
      action: 'Action',
      operation: 'Operation',
      scope: 'Scope',
      yearMonth: 'Year/Month',
      baseOrganization: 'Base - Organization',
      calculationTarget: 'Calculation Target',
      type: 'Type',
      activityVolume: 'Activity volume',
    },

    modal: {
      titleModalConfirm: <>Green Energy Certificate Deletion Confirmation</>,
      titleModalRegister: <>Green Energy Certificate Registration</>,
      titleModalUpdate: <>Green Energy Certificate Edit</>,
      attentionMessage: (
        <>Are you sure you want to delete the following certificate data?</>
      ),
      generatedPowerAmount: 'Generated power amount',
      certificateIssueDate: 'Certificate issue date',
    },
  },

  B01: {
    pageTitle: '',
    label: {
      generalAnchor: 'Summary',
      monthlyEmissionAnchor: 'Monthly emissions',
      thisYearsSimulationAnchor: "This year's simulation",
      breakdownEmissionAnchor: 'Breakdown of emissions',
      predictEmissionAnchor: 'Predict',
      scope1Last: 'Scope 1 (last year)',
      scope2Last: 'Scope 2 (last year)',
      scope3Last: 'Scope 3 (last year)',
      scope1Current: 'Scope 1 (current year)',
      scope2Current: 'Scope 2 (current year)',
      scope3Current: 'Scope 3 (current year)',
      general: 'Summary',
      cumulativeCurrentYear: 'Cumulative value for the current year',
      targetCurrentYear: 'Target value for current year',
      compareEmission: "Compared to the previous year's total emissions",
      monthlyEmission: 'Monthly emissions',
      thisYearsSimulation: "This year's simulation",
      breakdownEmission: 'Breakdown of emissions',
      predictEmission: 'Predict',
      higoBank: 'Higo Bank',
      predict: 'Predict',
      detail: 'Detail',
      energyOriginCO2: 'Energy Origin CO2',
      nonEnergyOriginCO2: 'Non Energy Origin CO2',
      scope1Desc: 'Use of Fuel by The Company (Gasoline, Gas, etc.)',
      scope2Desc: 'Use of Electricity, Heat, etc. Supplied by Other Companies',
      scope3Desc:
        'Emissions from Purchasing Products and Services and The Entire Supply Chain',
      other: 'Other',
      total3Scopes1: 'Current year cumulative value',
      total3Scopes2: '(Scope 1, 2, 3)',
      total1: 'Total emissions for the current year',
      total2: '(after offset)',
      compareToLastYear1: 'Current emission status',
      compareToLastYear2: '(relative to target value)',
      target1: 'Target value for the current year',
      target2: '(Entire company)',
      scope2MethodSelected: 'Scope2 calculation criteria',
    },
    placeholder: {
      enterpriseId: 'Company',
      targetYear: 'Fiscal year',
      baseId: 'Base. Organization',
      supplyChain: 'Supply chain companies',
    },
    scope3Descs: {
      '01': 'Purchased Goods and Services',
      '02': 'Capital Goods',
      '03': 'Fuel- and Energy-Related Activities (Not Included in Scope 1 or Scope 2)',
      '04': 'Upstream Transportation and Distribution',
      '05': 'Waste Generated in Operations',
      '06': 'Business Travel',
      '07': 'Employee Commuting',
      '08': 'Upstream Leased Assets',
      '09': 'Downstream Transportation and Distribution',
      '10': 'Processing of Sold Products',
      '11': 'Use of Sold Products',
      '12': 'End-of-Life Treatment of Sold Products',
      '13': 'Downstream Leased Assets',
      '14': 'Franchises',
      '15': 'Investments',
    },
  },
  E01: {
    pageTitle: 'Export Report',
    label: {
      fiscalYear: 'Fiscal year',
      LCIDBYear: 'LCI-DB year',
      excel: 'EXCEL',
      csv: 'CSV',
      enterpriseId: 'Company',
      baseId: 'Base. Organization',
      outputType: 'Export type',
      outputDisplayRange: 'Display range specification',
    },
    placeholder: {
      bankId: '',
      enterpriseId: '',
      fiscalYear: '',
      targetYearMonthStart: '',
      targetYearMonthEnd: '',
    },
    message: {
      startMonth: 'Start date is required item.',
      endMonth: 'End date is required item.',
      startMonthendMonth:
        'The display start month and display end month are not correct.',
      timeBetweenExceedOneYear:
        'Time between start month and end month cannot exceed one year.',
    },
    modal: {
      exportConfirmation: 'Output confirmation',
      exportConfirmationQuestion: 'Are you sure you want to print the report?',
      exportConfirmationWarning:
        'It may take some time depending on the output conditions.',
    },
  },
  E02: {
    pageTitle: 'Company information list output instruction',
    label: {
      format: 'Output format',
      excel: 'EXCEL',
      csv: 'CSV',
      status: 'Status',
      selectAll: 'Select all',
      deselectAll: 'Deselect all',
      confirmContent: (
        <>
          <p>Do you want to output the company information list?</p>
          <p>It may take some time depending on the output conditions.</p>
        </>
      ),
    },
    modal: {
      confirmTitle: 'Output confirmation',
    },
  },
  D13: {
    pageTitle: 'Message Distribution List',
    label: {
      id: 'ID',
      title: 'Title',
      type: 'Message Type',
      delivery: 'Message Delivery Range',
      status: 'Status',
      displayStartDate: 'Delivery start date and time',
      displayEndDate: 'Delivery end date and time',
      lastModified: 'Last Modified',
      description: 'Description',
    },
    placeholder: {
      message: 'Message',
    },
    message: {
      startDateTimeEarlier:
        'Please set the delivery start date and time to a value that is earlier than the delivery end date and time.',
      endDateTimeEarlier:
        'The delivery end date and time must be in the future.',
      edit: `Messages that have already been delivered to users cannot be edited.
          If corrections are necessary, please create a new correction message
          and send it, then delete the incorrect message. 
          (Sent emails cannot be deleted)
        `,
      delete: (
        <>
          You are attempting to delete a message that has already been delivered
          to a user. <br />
          Are you sure you want to delete?
          <br />
          (Sent emails cannot be deleted)
        </>
      ),
    },
    modal: {
      addMessage: 'New Message Registration',
      displayStartDate: 'Message delivery start date and time',
      displayEndDate: 'Message delivery end date and time',
      messageType: 'Message type',
      messageRange: 'Message range',
      messageTitle: 'Message title (within 50 characters)',
      messageBody: 'Message body (within 2000 characters)',

      updateMessage: 'Update message',

      confirmMessage: 'Message delivery confirmation',
      confirmTitle: 'Message title:',
      confirmType: 'Message type:',
      confirmRange: 'Message range:',
      confirmStartDate: 'Delivery start time:',
      confirmEndDate: 'Delivery end time:',
      confirmWarning: (
        <>
          Deliver a message with the above content. <br />
          If the processing time has passed the delivery start time, an email
          will be immediately sent to the user. <br />
          Is it OK?{' '}
        </>
      ),

      discardMessage: 'Message discard confirmation',
      discardContent: (
        <>
          Data has changed.
          <br />
          Are you sure you want to close without saving? <br />
          If you press the close button, the changed data will be discarded and
          the message editing screen will be closed.
        </>
      ),

      deleteMessage: 'Message delete confirmation',
      deleteContent: (s: string) =>
        `Are you sure you want to delete message "${s}"?`,
    },
  },
  D22: {
    pageTitle: 'Introducer',
    label: {
      supportCode: 'Introducer code',
      supportCodeDescription: 'Issued after registration',
      introducerName: 'Introducer name',
      numberOfApplications: 'Number of applications approved',
      lastApplicationDate: 'Last approval date',
      introducerNameKana: 'Introducer name Kana',
      buildingName: 'Building name',
      phoneNumber: 'Phone number',
      PICEmail: 'PIC email',
      PICName: 'PIC name',
      PICNameKana: 'PIC name kana',
      referralCode: 'Referral code',
      addIntroducer: 'Add introducer',
      viewIntroducer: 'Introducer information',
    },
    placeholder: {
      introducerName: 'Introducer name',
    },

    message: {},
    modal: {},
  },
  D15: {
    title: "Supply Chain Group's Buyer List",
    columns: {
      inviteCode: 'Invite Code',
      numPracticipants: 'Number of Participants',
      createDate: 'Created Date',
      note: 'Note',
    },
    label: {
      supplyChainGroupName: 'Supply chain group name',
      inviteCode: 'Invite code',
    },
    titleAddModal: 'New Supply Chain Group Registration',
    titleViewModal: 'Supply Chain Group Information',
    noteInvite: 'Issued after registration',
  },
  D17: {
    title: "Supply Chain Group's Supplier List",
    columns: {
      groupOwner: 'Group Buyer',
      joinDate: 'Joining Date',
      numberOfResSent: 'Number of Responses Sent',
      resList: 'Response List',
      createRes: 'Create Response',
    },
    label: {
      supplyChainGroupBuyer: "Supply Chain Group's Buyer",
    },
    titleAddModal: 'New Supply Chain Group Participation',
    titleViewModal: 'Supply Chain Group Participation Information',
  },
  D19: {
    title: "Supplier's Product List",
    titleModal: 'Product Suspension Confirmation',
    emissionsPerUnit: 'Emissions per Unit',
    gtinCode: 'GTIN code',
    upcCode: 'UPC code',
    productName: 'Product Name',
    serialNumber: 'Serial Number',
    columns: {
      dateOfRegistration: 'Date of registration',
      updatedOn: 'Updated on',
      numberOfUses: 'Number of Uses',
      status: 'Status',
      active: 'Active',
      inactive: 'Inactive',
    },
    modal: {
      supplierProductRegistration: "New Supplier's Product Input",
      supplierProductsEdit: "Edit Supplier's Product",
      supplierProductsInformation: "Supplier's Product Information",
      unit: 'Unit',
      attentionMessage: 'Are you sure you want to suspend this product?',
      serialNumber: 'Serial Number',
      productName: 'Product Name',
    },
  },
  F04: {
    label: {
      higoBank: '肥後銀行',
    },
    title: 'Contact Us',
    description:
      'For inquiries regarding Tansaku-kun, please contact us using the inquiry form via the link below.',
    linkContent: 'Tansaku-kun inquiry form',
    linkDescription: '（opens in an external window）',
  },

  H01: {
    title: 'Received Response List',
    columns: {
      supplierName: 'Supplier name',
      answerType: 'Response type',
      responsePeriod: 'Response period',
      responseReceivedDateAndTime: 'Received time',
      answerReceivedStatus: 'Status',
      pending: 'Pending Registration',
      register: 'Registered',
      return: 'Return',
    },
    modal: {
      remandConfirmTitle: 'Return response confirmation',
      remainConfirmSubtitle: 'Are you sure you want to return the response?',
      enterpriseName: 'Enterprise name',
    },
  },

  H02: {
    title: 'Submitted Response List',
    responsePeriod: 'Response period',
    notReceived: 'Not Received',
    received: 'Received',
    returned: 'Returned',
    fiscalYear: 'Fiscal year',
    productUnit: 'Product unit',
    organizationalUnit: 'organizational unit',
    columns: {
      sentDateTime: 'Sent date-time',
      status: 'Status',
      responseType: 'Response type',
      detail: 'Detail',
      emissionVolume: 'Emissions',
    },
    modal: {
      title: 'Delete response confirmation',
      announcementMessage: 'Are you sure you want to delete the response?',
      GroupBuyer: "Group's buyer",
      groupName: "Group's name",
    },
    modalAction: {
      content: (
        orgName: string,
        yearMonthStart: string,
        yearMonthEnd: string,
      ) => {
        return (
          <>
            <div className="tw-flex tw-items-start tw-justify-start">
              <p className="tw-text-h3">Base - Organization&nbsp;:</p>
              <p className="tw-break-all tw-text-h3">&nbsp;{orgName}</p>
            </div>
            <p className="tw-text-h3">
              The company's Scope 1 and 2 total emissions for the range{' '}
              {yearMonthStart} ~ {yearMonthEnd} have changed.
            </p>
            <p className="tw-text-h3">Are you sure you want to continue?</p>
          </>
        );
      },
    },
  },

  H03: {
    titleAdd: 'Supplier Responses Input',
    titleEdit: 'Edit supplier responses',
    groupBuyer: "Group's Buyer",
    responseType: 'Response type (Product | Organization)',
    responseTooltip: (
      <>
        There are two types of emissions: <br />
        ・Product-based: Emissions per unit (1 item) of the supplied product
        <br />
        ・Organization-based: Emissions related to all products supplied to the
        responding company in one year
      </>
    ),
    startDate: 'Start month',
    startDateTooltip:
      'Please select a month that is in the same year as the end date and has a start date earlier than the end date.',
    endDate: 'End month',
    endDateTooltip:
      'Please select a month that is in the same year as the start date and has an end date later than the start date.',
    manufacturingCodeProductName: 'Serial number/ Product name',
    manufacturingCodeProductNameTootip:
      'To be defined on the supplier product screen',
    quantity: 'Quantity',
    correspondingUnit: 'tCO2/Corresponding Unit',
    quantityUnit: 'Unit',
    scopeHalfEmissions: 'Scope 1/2 emissions',
    allocation: 'Allocation',
    allocationTooltip:
      'Provide the emissions calculated by applying the allocation (%) to the entered Scope 1 and Scope 2 emissions of the supplier for the specified time period and base/organization.',
    responseSummary: 'Response summary',
    modalCreate: {
      title: 'Submit response confirmation',
      subTitle: 'Are you sure you want to submit the response? ',
    },
    modalEdit: {
      title: 'Edit response confirmation',
      subTitle: 'Are you sure you want to update the response? ',
    },
    modalSubmit: {
      content: (
        orgName: string,
        emission: number,
        yearMonthStart: string,
        yearMonthEnd: string,
      ) => {
        return (
          <>
            <div className="tw-flex tw-items-start tw-justify-start">
              <p className="tw-text-h3">Base - Organization&nbsp;:</p>
              <p className="tw-break-all tw-text-h3">&nbsp;{orgName}</p>
            </div>
            <div className="tw-flex tw-items-start tw-justify-start">
              <p className="tw-text-h3">Responded emissions&nbsp;:</p>
              <p className="tw-break-all tw-text-h3">&nbsp;{emission} tC02</p>
            </div>
            <p className="tw-text-h3">
              The reported CO2 emissions for the range {yearMonthStart} ~{' '}
              {yearMonthEnd} exceed the company's Scope 1 and 2 total emissions.
            </p>
            <p className="tw-text-h3">Are you sure you want to continue?</p>
          </>
        );
      },
    },
  },

  H04: {
    title: 'Supplier Emission Data Input',
    baseOrganization: 'Base-Organization',
    calculationTarget: 'Calculation target',
    startMonth: 'Start month',
    endMonth: 'End month',
    month: 'Month',
    supplierProduct: "Supplier's Product",
    serialNumber: 'Serial number',
    supplierName: 'Supplier name',
    unit: 'Unit',
    supplierProductBase: 'Supplier product base',
    directManualInput: 'Direct manual input',
    supplierResponseBase: 'Supplier response base',
    fiscalYear: 'Fiscal year',
    startDateTooltip:
      'Please select a month in the same fiscal year as the end month, and ensure that the start month is before the end month.',
    endDateTooltip:
      'Please select a month in the same fiscal year as the start month, and ensure that the start month is before the end month.',
    placeholder: {
      category1: 'Category 1: Purchased Goods and Services',
    },
    modalRegister: {
      title: 'Register confirmation',
      subTitle: "Are you sure you want to register supplier's emissions?",
    },

    modalEdit: {
      title: 'Edit confirmation',
      subTitle: 'Are you sure you want to update data?',
    },
  },

  E0000: (field: string) => createMessage('{0} must be specified.', field),
  E0001: '',
  E0002: (field: ReactNode) => createNode('Copy {0} successfully.', field),
  E0003: 'Incorrect bank code, Login ID or password.',
  E0004: `User already existed.`,
  E0005: `User ID exists. Please enter another user ID.`,
  E0006: `User does not exist.`,
  E0007: `Your account is temporarily locked because you failed to authenticate 5 times consecutively. Please contact your administrator.`,
  E0008: `Invalid email format.`,
  E0009: `Your current password is incorrect. Please try again.`,
  E0010: `The new password you entered does not match the new password (confirmation). Please try again.`,
  E0011: `This password has been used recently. Please specify a different password.`,
  E0012: `The current password and new password are the same. Please try again.`,
  E0013: (field: string) =>
    createMessage(
      `Please enter {0} in half-width alphanumeric characters and half-width symbols.`,
      field,
    ),
  E0014: (field: string) => createMessage(`{0} is not registered.`, field),
  E0015: `Your account is locked. Please contact your administrator.`,
  E0016: `Your account has been deleted.`,
  E0017: (field: string) => createMessage(`Please enter {0}.`, field),
  E0018: `No results`,
  E0019: (field: string) => createMessage(`Converted to {0}.`, field),
  E0020: (
    <>
      Year data existed. <br />
      Unable to register
    </>
  ),
  E0021: `The report is too large to export. Please select the export criteria again.`,
  E0022: (
    <>
      The [base/organization] existed. <br />
      Cannot register.
    </>
  ),
  E0023: `File is not in proper format.`,
  E0024: `No file selected.`,
  E0025: (field: string) =>
    createMessage(`Please upload the file size within {0}MB.`, field),
  E0026: `The input file does not match the selected OCR template.`,
  E0027: `The format of the year and month is not appropriate. Please enter as 'YYYY-MM'.`,
  E0028: `An error has occurred. No data found.`,
  E0029: `Invalid input. Please enter a number.`,
  E0030: `Please complete all required fields.`,
  E0031: (
    <>
      Unable to connect to database.
      <br />
      Cannot register.
    </>
  ),
  E0032: (
    <>
      Unable to connect to database
      <br />
      Cannot register.
    </>
  ),
  E0033: `Email address exists. Please enter another email address.`,
  E0034: `The email address is incorrect or has not been registered.`,
  E0035: (field: string) =>
    createMessage(`{0} days left until expiration`, field),
  E0036: `Enter the new password using half-width alphanumeric characters and half-width symbols.`,
  E0037: `Please enter a new password with at least 12 digits and less than 16 digits.`,
  E0038: `New password and new password (confirmation) are not the same and cannot be changed.`,
  E0039: `Postal code is not correct.`,
  E0040: `The output start year/month and output end year/month values are not correct.`,
  E0041: `Data does not exist.`,
  E0042: `Data being deleted does not exist.`,
  E0043: `The maximum number of links that can be registered has been exceeded. (Maximum number of links: 10)`,
  E0044: `Please select a partner bank.`,
  E0045: `Please select a company.`,
  E0046: `This partner bank does not have a company.`,
  E0047: `Please select a start date.`,
  E0048: `Please select the end date.`,
  E0049: `The end month must be greater than the start month.`,
  E0050: (field1: string, field2: string) =>
    createMessage(`Line {0}: {1} is a required item.`, field1, field2),
  E0051: (field: string) =>
    createMessage(`Line {0}: Please enter a usage amount of 0 or more.`, field),
  E0052: (field: string) =>
    createMessage(
      `Line {0}: Usage can be entered up to three decimal places.`,
      field,
    ),
  E0053: `Registration failed because there is a negative value in the emission data.`,
  E0054: `Manual does not exist.`,
  E0055: `FAQ file does not exist.`,
  E0056: `Failed to update because there is a negative value in the emissions data.`,
  E0057: `Please set your password including uppercase letters, lowercase letters, numbers and symbols.`,
  E0058: (field: string) => createMessage(`File size exceeds {0}MB.`, field),
  E0059: `Character code is not UTF-8`,
  E0060: (field1: string, field2: string) =>
    createMessage(
      `The data for this record does not match the pattern for anything in {0} and {1}.`,
      field1,
      field2,
    ),
  E0061: `The number of columns in the csv file is incorrect`,
  E0062: (field: string) => createMessage(`The {0} is invalid`, field),
  E0063: `Incorrect code value for location code for company code`,
  E0064: `Scope codes do not match category codes.`,
  E0065: ``,
  E0066: ``,
  E0067: `The csv file name is not correct with the database master table.`,
  E0068: `Time between start month and end month cannot exceed one year.`,
  E0069: ``,
  E0070: (field: string) => createMessage(``, field),
  E0071: (field: string) =>
    createMessage(`The length of {0} exceeds the maximum limit.`, field),
  E0072: (field: string) => createMessage(``, field),
  E0073: ``,
  E0074: ``,
  E0075: `Already exist LCI DB data of year targer, cannot register`,
  E0076: ``,
  E0077: ``,
  E0078: `The registration process does not continue because there is no applicable LCI-DB data for the year for which CO2 data must be registered.`,
  E0079: `The selected category has been disabled by enterprise settings.`,
  E0080: (field: string) => createMessage(``, field),
  E0081: `Please set the display start date and time to a value that is earlier than the display end date and time.`,
  E0082: `Please set a future date and time for the display end date.`,
  E0083: (field1: string, field2: string) =>
    createMessage(
      `Please specify {0} with {1} characters or less.`,
      field1,
      field2,
    ),
  E0084: (
    <>
      The destination message ID cannot be found. It may have been deleted by
      another user. <br />
      Would you like to register it as a new message?
    </>
  ),
  E0085: `The email address you entered cannot be used.`,
  E0086: (field1: string, field2: string, field3: string) =>
    createMessage('{0} must be between {1} and {2}.', field1, field2, field3),
  E0087: (field1: string, field2: string) =>
    createMessage(
      'When {0} is selected, {1} must be specified.',
      field1,
      field2,
    ),
  E0088: (field: string) => createMessage(`Please enter {0} digits.`, field),
  E0089: `Please enter the total to be 100%.`,
  E0090: `The data has been changed by another user.`,
  E0091: `The data has been changed by the buyer, the supplier is unable to make any changes.`,
  E0092: `The group does not exist. Please select again.`,
  E0093: `The product does not exist. Please select again.`,
  E0094: `The product's information has been changed.`,
  E0095: `The Base/Organization does not exist. Please select again.`,
  E0096: `Base/Organization's information has been changed.`,
  E0097: `The emissions have been changed.`,
  E0098: `In the year 20XX, the total allocation of responses for XXX exceeds 100%. The total allocation of responses already submitted is XX%.`,
  E0099: `The supplier does not exist. Please selet again.`,
  E0100: `Please select the start month and end month of the same LCI-DB year.`,
  E0101: `Please enter a value no greater than 100%.`,
  E0102: 'Please enter 10 alphabet characters starting with TSC.',
  E0103: `The invite code has already been used, please check again.`,
  E0104: (...params: number[]) => {
    if (params.length === 0) {
      return `Please enter digits.`;
    } else if (params.length === 1) {
      return `Please enter ${params[0]} digits.`;
    } else {
      const item = params.slice(0, -1).join(', ');
      const lastItem = params.pop();
      return `Please enter ${item} or ${lastItem} digits.`;
    }
  },
  E0106: 'The emissions amount exceeded 14 digits, please edit the quantity.',
  E0117: 'Please set the start date to a value earlier than the end date.',
  E0119: 'The mission volume exceeds 15 digits, please edit again.',
  E0122: (field: string) =>
    createMessage(
      `The {0}'s whole number exceeds 10 digits, so please correct it.`,
      field,
    ),
  E0113:
    'Please enter a deducted amount that is less than or equal to the usage amount.',
  E0116:
    "Certificate's amount to use has exceeded the remaining amount. Please re-input.",
  I0001: 'Registration process completed successfully.',
  I0002: 'Delete processing completed successfully.',
  I0003: 'Update processing completed successfully.',
  I0004: 'Your password has been changed.',
  I0005: 'No results found.',
  I0006: (userID: string, userName: string) =>
    createMessage(`Delete user information {0}_{1}?`, userID, userName),
  I0007: `There is no data.`,
  I0008: `Please select a partner bank.`,
  I0009: `Choose a partner bank and company.`,
  I0010: `Please select a company.`,
  I0011: `No partner banks exist.`,
  I0012: `There is no data for the previous month/current month.`,
  I0013: `Do you want to perform data registration?`,
  I0014: `Do you want to update data?`,
  I0015: `When the file doesn't have the content, it cannot register the data`,
  I0016: ``,
  I0017: `There is no data to update according to the LCI-DB data master`,
  I0018: `Data master LCI-DB not updated for the current year`,
  I0019: `Updating data based on new LCI-DB data master. Please wait.`,
  I0020: (field1: string) =>
    createMessage(`Notice for deletion of {0} information`, field1),
  I0021: <></>,
  I0022: <></>,
  I0023: <></>,
  I0024: `This feature is not supported on mobile, so please do it on your computer.`,
  I0025: (
    <>
      Messages that have already been delivered to users cannot be edited.
      <br />
      If corrections are necessary, please create a new correction message and
      send it, then delete the incorrect message. <br />
      (Sent emails cannot be deleted)
    </>
  ),

  I0026: (
    <>
      F You are attempting to delete a message that has already been delivered
      to a user. <br /> Are you sure you want to delete? <br />
      (Sent emails cannot be deleted)
    </>
  ),

  I0027: `This application URL has expired.`,
};
