import { HgbFieldError } from '@common/contents/HgbFieldError';
import HgbInfoLink from '@common/contents/HgbInfoLink';
import HgbInfoTooltip from '@common/contents/HgbInfoTooltip';
import { LanguageContext } from '@contexts/LanguageContext';
import { cn } from '@utils/cn';
import { DatePicker } from 'antd';
import { default as EN_Locale } from 'antd/es/date-picker/locale/en_US';
import { default as ja_JP } from 'antd/es/date-picker/locale/ja_JP';
import { RenderFunction, TooltipPlacement } from 'antd/lib/tooltip';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import React, { useContext, useEffect, useState } from 'react';
import {
  UseControllerProps,
  useFormContext,
  useFormState,
} from 'react-hook-form';

export type PickerType = 'date' | 'week' | 'month' | 'quarter' | 'year';

interface IHgbDatePickerProps extends UseControllerProps {
  label?: string;
  subLabel?: string;
  disabled?: boolean;
  format?: string;
  className?: string;
  placeholder?: string;
  required?: boolean;
  picker?: PickerType;
  showTime?: boolean;
  popupClassname?: string;
  disabledDate?: boolean;
  info?: string;
  modalStatus?: string;
  readOnly?: boolean;
  onChangeValue?: (name: string, value: string) => void;
  allowClear?: boolean;
  infoTooltip?: React.ReactNode | RenderFunction;
  palcement?: TooltipPlacement;
}

export const HgbDatePicker = React.forwardRef<any, IHgbDatePickerProps>(
  (props, ref) => {
    const {
      label,
      className,
      format,
      placeholder = '',
      subLabel = '',
      picker = 'month',
      required,
      showTime = false,
      popupClassname = '',
      disabledDate = false,
      info,
      modalStatus,
      readOnly,
      onChangeValue,
      allowClear = false,
      infoTooltip,
      palcement,
      ...rest
    } = props;
    const [valueInternal, setValueInternal] = useState<dayjs.Dayjs | null>(
      null,
    );
    const formProps = useFormContext() || {};
    const { errors } = useFormState();

    const handleChange = (value: any, dateString: string) => {
      if (readOnly) return;
      formProps?.setValue(rest.name, dateString);
      onChangeValue?.(value, dateString);
    };

    const {
      language,
      text: {
        common: { button },
      },
    } = useContext(LanguageContext)!;

    useEffect(() => {
      const value = formProps?.getValues(rest.name);
      if (value) {
        setValueInternal(dayjs(value, format));
      } else {
        setValueInternal(null);
      }
    }, [formProps?.watch(rest.name)]);

    return (
      <>
        <div className={cn('tw-flex tw-flex-col', className)}>
          {label && (
            <label
              className="tw-mb-4 tw-flex tw-select-none tw-gap-4 tw-text-paragraph tw-font-bold"
              htmlFor={props.disabled ? undefined : rest?.name}
            >
              {label}
              {required && (
                <span className=" tw-text-paragraph tw-text-error-7">*</span>
              )}
              {modalStatus !== 'VIEW' && !!info ? (
                <HgbInfoLink to={info} target="_blank" />
              ) : null}
              {infoTooltip && (
                <HgbInfoTooltip title={infoTooltip} placement={palcement} />
              )}
            </label>
          )}
          <div className={cn({ 'tw-cursor-not-allowed': props.disabled })}>
            <DatePicker
              open={readOnly ? false : undefined}
              picker={picker}
              disabledDate={(currentDate) => {
                return disabledDate
                  ? currentDate && currentDate < dayjs().endOf('day')
                  : disabledDate;
              }}
              tabIndex={props.disabled ? -1 : 0}
              placeholder={placeholder}
              className={cn(
                'tw-relative tw-h-[40px] tw-w-full tw-overflow-hidden tw-rounded-6',
                {
                  'tw-pointer-events-none': props.disabled,
                  'hover:tw-border-neutral-2 focus:tw-border-neutral-2 focus:tw-shadow-none [&.ant-picker-focused]:tw-border-neutral-2 [&.ant-picker-focused]:tw-shadow-none':
                    props.readOnly,
                },
                {
                  'tw-border-error-7 hover:tw-border-error-7':
                    !!errors[rest.name],
                },
              )}
              ref={ref}
              format={format}
              onChange={handleChange}
              value={valueInternal}
              locale={
                language === 'en'
                  ? EN_Locale
                  : {
                      ...ja_JP,
                      lang: {
                        ...ja_JP.lang,
                        now: button.now,
                        ok: button.ok,
                      },
                    }
              }
              allowClear={allowClear}
              showTime={showTime}
              popupClassName={popupClassname}
              showNow={false}
            />
          </div>
          {errors[rest.name] && (
            <HgbFieldError>
              {errors[rest.name]?.message?.toString()}
            </HgbFieldError>
          )}
        </div>
      </>
    );
  },
);
