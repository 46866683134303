import { PlusOutlined } from '@ant-design/icons';
import { HgbButton } from '@common/atoms/HgbButton';
import { HgbEmpty } from '@common/antd/HgbEmpty';
import { HgbCellTooltip } from '@common/contents';
import { HgbCellLink } from '@common/contents/HgbCellLink';
import { HgbAntdInput } from '@common/forms';
import { API_URLS } from '@constants/API_URLS';
import { AuthContext } from '@contexts/AuthContext';
import { LanguageContext } from '@contexts/LanguageContext';
import { useDebounce } from '@hooks/useDebound';
import { usePagin } from '@hooks/usePagin';
import { DataTableTemplate, ManagementTemplate } from '@layouts/templates';
import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHgbQueryWithPaging } from 'services/common/query';
import { GetUsersRequest, User } from 'services/types/user';
import { AddUserModal, AddUserModalProps } from './AddUserModal';
import { DeleteUserModal, DeleteUserModalProps } from './DeleteUserModal';
import {
  UpdateUserModal,
  UpdateUserModalProps,
  parseUpdateUserRequestToFormData,
} from './UpdateUserModal';
import { HgbActionButton } from '@common/antd/HgbActionButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

type DataType = {
  userId?: string;
  name?: string;
  nameKana?: string;
  roleName?: string;
  email?: string;
  action?: React.ReactNode | null;
};

export const D04: FC = () => {
  const {
    text: { D04, common },
  } = useContext(LanguageContext)!;

  const { user } = useContext(AuthContext)!;

  const {
    PaginationComponent,
    paginationProps,
    resetPaginations,
    setPaginationProps,
  } = usePagin();
  const [addUserModalProps, setAddUserModalProps] = useState<AddUserModalProps>(
    {
      open: false,
    },
  );
  const [updateUserModalProps, setUpdateUserModalProps] =
    useState<UpdateUserModalProps>({
      open: false,
    });

  const [deleteUserModalProps, setDeleteUserModalProps] =
    useState<DeleteUserModalProps>({ open: false, id: '' });

  const searchMethods = useForm<GetUsersRequest>({
    values: {
      nameSearch: '',
    },
  });

  const { register: registerSearchForm, watch: watchSearchForm } =
    searchMethods;
  const searchValues = watchSearchForm();

  const nameSearchDebounce = useDebounce(searchValues.nameSearch, 300);

  useEffect(() => {
    resetPaginations();
  }, [nameSearchDebounce]);

  const {
    data: usersQuery,
    refetch: usersRefetch,
    isFetching: usersFetching,
  } = useHgbQueryWithPaging<User[], GetUsersRequest>(
    API_URLS.USER_API_URL,
    { ...searchValues, ...paginationProps },
    {
      queryKey: [API_URLS.USER_API_URL, paginationProps, nameSearchDebounce],
    },
  );

  const handleEdit = (item: User) => {
    setUpdateUserModalProps((prev) => ({
      ...prev,
      item: parseUpdateUserRequestToFormData(item),
      open: true,
      readOnly: false,
    }));
  };

  const handleDelete = (item: User) => {
    setDeleteUserModalProps({
      id: item?.id?.toString(),
      open: true,
      content: `${D04.message.deleteUserAlertSplit1}
                ${item.userId}_${item.name}
                ${D04.message.deleteUserAlertSplit2}`,
    });
  };

  const headers: ColumnsType<DataType> = [
    {
      title: user.isPresidingBank ? D04.label.bankName : D04.label.companyName,
      dataIndex: 'departmentName',
      width: 160,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: common.label.loginID,
      dataIndex: 'item',
      width: 120,
      render: (item: User) => (
        <HgbCellLink
          onClick={() => {
            setUpdateUserModalProps({
              open: true,
              item: parseUpdateUserRequestToFormData(item),
              readOnly: true,
            });
          }}
        >
          <HgbCellTooltip>{item.userId}</HgbCellTooltip>
        </HgbCellLink>
      ),
    },
    {
      title: D04.label.name,
      dataIndex: 'name',
      width: 160,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: D04.label.nameKana,
      dataIndex: 'nameKana',
      width: 128,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: D04.label.roleName,
      dataIndex: 'roleName',
      width: 128,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: D04.label.email,
      dataIndex: 'email',
      width: 190,
      render: (text) => <HgbCellTooltip>{text}</HgbCellTooltip>,
    },
    {
      title: common.label.action,
      align: 'center',
      dataIndex: 'action',
      width: 160,
    },
  ];

  const rows = useMemo(
    () =>
      (usersQuery?.result?.content ?? []).map((item, index) => ({
        key: index,
        userId: item.userId.toString(),
        name: item.name,
        nameKana: item.nameKana,
        roleName: item.roleName,
        email: item.email,
        departmentName: item.departmentName,
        item: item,
        action: (
          <div className="tw-flex tw-justify-center tw-gap-24">
            <HgbActionButton
              onClick={() => handleEdit(item)}
              disabled={!item.updatable}
              icon={<EditOutlined />}
            >
              {common.button.update}
            </HgbActionButton>
            <HgbActionButton
              onClick={() => handleDelete(item)}
              disabled={!item.deletable}
              icon={<DeleteOutlined />}
            >
              {common.button.delete}
            </HgbActionButton>
          </div>
        ),
      })),
    [usersQuery?.result?.content],
  );

  const columns = useMemo(() => {
    const tmpHeaders = [...headers];
    if (user.isEnterpriseUser || user.isEnterpriseAdmin) tmpHeaders.shift();
    return tmpHeaders;
  }, [user]);

  const closeDeleteModal = () => {
    setDeleteUserModalProps((prev) => ({ ...prev, open: false }));
  };

  const closeUpdateModal = () => {
    setUpdateUserModalProps((prev) => ({ ...prev, open: false }));
  };

  const closeAddModal = () => {
    setAddUserModalProps((prev) => ({ ...prev, open: false }));
  };

  return (
    <ManagementTemplate>
      <FormProvider {...searchMethods}>
        <DataTableTemplate
          title={D04.pageTitle}
          inputsComponent={
            <div className="tw-grid tw-flex-auto tw-gap-16 tablet:tw-grid-cols-[repeat(auto-fill,_minmax(220px,_1fr))]">
              <HgbAntdInput
                {...registerSearchForm('nameSearch')}
                type="K"
                maxLength={50}
                search
                placeholder={D04.placeholder.nameSearch}
              />
            </div>
          }
          buttonComponent={
            <HgbButton
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setAddUserModalProps((prev) => ({ ...prev, open: true }));
              }}
            >
              <>{common.button.register}</>
            </HgbButton>
          }
          paginationComponents={
            (usersQuery?.result?.totalRecord ?? 0) > 0 ? (
              <PaginationComponent
                total={usersQuery?.result?.totalRecord}
                current={usersQuery?.result?.currentPage}
                pageSize={usersQuery?.result?.pageSize}
              />
            ) : null
          }
        >
          {isEmpty(rows) ? (
            usersFetching ? (
              <Spin className="tw-flex tw-h-full tw-items-center tw-justify-center" />
            ) : (
              <HgbEmpty
                className="tw-flex tw-h-full tw-items-center tw-justify-center"
                description={common.message.noResult}
              />
            )
          ) : (
            <Table
              columns={columns}
              dataSource={rows}
              pagination={false}
              className="tw-overflow-hidden"
              scroll={{
                x: headers.reduce(
                  (prev, current) => prev + Number(current.width ?? 0),
                  0,
                ),
              }}
            />
          )}
        </DataTableTemplate>
      </FormProvider>

      <DeleteUserModal
        {...deleteUserModalProps}
        onCancel={closeDeleteModal}
        onSubmitOk={() => {
          closeDeleteModal();
          if (rows?.length === 1) {
            setPaginationProps((prev) => ({
              ...prev,
              pageNo: prev.pageNo > 0 ? prev.pageNo - 1 : 0,
            }));
          } else {
            usersRefetch();
          }
        }}
      />

      {updateUserModalProps.open ? (
        <UpdateUserModal
          {...updateUserModalProps}
          destroyOnClose
          onCancel={closeUpdateModal}
          onSubmitOk={() => {
            closeUpdateModal();
            usersRefetch();
          }}
        />
      ) : null}

      {addUserModalProps.open ? (
        <AddUserModal
          {...addUserModalProps}
          onCancel={closeAddModal}
          onSubmitOk={() => {
            closeAddModal();
            usersRefetch();
          }}
        />
      ) : null}
    </ManagementTemplate>
  );
};
