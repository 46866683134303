import { HgbButton } from '@common/atoms/HgbButton';
import HgbModalForm from '@common/antd/HgbModalForm';
import { HgbModalFormBody } from '@common/antd/HgbModalFormBody';
import { HgbModalFormFooter } from '@common/antd/HgbModalFormFooter';
import { HgbModalTitle } from '@common/antd/HgbModalTitle';
import { HgbAntdInput } from '@common/forms';
import { LanguageContext } from '@contexts/LanguageContext';
import { BooleanState } from '@hooks/useBooleanState';
import { Modal } from 'antd';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SupplyOwner } from './D15';

interface ViewSupplyOwnerModalProps {
  modalState: BooleanState;
  data?: SupplyOwner;
}

const ViewSupplyOwnerModal: React.FC<
  ViewSupplyOwnerModalProps
> = ({ modalState, data }) => {
  const {
    text: { common, D15 },
  } = React.useContext(LanguageContext)!;
  const viewSupplyOwnerMethods = useForm<SupplyOwner>({ values: data });
  return (
    <>
      <FormProvider {...viewSupplyOwnerMethods}>
        <Modal
          className="ant-modal-hgb-custom"
          centered
          title={<HgbModalTitle>{D15.titleViewModal}</HgbModalTitle>}
          open={modalState.value}
          onCancel={modalState.turnOff}
          destroyOnClose={true}
          footer={null}
          closable={true}
          maskClosable={false}
        >
          <HgbModalForm>
            <HgbModalFormBody>
              <HgbAntdInput
                {...viewSupplyOwnerMethods.register('supplyChainName')}
                readOnly
                required
                label={common.columns.supplyChainGroupName}
              />
              <HgbAntdInput
                {...viewSupplyOwnerMethods.register('supplyChainDescription')}
                type='area'
                readOnly
                label={D15.columns.note}
                rows={2}
              />
              <div className="tw-space-y-4">
                <label className="tw-text-paragraph tw-font-bold">
                  {D15.label.inviteCode}
                </label>
                <p>{viewSupplyOwnerMethods.getValues('inviteCode')}</p>
              </div>
            </HgbModalFormBody>
            <HgbModalFormFooter>
              <HgbButton
                className="tw-min-w-100"
                onClick={modalState.turnOff}
                htmlType="button"
              >
                {common.button.cancel}
              </HgbButton>
            </HgbModalFormFooter>
          </HgbModalForm>
        </Modal>
      </FormProvider>
    </>
  );
};

export default ViewSupplyOwnerModal;
